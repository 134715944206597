import moment from "moment-timezone";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { getCorrectWordForm, parsePhone } from '../../../assets/utils/utils';
import "./PrevOrders.css";
import MiniPreloader from "../../../../MiniPreloader/MiniPreloader";
import ProductsList from "../../../ProductsList/ProductsList";
import {
  StringOrNothingFormater,
  parseApiError,
  parsePhone,
  parseRequestNumber,
} from "../../../../../assets/utils/utils";
import {
  ORDERS_ITEM_LINK,
  ORDERS_MAIN_LINK,
  PLATFORM_MAIN_LINK,
  PREV_ORDERS_LIMIT,
} from "../../../../../assets/utils/constants";
import { UserContext } from "../../../../../assets/contexts/userContext";
import mainApi from "../../../../../assets/api/MainApi";
import useAutoDismissError from "../../../../../assets/hooks/useAutoDismissError";
import ErrorMessage from "../../../../ErrorMessage/ErrorMessage";

const PrevOrders = ({ order }) => {
  const { user } = useContext(UserContext);
  const [orders, setOrders] = useState([]);
  const [isPreloaderVisible, setPreloaderVisible] = useState(true);
  const [isFeedPreloader, setIsFeedPreloader] = useState(false);
  const [isMoreBtnVisible, setIsMoreBtnVisible] = useState(false);
  const [error, showError] = useAutoDismissError();

  useEffect(() => {
    if (!order || !user) return;

    setPreloaderVisible(true);
    getPrevOrders({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  function getPrevOrders({ last_id }) {
    const shop_id = user.default_shop._id;
    mainApi
      .getPrevOrders({
        shop_id,
        user_id: order.user_id,
        exclude_id: order._id,
        limit: PREV_ORDERS_LIMIT,
        last_id,
      })
      .then((res) => {
        //   {
        //     "different_items": 1,
        //     "total_items": 1,
        //     "total_price": "34500",
        //     "delivery_price": "0",
        //     "items_price": "34500",
        //     "ai_id": "00000011",
        //     "first_name": "Тест",
        //     "last_name": "Тест",
        //     "phone": "79999999999",
        //     "email": "miketogo66@gmail.com",
        //     "submit_type": "phone",
        //     "address": {
        //         "city": "Спб",
        //         "street": null,
        //         "house": null,
        //         "apartment": null,
        //         "type": "free"
        //     },
        //     "payment_type": "bank_card",
        //     "promo_code": null,
        //     "_id": "64f0b5712f5833ec7b51ef7d",
        //     "user_id": "64cbaed487d9521d6ce36c88",
        //     "utc_date": "2023-08-31T15:44:49+00:00",
        //     "status": "cancelled",
        //     "discount": null,
        //     "url": null,
        //     "order_items": DEMO_ORDER_ITEMS,
        //     "order_notes": []
        // },
        const data = res.data;
        setOrders(last_id ? (prevArr) => prevArr.concat(data) : data);
        setIsMoreBtnVisible(res.is_more);
      })
      .catch((err) => {
        if (err.statusCode === 403) {
          setOrders([]);
          setIsMoreBtnVisible(false)
        }
        showError(parseApiError(err));
      })
      .finally(() => {
        setPreloaderVisible(false);
        setIsFeedPreloader(false);
      });
  }

  function getMore() {
    const last_id = orders[orders.length - 1]._id;
    setIsFeedPreloader(true);
    getPrevOrders({ last_id });
  }

  return (
    <>
      {isPreloaderVisible ? (
        <div className="prev-orders__preloader">
          <MiniPreloader />
        </div>
      ) : (
        <div className="prev-orders">
          <p className="prev-orders__title">
            Прошлые заказы {parsePhone(order?.phone)}
          </p>
          {orders && orders.length > 0 ? (
            orders.map((item, i) => (
              <div className="prev-orders__card">
                <div className="prev-orders__card-head">
                  <Link
                    className="prev-orders__card-number"
                    to={`${PLATFORM_MAIN_LINK}/${ORDERS_MAIN_LINK}/${ORDERS_ITEM_LINK}/${item._id}`}
                  >
                    {parseRequestNumber(item.ai_id)}
                  </Link>
                  <p className="prev-orders__card-date">
                    {moment(item.utc_date).format("DD.MM.YYYY")}
                  </p>
                </div>
                <div className="prev-orders__infos">
                  <div className="prev-orders__info">
                    <p className="prev-orders__info-title">Данные о доставке</p>
                    <div className="prev-orders__info-items">
                      <div className="prev-orders__info-item">
                        <p className="prev-orders__info-item-title">Город:</p>
                        <p className="prev-orders__info-item-value">
                          {" "}
                          {StringOrNothingFormater(order.address.city)}
                        </p>
                      </div>
                      <div className="prev-orders__info-item">
                        <p className="prev-orders__info-item-title">Улица:</p>
                        <p className="prev-orders__info-item-value">
                          {" "}
                          {StringOrNothingFormater(order.address.street)}
                        </p>
                      </div>
                      <div className="prev-orders__info-item">
                        <p className="prev-orders__info-item-title">Дом: </p>
                        <p className="prev-orders__info-item-value">
                          {" "}
                          {StringOrNothingFormater(order.address.house)}
                        </p>
                      </div>
                      <div className="prev-orders__info-item">
                        <p className="prev-orders__info-item-title">
                          Квартира:
                        </p>
                        <p className="prev-orders__info-item-value">
                          {" "}
                          {StringOrNothingFormater(order.address.apartment)}
                        </p>
                      </div>

                    </div>
                  </div>
                </div>
                <ProductsList
                  className={"prev-orders__items"}
                  products={item.order_items}
                  name={"prev-order-items" + i}
                  is_order_item={true}
                />
              </div>
            ))
          ) : error ? (
            <ErrorMessage error={error} />
          ) : (
            <p>Нет прошлых заказов</p>
          )}

          {isMoreBtnVisible ? (
            <button
              className={`order-page__more-btn ${isFeedPreloader ? "order-page__more-btn_disabled" : ""
                }`}
              onClick={getMore}
              type="button"
            >
              <p
                className={`order-page__more-btn-text ${!isFeedPreloader ? "order-page__more-btn-text_visible" : ""
                  }`}
              >
                Загрузить ещё
              </p>
              <span
                className={`order-page__btn-preloader ${isFeedPreloader ? "order-page__btn-preloader_visible" : ""
                  }`}
              >
                <MiniPreloader />
              </span>
            </button>
          ) : null}
        </div>
      )}
    </>
  );
};

export default PrevOrders;
