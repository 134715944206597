import { useEffect, useRef, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { TextareaAutosize } from "@mui/material";
import { ruRU } from '@mui/x-date-pickers/locales';
import { motion } from "framer-motion";

import moment from "moment-timezone";
import dayjs from "dayjs";

import "dayjs/locale/ru";

import "./PlatformInput.css";
import {
  HidePassonrdIcon,
  ShowPassonrdIcon,
} from "../../../assets/icons/auth/auth";
import { SelectArrowIcon, TickIcon } from "../../../assets/icons/controls/controls";
import { CategoriesInputCrossIcon } from "../../../assets/icons/product/product";
import { parseWithViewOnlyValue } from "../../../assets/utils/utils";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";

const dropdownVariants = {
  open: {
    clipPath: "inset(0% 0% 0% 0% round 8px)",
    transition: {
      type: "spring",
      bounce: 0,
      duration: 0.5,
      delayChildren: 0.2,
      staggerChildren: 0.05,
    },
  },
  closed: {
    clipPath: "inset(0 90% 90% 10% round 8px)",
    transition: {
      type: "spring",
      bounce: 0,
      duration: 0.3,
    },
  },
};

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 300, damping: 24 },
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
};

function PlatformInput({
  index,
  label,
  hint,
  boxType,
  isPassword,
  value,
  name,
  type,
  inputMode = "text",
  handleChange,
  isRequired,
  readOnly = false,
  icon,
  handleVisibility,
  error,
  isTextArea,
  maxRows,
  minRows,
  onKeyDown,
  placeholder,
  maxLength,
  isDateInput,
  minDate,
  isSelect,
  selected_text_value,
  selectList,
  selectDifferenceKey,
  selectTextValueKey,
  show_eror_color_only,
  isBooleanSelector,
  boolean_values,
  onFocus,
  onBlur,
  isMultipleSelect,
  isNoChange,
  preloader,
  isCorrectState,
  handleFocus,
}) {
  const inputRef = useRef(null);
  const dropdownRef = useRef(null)
  const [isFocuced, setIsFocuced] = useState(false);

  useEffect(() => {
    Boolean(value) ? setIsFocuced(true) : setIsFocuced(false);
  }, [value]);

  function handleFocused(evt) {
    setIsFocuced(true);
    if (onFocus) onFocus(evt)
  }

  function handleUnfocused(evt) {
    Boolean(value) ? setIsFocuced(true) : setIsFocuced(false);
    if (onBlur) onBlur(evt)
  }

  const handleClickOutside = () => {
    inputRef.current.focus();
  };

  const [isHidden, setHidden] = useState(true);

  const [isSelectOpen, setSelectOpen] = useState(false);
  const selectDropdown = useRef();

  useEffect(() => {
    function handleOutsideClickClose(evt) {
      if (
        isSelectOpen &&
        selectDropdown.current &&
        !selectDropdown.current.contains(evt.target)
      ) {
        setSelectOpen(false);
      }
    }

    document.addEventListener("mousedown", handleOutsideClickClose);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClickClose);
    };
  });



  const sorted_multiple_select_list =
    selectList && selectList.length > 0
      ? selectDifferenceKey && value && value.length > 0
        ? selectList.filter(item1 => !value.some(item2 => item2[selectDifferenceKey] === item1[selectDifferenceKey]))
        : selectList
      : [];

  function handleCloseDropdown() {
    setSelectOpen(false);
    if (dropdownRef.current) {
      setTimeout(() => {
        dropdownRef.current.scrollTo({ top: 0 });
      }, 600);

    }
  }

  useEffect(() => {
    function handleOutsideClickClose(evt) {
      if (
        isSelectOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(evt.target)
      ) {
        handleCloseDropdown()
      }
    }

    document.addEventListener("mousedown", handleOutsideClickClose);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClickClose);
    };
  });

  return (
    <div className="platform-input__input-container">
      {label || hint ? (
        <div className="platform-input__input-heading">
          {label ? (
            <span className="platform-input__label">{label}</span>
          ) : null}
          {error ? (
            !show_eror_color_only ? (
              <span
                className="platform-input__error"
                dangerouslySetInnerHTML={{ __html: error }}
              ></span>
            ) : null
          ) : hint && !readOnly ? (
            <span
              className="platform-input__hint"
              dangerouslySetInnerHTML={{ __html: hint }}
            ></span>
          ) : null}
        </div>
      ) : null}

      {
        isMultipleSelect ?
          <div className="platform-input__categories-box">
            <div className={`platform-input__categories-input ${readOnly ? 'platform-input__categories-input_read-only' : ''}`}>
              {value && value.length > 0 ?
                value.map((item, i) => (
                  <div className="platform-input__category">
                    {item[selectTextValueKey]}
                    <button className='platform-input__category-delete' type="button" onClick={() => {
                      if (readOnly) return
                      handleChange({
                        target: {
                          value: item,
                          name: name + '-delete',
                        },
                      });
                    }}>
                      <CategoriesInputCrossIcon
                        mainClassName={'platform-input__category-delete-icon'}
                        fillClassName={'platform-input__category-delete-icon-fill'}
                      />
                    </button>
                  </div>
                ))
                : readOnly ?
                  <span className="platform-input__category-read-only-placeholder">-</span>
                  : null}

              {!readOnly ?
                <button className='platform-input__category-add' type="button" onClick={() => {
                  if (readOnly) return
                  if (isSelectOpen) {
                    handleCloseDropdown()
                  } else {
                    setSelectOpen(true)
                  }
                }}>
                  Добавить...
                </button>
                : null}
            </div>
            <div
              className={`platform-input__dropdown ${isSelectOpen ? "platform-input__dropdown_opened" : ""
                }`}
              ref={dropdownRef}
            >
              <motion.div
                className="platform-input__box-with-dropdown"
                initial={false}
                animate={isSelectOpen ? "open" : "closed"}
              >
                <motion.div
                  variants={dropdownVariants}
                  style={{ pointerEvents: isSelectOpen ? "auto" : "none" }}
                  className="platform-input__dropdown-container"
                >
                  <ul className="platform-input__dropdown-list">
                    {sorted_multiple_select_list && sorted_multiple_select_list.length > 0
                      ? sorted_multiple_select_list.map((item, i) => (
                        <li
                          className={`platform-input_dropdown-item `}
                          key={`dropdown-type-${name}-item-${i}`}
                        >
                          {selectTextValueKey && item[selectTextValueKey] ? (
                            <button
                              className="platform-input_dropdown-btn"
                              type="button"
                              onClick={() => {
                                if (readOnly) return
                                console.log(item, value);
                                setSelectOpen(false);
                                handleChange({
                                  target: {
                                    value: item,
                                    name: name,
                                  },
                                });
                              }}
                            >
                              {item[selectTextValueKey]}
                            </button>
                          ) : null}
                        </li>
                      ))
                      : null}
                  </ul>
                </motion.div>
              </motion.div>
            </div>
          </div>
          : isBooleanSelector ? (
            <div className="platform-input__boolean-selector">
              <button
                className={`platform-input__boolean-selector-item ${value ? "platform-input__boolean-selector-item_selected" : ""
                  }`}
                type="button"
                onClick={() => {
                  if (readOnly) return;
                  handleChange({
                    target: {
                      value: true,
                      name: name,
                    },
                  });
                }}
              >
                {boolean_values.true.title}
              </button>
              <button
                className={`platform-input__boolean-selector-item ${!value ? "platform-input__boolean-selector-item_selected" : ""
                  }`}
                type="button"
                onClick={() => {
                  if (readOnly) return;
                  handleChange({
                    target: {
                      value: false,
                      name: name,
                    },
                  });
                }}
              >
                {boolean_values.false.title}
              </button>
            </div>
          ) : isDateInput ? (
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru"
              localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText} >
              <DatePicker
                // label="Срок действия"
                className={`${isFocuced ? "platform-input__input-box_focused" : ""
                  } ${error ? "platform-input__input-box_error" : ""}`}
                value={dayjs(value)}
                format="DD.MM.YYYY"
                name={name}
                minDate={minDate}
                onChange={(newValue) => {
                  if (readOnly) return;
                  const formatedValue = newValue?.format("YYYY-MM-DDTHH:mm:ssZ");
                  const value = newValue ? formatedValue : "";
                  handleChange({
                    target: {
                      value: value,
                      name: name,
                    },
                  });
                }}
              // error={error ? error : false}
              />
            </LocalizationProvider>
          ) : isSelect ? (
            <div className="platform-input__select-box" ref={selectDropdown}>
              <button
                className={`platform-input__select-selected ${isSelectOpen ? "platform-input__select-selected_open" : ""
                  }`}
                type="button"
                onClick={() => {
                  if (readOnly) return;
                  setSelectOpen((prevValue) => !prevValue);
                }}
              >
                <p className="platform-input__select-selected-value">
                  {selected_text_value ? selected_text_value : ""}
                </p>
                {!readOnly ? (
                  <SelectArrowIcon
                    mainClassName={"platform-input__select-arrow"}
                    strokeClassName={"platform-input__select-arrow-stroke"}
                  />
                ) : null}
              </button>
              <div
                className={`platform-input__dropdown ${isSelectOpen ? "platform-input__dropdown_opened" : ""
                  }`}
              >
                <motion.div
                  className="platform-input__box-with-dropdown"
                  initial={false}
                  animate={isSelectOpen ? "open" : "closed"}
                >
                  <motion.div
                    variants={dropdownVariants}
                    style={{ pointerEvents: isSelectOpen ? "auto" : "none" }}
                    className="platform-input__dropdown-container"
                  >
                    <ul className="platform-input__dropdown-list">
                      {selectList && selectList.length > 0
                        ? selectList.map((item, i) => (
                          <motion.li
                            className={`platform-input_dropdown-item ${item[selectDifferenceKey] === value[selectDifferenceKey] ? 'platform-input_dropdown-item_selected' : ''}`}
                            variants={itemVariants}
                            key={`dropdown-type-${name}-item-${i}`}
                          >
                            {selectTextValueKey && item[selectTextValueKey] ? (
                              <button
                                className="platform-input_dropdown-btn"
                                type="button"
                                onClick={() => {
                                  if (readOnly) return
                                  console.log(item, value);
                                  setSelectOpen(false);
                                  handleChange({
                                    target: {
                                      value: item,
                                      name: name,
                                    },
                                  });
                                }}
                              >
                                {item[selectTextValueKey]}
                              </button>
                            ) : null}
                          </motion.li>
                        ))
                        : null}
                    </ul>
                  </motion.div>
                </motion.div>
              </div>
            </div>
          ) : (
            <div
              className={`platform-input__input-box ${readOnly ? 'platform-input__input-box_no-change' : ''} ${isPassword ? "platform-input__input-box_with-icon" : ""
                } ${isFocuced ? "platform-input__input-box_focused" : ""} ${error ? "platform-input__input-box_error" : ""
                } ${preloader ? 'platform-input__input-box_with-preloader' : ''} ${isCorrectState ? 'platform-input__input-box_correct-state' : ''}`}
              onClick={handleClickOutside}
            >
              {isTextArea ? (
                <TextareaAutosize
                  ref={inputRef}
                  className={
                    "platform-input__input platform-input__input_type_text-area"
                  }
                  type={isPassword ? (isHidden ? "password" : "text") : type}
                  inputMode={inputMode}
                  value={parseWithViewOnlyValue(readOnly, value || "")}
                  autoComplete="off"
                  name={name}
                  onChange={handleChange}
                  placeholder={placeholder}
                  onFocus={handleFocused}
                  onBlur={handleUnfocused}
                  readOnly={readOnly}
                  onKeyDown={(e) => {
                    if (onKeyDown) {
                      onKeyDown(e);
                    }
                  }}
                  maxLength={maxLength ? maxLength : 1000}
                  maxRows={maxRows ? maxRows : 10}
                  minRows={minRows ? minRows : 1}
                />
              ) : (
                <input
                  ref={inputRef}
                  className={"platform-input__input"}
                  type={isPassword ? (isHidden ? "password" : "text") : type}
                  inputMode={inputMode}
                  value={parseWithViewOnlyValue(readOnly, value || "")}
                  autoComplete="off"
                  name={name}
                  onChange={handleChange}
                  placeholder={placeholder}
                  onFocus={handleFocused}
                  onBlur={handleUnfocused}
                  readOnly={readOnly}
                  onKeyDown={(e) => {
                    if (onKeyDown) {
                      onKeyDown(e);
                    }
                  }}
                  onFocusCapture={handleFocus}
                  maxLength={maxLength ? maxLength : 1000}
                />
              )}
              {preloader ?
                <MiniPreloader />
                :
                isCorrectState ?
                  <TickIcon mainClassName={'platform-input__tick'} fillClassName={'platform-input__tick-fill'} />
                  :
                  null}

              {isPassword ? (
                <button
                  className="platform-input__show-btn"
                  type="button"
                  onClick={() => {
                    if (readOnly) return
                    setHidden((prevValue) => !prevValue);
                  }}
                >
                  {isHidden ? (
                    <HidePassonrdIcon
                      mainClassName={"platform-input__show-btn-icon"}
                      fillClassName={"platform-input__show-btn-icon-fill"}
                    />
                  ) : (
                    <ShowPassonrdIcon
                      mainClassName={"platform-input__show-btn-icon"}
                      fillClassName={"platform-input__show-btn-icon-fill"}
                    />
                  )}
                </button>
              ) : null}
            </div>
          )}
    </div>
  );
}

export default PlatformInput;
