import { useContext, useEffect, useState } from 'react';
import PlatformInput from '../../PlatformInput/PlatformInput';
import './ShopInfo.css';
import mainApi from '../../../../assets/api/MainApi';
import ItemForm from '../../ItemForm/ItemForm';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import PageHeading from '../../PageHeading/PageHeading';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../../../../assets/contexts/userContext';
import { PlatformLinksContext } from '../../../../assets/contexts/platformLinksContext';
import { createAction, getCurrentPlatfromLocation, hasPermission, parseApiError, parsePermissions } from '../../../../assets/utils/utils';
import useAutoDismissError from '../../../../assets/hooks/useAutoDismissError';
import { PERMISSIONS, RBAC_CONTENT_MANAGEMENT, RBAC_SHOP_SETTINGS } from '../../../../assets/utils/permissions_rbac';
import { SETTINGS_CONSTANTS_LINK } from '../../../../assets/utils/constants';
import Toggle from '../../../Toggle/Toggle';
import SubmitActionPopup from '../../SubmitActionPopup/SubmitActionPopup';

function ShopInfo() {

    const { user, setUser } = useContext(UserContext)
    const location = useLocation();
    const navigate = useNavigate();

    const [preloaders, setPreloaders] = useState({
        update: false,
        filters_toggle: false,
    })

    const [popups, setPopups] = useState({
        filters_toggle: false,
    })

    const { links, default_link } = useContext(PlatformLinksContext)

    const current_location = getCurrentPlatfromLocation({
        location: location,
        links,
    })
    const [step, setStep] = useState(0)
    const [shopInfoForm, setShopInfoForm] = useState({
        name: user.default_shop.name ? user.default_shop.name : '',
        prev_name: user.default_shop.name ? user.default_shop.name : '',
        description: user.default_shop.description ? user.default_shop.description : '',
        prev_description: user.default_shop.description ? user.default_shop.description : '',
        official_name: user.default_shop.official_name ? user.default_shop.official_name : '',
        prev_official_name: user.default_shop.official_name ? user.default_shop.official_name : '',
        categories: user.default_shop.categories ? user.default_shop.categories : [],
        prev_categories: user.default_shop.categories ? user.default_shop.categories : [],
        using_filters: user.default_shop.using_filters ? user.default_shop.using_filters : false,
    })

    useEffect(() => {
        if (!user) return
        mainApi.getShopInfo({
            shop_id: user.default_shop._id,
        })
            .then((res) => {
                console.log(res)
                const info = res.info
                setShopInfoForm({
                    name: info.name ? info.name : '',
                    prev_name: info.name ? info.name : '',
                    description: info.description ? info.description : '',
                    prev_description: info.description ? info.description : '',
                    official_name: info.official_name ? info.official_name : '',
                    prev_official_name: info.official_name ? info.official_name : '',
                    categories: info.categories ? info.categories : [],
                    prev_categories: info.categories ? info.categories : [],
                    using_filters: res.using_filters ? res.using_filters : false,
                })
            })
            .catch((err) => {
                console.log(err)
                if (err.statusCode === 403) {
                    setShopInfoForm({
                        name: user.default_shop.name ? user.default_shop.name : '',
                        prev_name: user.default_shop.name ? user.default_shop.name : '',
                        description: user.default_shop.description ? user.default_shop.description : '',
                        prev_description: user.default_shop.description ? user.default_shop.description : '',
                        official_name: user.default_shop.official_name ? user.default_shop.official_name : '',
                        prev_official_name: user.default_shop.official_name ? user.default_shop.official_name : '',
                        categories: user.default_shop.categories ? user.default_shop.categories : [],
                        prev_categories: user.default_shop.categories ? user.default_shop.categories : [],
                        using_filters: user.default_shop.using_filters ? user.default_shop.using_filters : false,
                    })
                }

            })
    }, [user])

    function handleShopInfoChange(e) {
        const input = e.target;
        let value = input.value;
        const name = input.name
        switch (name) {
            case 'categories': {
                setShopInfoForm(prevValue => ({
                    ...prevValue,
                    [name]: prevValue[name].concat([value]),
                }))
                break;
            }

            case 'categories-delete': {
                setShopInfoForm(prevValue => ({
                    ...prevValue,
                    categories: prevValue.categories.filter(item => item._id !== value._id),
                }))
                break;
            }



            default:
                setShopInfoForm(prevValue => ({
                    ...prevValue,
                    [name]: value,
                }))
                break;
        }
    }


    function handleShopInfoSave() {
        setPreloaders(prevValue => ({
            ...prevValue,
            update: true,
        }))
        mainApi.updateShopInfo({
            shop_id: user.default_shop._id,
            name: shopInfoForm.name ? shopInfoForm.name : null,
            description: shopInfoForm.description ? shopInfoForm.description : null,
            official_name: shopInfoForm.official_name ? shopInfoForm.official_name : null,
            categories: shopInfoForm.categories && shopInfoForm.categories.length > 0 ? shopInfoForm.categories.map((item) => {
                return item._id
            }) : [],

        })
            .then((res) => {
                console.log(res)
                setShopInfoForm(prevValue => ({
                    ...prevValue,
                    prev_name: prevValue.name,
                    prev_description: prevValue.description,
                    prev_official_name: prevValue.official_name,
                    prev_categories: prevValue.categories,
                }))
                setUser(prevValue => ({
                    ...prevValue,
                    default_shop: {
                        ...prevValue.default_shop,
                        info: {
                            ...prevValue.default_shop.info,
                            name: shopInfoForm.name,
                            description: shopInfoForm.description,
                            official_name: shopInfoForm.official_name,
                        },
                    }
                }))
                setStep(1)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    update: false,
                }))
                setTimeout(() => {
                    setStep(0)
                }, 2000);
            })
    }

    const [shopCategories, setShopCategories] = useState([])
    const [error, showError] = useAutoDismissError()
    useEffect(() => {
        mainApi.getShopInfoCategories()
            .then((res) => {
                console.log(res)
                setShopCategories(res.data)
            })
            .catch((err) => {
                console.log(err)
                showError(parseApiError(err))
            })
    }, [])


    const hasEditPermission = hasPermission(parsePermissions(user), [RBAC_SHOP_SETTINGS[PERMISSIONS.MANAGE_SHOP_INFO]])
    const hasEditLayoutPermission = hasPermission(parsePermissions(user), [RBAC_CONTENT_MANAGEMENT[PERMISSIONS.EDIT_LAYOUT]])

    function addActionsBasedOnPermissions() {
        const actions = [];

        if (hasEditLayoutPermission) {
            actions.push(createAction('Настройки системы', () => {
                navigate(`${location.pathname}/${SETTINGS_CONSTANTS_LINK}`)
            }, {
                isMainAction: false,
            }));
        }
        if (hasEditPermission) {
            actions.push(createAction(step === 0 ? 'Сохранить' : 'Сохранено', handleShopInfoSave, {
                isMainAction: true,
                isPreloaderVisible: preloaders.update,
            }));
        }

        return actions;
    }

    const actions = addActionsBasedOnPermissions();


    function handleToggleFiltersClick() {
        setPopups(prevValue => ({
            ...prevValue,
            filters_toggle: true,
        }))
    }

    function handleToggleFiltersUpdate() {
        const shop_id = user.default_shop._id;
        setPreloaders(prevValue => ({
            ...prevValue,
            filters_toggle: true,
        }))
        mainApi.updateAllFiltersShow({ shop_id, status: !shopInfoForm.using_filters })
            .then((res) => {
                setShopInfoForm(prevValue => ({
                    ...prevValue,
                    using_filters: !prevValue.using_filters,
                }))
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    filters_toggle: false,
                }))
                setPopups(prevValue => ({
                    ...prevValue,
                    filters_toggle: false,
                }))
            })

    }

    useEffect(() => {
        console.log({ shopInfoForm })
    }, [shopInfoForm])

    return (
        <div className='shop-info'>
            <PageHeading
                className={'shop-info__heading'}
                title={current_location.location.name}
                // goBack={{
                //     onClick: () => { console.log('fdffd') },
                // }}
                actions={actions}
            />
            <ErrorMessage error={error} />
            <ItemForm
                containerClassName={'shop-info__item-from-container'}
            >
                <SubmitActionPopup
                    isOpen={popups.filters_toggle}
                    hendleClose={() => {
                        setPopups(prevValue => ({
                            ...prevValue,
                            filters_toggle: false,
                        }))
                    }}
                    hendleSubmit={() => {
                        handleToggleFiltersUpdate()
                    }}
                    text={`Вы действительно хотите ${shopInfoForm ? !shopInfoForm.using_filters ? 'активировать' : 'скрыть' : ''} отрисовку всех фильтров на всем сайте?`}
                    submitText={`${shopInfoForm ? !shopInfoForm.using_filters ? 'Активировать' : 'Скрыть' : ''}`}
                    cencelText={'Отменить'}
                    isSubmitPreloader={preloaders.filters_toggle}
                />
                <div className='shop-info__two-columns'>
                    <div className='shop-info__column'>
                        <PlatformInput
                            value={shopInfoForm.name}
                            placeholder={''}
                            label={'Название'}
                            // hint={`${shopInfoForm.title.length}/100`}
                            handleChange={handleShopInfoChange}
                            name={'name'}
                            maxLength={100}
                            readOnly={!hasEditPermission}
                        >
                        </PlatformInput>
                        <PlatformInput
                            value={shopInfoForm.description}
                            placeholder={''}
                            label={'Описание для поисковиков'}
                            // hint={`${shopInfoForm.title.length}/100`}
                            handleChange={handleShopInfoChange}
                            name={'description'}
                            maxLength={100}
                            readOnly={!hasEditPermission}
                        >
                        </PlatformInput>
                        <PlatformInput
                            value={shopInfoForm.official_name}
                            placeholder={''}
                            label={'Название юридического лица'}
                            // hint={`${shopInfoForm.title.length}/100`}
                            handleChange={handleShopInfoChange}
                            name={'official_name'}
                            maxLength={100}
                            readOnly={!hasEditPermission}

                        >
                        </PlatformInput>
                        <PlatformInput
                            value={shopInfoForm.categories.map((item) => {
                                return {
                                    ...item,
                                    title: item.title.ru ? item.title.ru : item.title,
                                }
                            })}
                            placeholder={''}
                            label={'Категории магазина'}
                            // hint={`${shopInfoForm.title.length}/100`}
                            handleChange={handleShopInfoChange}
                            name={'categories'}
                            maxLength={100}
                            isMultipleSelect={true}
                            readOnly={!hasEditPermission}

                            selectList={shopCategories.map((item) => {
                                return {
                                    ...item,
                                    title: item.title.ru,
                                }
                            })}
                            selectTextValueKey={'title'}
                            selectDifferenceKey={'_id'}
                        >
                        </PlatformInput>
                    </div>
                    <div className='shop-info__column'>
                        {hasEditPermission ?
                            <div className={`constant-input`}>
                                <div className="constant-input__heading">
                                    <p className="constant-input__title">Видимость фильтров на сайте</p>
                                </div>
                                <Toggle
                                    isActive={shopInfoForm.using_filters}
                                    className={'constant-input__toggle'}
                                    handleClick={handleToggleFiltersClick}
                                />
                            </div>
                            : null}
                    </div>
                </div>

            </ItemForm>

        </div>
    );
}

export default ShopInfo