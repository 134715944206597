
import './AddressForm.css';
import AddressInput from './AddressInput/AddressInput';
import PlatformInput from '../../../../PlatformInput/PlatformInput';
import { handleAddressFormChange } from './utils/addressFromUtils';

function AddressForm({ form, setForm, disabled }) {

    function handleChange(e) {

    }
    return (
        <div className='address-form'>
            <AddressInput
                form={form}
                setForm={setForm}
                disabled={disabled}
            />
            <div className='address-form__extra'>
                <PlatformInput
                    value={form.apartment}
                    handleChange={(e) => {
                        handleAddressFormChange(e, setForm)
                    }}
                    label={'Квартира'}
                    type={'text'}
                    name={'apartment'}
                    readOnly={disabled}
                />
                <PlatformInput
                    value={form.entry_code}
                    handleChange={(e) => {
                        handleAddressFormChange(e, setForm)
                    }}
                    label={'Домофон'}
                    type={'text'}
                    name={'entry_code'}
                    readOnly={disabled}
                />
            </div>
            <div className='address-form__extra'>
                <PlatformInput
                    value={form.entrance}
                    handleChange={(e) => {
                        handleAddressFormChange(e, setForm)
                    }}
                    label={'Подъезд'}
                    type={'text'}
                    name={'entrance'}
                    readOnly={disabled}
                />
                <PlatformInput
                    value={form.floor}
                    handleChange={(e) => {
                        handleAddressFormChange(e, setForm)
                    }}
                    label={'Этаж'}
                    type={'text'}
                    name={'floor'}
                    readOnly={disabled}
                />
            </div>
            <div className='address-form__comment'>
                <PlatformInput
                    value={form.comment}
                    handleChange={(e) => {
                        handleAddressFormChange(e, setForm)
                    }}
                    label={'Комментарий покупателю'}
                    type={'text'}
                    name={'comment'}
                    readOnly={disabled}
                />
            </div>


        </div>

    );
}

export default AddressForm