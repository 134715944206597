import {
    YMap,
    YMapComponentsProvider,
    YMapDefaultSchemeLayer,
    YMapDefaultFeaturesLayer,
    YMapListener,
    YMapFeature,
    YMapCollection,
    YMapControls,
    YMapGeolocationControl,
    YMapZoomControl,
    YMapHint,
    YMapDefaultMarker,
    YMapContainer,
    YMapControlButton,
    YMapHintContext,
    YMapMarker,
    YMapClusterer,
} from "ymap3-components";
import { Y_MAP_API_KEY } from '../../../../../../assets/utils/constants';

import './AddressMap.css';
import { DEFAULT_MAP_STYLE } from "../../../../../../assets/mapStyles/default";
import { useCallback, useContext } from "react";
import { colorMap } from "../AddressForm/utils/addressFromUtils";
import { parsePrice } from "../../../../../../assets/utils/utils";

function MyHint() {
    const hint = useContext(YMapHintContext);
    console.log({ hint })
    // distance_km: 10,
    // cost: '100',
    // time: '5 минут',
    return (
        <div className="address-map__hint" >
            {/* <div className="address-map__hint-title">{hint?.hint.title}</div> */}
            <div className="address-map__hint-zone">
                <p className="address-map__hint-zone-title">Зона</p>
                <p className="address-map__hint-zone-number" style={{
                    backgroundColor: `${colorMap[hint?.hint.i]?.stroke}`,
                }}
                >{hint?.hint.i + 1}</p>
            </div>
            {/* <div className="address-map__hint-info">
                <p className="address-map__hint-info-title">Радиус</p>
                <p className="address-map__hint-info-value">{hint?.hint.distance_km} км</p>
            </div>
            <div className="address-map__hint-info">
                <p className="address-map__hint-info-title">Время</p>
                <p className="address-map__hint-info-value">{hint?.hint.time}</p>
            </div>
            <div className="address-map__hint-info">
                <p className="address-map__hint-info-title">Цена</p>
                <p className="address-map__hint-info-value">{parsePrice(hint?.hint.cost, 'RUB')}</p>
            </div> */}
        </div>
    );
}



function AddressMap({ location, setYmap, features, className, preloader, disabled }) {
    const getHint = useCallback((object) => object?.properties?.hint, []);

    return (
        <div className={`address-map ${className ? className : ''}`}>
            {preloader ? <div className="address-map__preloader" /> : null}
            <div className="address-map__box">
                <YMapComponentsProvider apiKey={Y_MAP_API_KEY} lang="ru_RU">
                    <YMap
                        key="map"
                        ref={(ymap) => {
                            if (!setYmap) return
                            console.log(ymap)
                            setYmap(ymap)
                        }}
                        location={location}
                        mode="vector"
                        theme="dark"
                        controls={disabled ? [] : ['default']} // Disable controls if 'disabled' is true
                        // behaviors={disabled
                        //     ? ["disable('scrollZoom')", "disable('drag')", "disable('dblClickZoom')", "disable('multiTouch')"]
                        //     : ['default', "disable('scrollZoom')", "disable('drag')", "disable('dblClickZoom')", "disable('multiTouch')"]
                        // } // Disable map interactions if 'disabled' is true
                    >
                        <YMapDefaultSchemeLayer customization={DEFAULT_MAP_STYLE} />
                        <YMapDefaultFeaturesLayer />
                        <YMapHint hint={getHint}>
                            <MyHint />
                        </YMapHint>
                        {/* <YMapListener onUpdate={onUpdate} /> */}

                        <YMapCollection>
                            {features && features.map((feature, i) => (
                                <YMapFeature key={feature.id} {...feature.feature} onClick={() => {
                                    console.log(feature)
                                    if (feature.onClick && !disabled) {
                                        feature.onClick(feature)
                                    }
                                }} />
                            ))}
                        </YMapCollection>
                        <YMapDefaultMarker coordinates={location.center} />
                        {!disabled && (
                            <YMapControls position="top right">
                                <YMapZoomControl />
                            </YMapControls>
                        )}
                        {!disabled && (
                            <YMapControls position="bottom left">
                                <YMapGeolocationControl />
                            </YMapControls>
                        )}

                    </YMap>
                </YMapComponentsProvider>
            </div>
        </div>

    );
}

export default AddressMap