
import { useContext, useEffect, useRef, useState } from 'react';
import './NoDelivery.css';
import { UserContext } from '../../../../../../assets/contexts/userContext';
import mainApi from '../../../../../../assets/api/MainApi';
import PreloaderBox from '../../../../../PreloaderBox/PreloaderBox';
import AddressMap from '../AddressMap/AddressMap';
import publicApi from '../../../../../../assets/api/PublicApi';
import AddressForm from '../AddressForm/AddressForm';
import { deepEqual, formatStringOrEmptyString, hasPermission, parsePermissions } from '../../../../../../assets/utils/utils';
import { ActionsContext } from '../../../../../../assets/contexts/actionsContext';
import { createFeatureCircle, getRandomColor } from '../AddressForm/utils/addressFromUtils';
import { PERMISSIONS, RBAC_SHOP_SETTINGS } from '../../../../../../assets/utils/permissions_rbac';
import { NO_DELIVERY_TYPE, OWN_DELIVERY_TYPE } from '../../../../../../assets/utils/constants';



const INIT_FORM = {
    address: '',
    hint_selected: '',
    initial_value: '',
    entrance: '',
    entry_code: '',
    floor: '',
    apartment: '',
    comment: '',
}


function NoDelivery({ initData, setInitData }) {
    const { setActions } = useContext(ActionsContext)
    const blockRef = useRef()
    const { user } = useContext(UserContext)
    const [data, setData] = useState(undefined)
    const [prevForm, setPrevForm] = useState(INIT_FORM)
    const [form, setForm] = useState(INIT_FORM)
    const [preloaders, setPreloaders] = useState({
        main: true,
        hints: false,
        map: true,
        save: false,
        toggle_active: false,
    })

    const [addressHints, setAddressHints] = useState(null)

    const [location, setLocation] = useState({ center: [37.95, 55.65], zoom: 14 });
    const [ymap, setYmap] = useState(null);
    const [features, setFetures] = useState(null);


    useEffect(() => {
        if (!user) return
        setPreloaders(prevValue => ({
            ...prevValue,
            main: true,
        }))
        mainApi.getDelivery({
            shop_id: user.default_shop._id,
            delivery_type: NO_DELIVERY_TYPE,
        })
            .then((res) => {

                console.log({
                    res,
                })
                setData({
                    ...res,
                })
                if (res.data) {
                    const res_data = res.data
                    const { store_address, additional_address_data, coords } = res_data
                    const { entrance = '', entry_code = '', floor = '', apartment = '', comment = '' } = additional_address_data || {}

                    setForm(prevValue => ({
                        ...prevValue,
                        address: store_address,
                        hint_selected: store_address,
                        initial_value: store_address,
                        entrance: formatStringOrEmptyString(entrance),
                        entry_code: formatStringOrEmptyString(entry_code),
                        floor: formatStringOrEmptyString(floor),
                        apartment: formatStringOrEmptyString(apartment),
                        comment: formatStringOrEmptyString(comment),
                    }))
                    setPrevForm({
                        address: store_address,
                        hint_selected: store_address,
                        initial_value: store_address,
                        entrance: formatStringOrEmptyString(entrance),
                        entry_code: formatStringOrEmptyString(entry_code),
                        floor: formatStringOrEmptyString(floor),
                        apartment: formatStringOrEmptyString(apartment),
                        comment: formatStringOrEmptyString(comment),
                    })
                    if (coords) {

                        setLocation({ center: coords, zoom: 14 })
                        setPreloaders(prevValue => ({
                            ...prevValue,
                            map: false,
                        }))
                    }
                }
            })
            .catch((err) => {
                console.log(err)
                if (err.statusCode === 403) {
                    setData(undefined)
                }
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    main: false,
                }))
            })
    }, [user])






    useEffect(() => {
        const hint_selected = form.hint_selected
        const address = form.address

        if (!hint_selected || hint_selected === address) return
        setPreloaders(prevValue => ({
            ...prevValue,
            map: true,
        }))
        publicApi.getCoordinates({ address: hint_selected })
            .then((res) => {
                setLocation({ center: [res.lon, res.lat], zoom: 14 })
                setFetures(null)
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    map: false,
                }))
            })

    }, [form.hint_selected, form.address])



    function handleSave() {
        setPreloaders(prevValue => ({
            ...prevValue,
            save: true,
        }))
        const {
            hint_selected,
            entrance,
            entry_code,
            floor,
            apartment,
            comment
        } = form
        mainApi.editDelivery({
            shop_id: user.default_shop._id,
            delivery_type: NO_DELIVERY_TYPE,
            store_address: hint_selected,
            entrance: entrance,
            entry_code: entry_code,
            floor: floor,
            apartment: apartment,
            comment: comment,
            is_active: initData.formatted[NO_DELIVERY_TYPE].is_active,
        })
            .then((res) => {
                setPrevForm({
                    address: hint_selected,
                    hint_selected: hint_selected,
                    initial_value: hint_selected,
                    entrance: formatStringOrEmptyString(entrance),
                    entry_code: formatStringOrEmptyString(entry_code),
                    floor: formatStringOrEmptyString(floor),
                    apartment: formatStringOrEmptyString(apartment),
                    comment: formatStringOrEmptyString(comment),
                })
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    save: false,
                }))
            })
    }

    function handleFakeToggleActive(){
        console.log('handleFakeToggleActive')
        setInitData(prevValue => ({
            ...prevValue,
            formatted: {
                ...prevValue.formatted,
                [NO_DELIVERY_TYPE]: {
                    is_active: true,
                }
            }
        }))
    }

    function handleToggleActive() {
        console.log('handleToggleActive')
        setPreloaders(prevValue => ({
            ...prevValue,
            toggle_active: true,
        }))
        mainApi.toggleDelivery({
            shop_id: user.default_shop._id,
            delivery_type: NO_DELIVERY_TYPE,
        })
            .then((res) => {
                console.log(res)
                setInitData(prevValue => ({
                    ...prevValue,
                    formatted: {
                        ...prevValue.formatted,
                        [NO_DELIVERY_TYPE]: {
                            ...prevValue.formatted[NO_DELIVERY_TYPE],
                            is_active: !prevValue.formatted[NO_DELIVERY_TYPE].is_active,
                        }
                    }
                }))
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    toggle_active: false,
                }))
            })
    }
    const hasManageDeliveryPermission = user ? hasPermission(parsePermissions(user), [RBAC_SHOP_SETTINGS[PERMISSIONS.MANAGE_DELIVERY]]) : false

    useEffect(() => {
        const actionsArray = hasManageDeliveryPermission ?
            initData ?
                !initData.formatted[NO_DELIVERY_TYPE] ?
                    [
                        {
                            name: 'Активировать самовывоз',
                            onClick: () => handleFakeToggleActive(),
                            isMainAction: true,
                            id: "no-delivery_fake-toggle-active_true",
                            isPreloaderVisible: preloaders.toggle_active,
                        },
                    ]
                    :
                    initData.formatted[NO_DELIVERY_TYPE]?.is_active ?
                        [
                            {
                                name: false ? 'Успешно сохранено' : "Сохранить",
                                onClick: () => handleSave(),
                                isMainAction: true,
                                inactive: !(!deepEqual({
                                    store_address: form.hint_selected,
                                    entrance: form.entrance,
                                    entry_code: form.entry_code,
                                    floor: form.floor,
                                    apartment: form.apartment,
                                    comment: form.comment,
                                }, {
                                    store_address: prevForm.hint_selected,
                                    entrance: prevForm.entrance,
                                    entry_code: prevForm.entry_code,
                                    floor: prevForm.floor,
                                    apartment: prevForm.apartment,
                                    comment: prevForm.comment,
                                }) && form.hint_selected),
                                id: "no-delivery_save",
                                isPreloaderVisible: preloaders.save,
                            },
                            {
                                name: 'Отключить самовывоз',
                                onClick: () => handleToggleActive(),
                                isMainAction: false,
                                inactive: !initData?.formatted[OWN_DELIVERY_TYPE]?.is_active ? true : false,
                                id: "no-delivery_toggle-active_false",
                                isPreloaderVisible: preloaders.toggle_active,
                            },
                        ]
                        :
                        [
                            {
                                name: 'Активировать самовывоз',
                                onClick: () => handleToggleActive(),
                                isMainAction: true,
                                id: "no-delivery_toggle-active_true",
                                isPreloaderVisible: preloaders.toggle_active,
                            },
                        ]
                :
                []
            :
            []

        setActions(actionsArray)

    }, [form, initData, preloaders.toggle_active, preloaders.save, prevForm, hasManageDeliveryPermission])

    return (
        <>
            {!preloaders.main ?
                <div className={`no-delivery ${!initData?.formatted[NO_DELIVERY_TYPE]?.is_active ? 'no-delivery_deactiveted' : ''}`} ref={blockRef}>
                    <AddressForm
                        form={form}
                        setForm={setForm}
                        disabled={!hasManageDeliveryPermission}
                    />
                    {form.hint_selected ?
                        <AddressMap
                            className={'no-delivery__map'}
                            location={location}
                            features={features}
                            setYmap={setYmap}
                            preloader={preloaders.map}
                            disabled={hasManageDeliveryPermission ? !initData?.formatted[NO_DELIVERY_TYPE]?.is_active : true}
                        />
                        :
                        null
                    }
                </div>
                :
                <PreloaderBox />
            }
        </>

    );
}

export default NoDelivery