import { getRandomId } from "../../../../../../../assets/utils/utils";

export function handleAddressFormChange(e, setForm, setPreloaders, setAddressHints) {
    const input = e.target;
    let value = input.value;
    const name = input.name
    switch (name) {
        case 'address': {
            if (value.length > 3) {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    hints: true,
                }))
            } else {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    hints: false,
                }))
            }
            setForm(prevValue => ({
                ...prevValue,
                [name]: value,
                hint_selected: '',
            }))
            setAddressHints(null)
            break;
        }

        default: {
            setForm(prevValue => ({
                ...prevValue,
                [name]: value,
            }))
            break;
        }
    }
}

export function getYandexZoomLevelForRadius(radius, latitude, mapWidthInPixels = 600, padding = 1.5) {
    const earthCircumference = 40075017; // Earth's circumference in meters
    const mapWidth = mapWidthInPixels; // Actual map container width in pixels (adjust to your map width)

    // Convert radius to diameter and apply padding to make sure the circle fits with some space
    const diameter = 2 * radius * padding;

    // Calculate the zoom level considering the latitude (Yandex uses Mercator projection)
    const zoomLevel = Math.log2((earthCircumference * Math.cos(latitude * Math.PI / 180)) / (diameter * mapWidth));

    // Yandex Maps zoom levels range from 0 to 23, clamp the result within this range
    return Math.min(Math.max(zoomLevel, 0), 23);
}


export function createFeatureCircle({ lat, lon, r, n, stroke, fill, setLocation, mapRef, zoneHint }) {
    console.log({ lat, lon, r, n, stroke, fill })
    const points = [];
    const k = Math.cos(lat * Math.PI / 180);
    for (let i = 0; i < n; i++) {
        points.push([
            lon + (r / 111 / k) * Math.cos((2 * Math.PI / n) * i),
            lat + (r / 111) * Math.sin((2 * Math.PI / n) * i)
        ]);
    }
    const zoom = getYandexZoomLevelForRadius(r, lat, mapRef && mapRef.current ? mapRef.current.clientWidth : 500);
    setLocation(prevValue => ({ ...prevValue, zoom: zoom }))
    return {
        id: getRandomId(),
        geometry: {
            type: 'Polygon',
            coordinates: [points],
        },
        style: {
            stroke: [{ color: stroke, width: 2 }],
            fill: fill,
        },
        properties: { hint: zoneHint },
    }
}

export const colorMap = [
    { stroke: '#DC0000', fill: 'rgba(255, 0, 0, 0.18)' },
    { stroke: '#C2008C', fill: 'rgba(255, 0, 184, 0.18)' }, 
    { stroke: '#0029FF', fill: 'rgba(0, 30, 189, 0.18)' },  
    { stroke: '#0092A7', fill: 'rgba(0, 224, 255, 0.18)' },  
    { stroke: '#60868B', fill: 'rgba(52, 41, 96, 0.18)' }, 
    { stroke: '#228209', fill: 'rgba(227, 255, 145, 0.18)' }, 
    { stroke: '#008AB7', fill: 'rgba(143, 227, 254, 0.18)' }, 
    { stroke: '#FFB800', fill: 'rgba(255, 184, 0, 0.18)' }, 
    { stroke: '#8F00FF', fill: 'rgba(143, 0, 255, 0.18)' }, 
    { stroke: '#00FFD1', fill: 'rgba(0, 255, 209, 0.18)' }, 
    // { stroke: '#00fc4e', fill: 'rgba(0, 252, 78, 0)' },  // Light Green
    // { stroke: '#8ffc00', fill: 'rgba(143, 252, 0, 0)' }, // Lime Green
    // { stroke: '#fcdb00', fill: 'rgba(252, 219, 0, 0)' }, // Yellow
    // { stroke: '#fcae00', fill: 'rgba(252, 174, 0, 0)' }, // Orange
    // { stroke: '#fc4e00', fill: 'rgba(252, 78, 0, 0)' },  // Red-Orange
    // { stroke: '#fc0000', fill: 'rgba(252, 0, 0, 0)' },   // Red
    // { stroke: '#fc00ae', fill: 'rgba(252, 0, 174, 0)' }, // Pink
    // { stroke: '#ae00fc', fill: 'rgba(174, 0, 252, 0)' }, // Purple
    // { stroke: '#6000fc', fill: 'rgba(96, 0, 252, 0)' },  // Deep Purple
    // { stroke: '#00fcdb', fill: 'rgba(0, 252, 219, 0)' }, // Cyan
    // { stroke: '#fc00db', fill: 'rgba(252, 0, 219, 0)' }, // Magenta
];

export function getRandomColor() {
    const randomIndex = Math.floor(Math.random() * colorMap.length);
    return colorMap[randomIndex];
}