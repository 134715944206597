import { useEffect, useState } from "react";

import "./MainOrderInfo.css";
import {
  ADDRESS_TYPE,
  DATE_WITH_FORMAT_TYPE,
  DELIVERY_TYPE_TYPE,
  EMAIL_TYPE,
  MAP_TYPE,
  NEW_ADDRESS_TYPE,
  NUMBER_STRING_TYPE,
  OWN_DELIVERY_TYPE,
  PAYMENT_TYPE_TYPE,
  PAYMENT_TYPE_VALUES,
  PHONE_TYPE,
  PRICE_TYPE,
  REQUEST_NUMBER_TYPE,
  STATUS_TYPE,
  SUBMIT_TYPE_TYPE,
  SUBMIT_TYPE_VALUES,
  TEXT_TYPE,
} from "../../../../../assets/utils/constants";
import { parsePhone } from "../../../../../assets/utils/utils";
import InfoBox from "./InfoBox/InfoBox";
import MiniPreloader from "../../../../MiniPreloader/MiniPreloader";
import ErrorMessage from "../../../../ErrorMessage/ErrorMessage";
import { PDFDownloadLink } from '@react-pdf/renderer';
import OrderPdfGenerator from "./OrderPdfGenerator/OrderPdfGenerator";


const MAIN_DATA_MAP = [
  {
    title: "Статус:",
    key: "status",
    type: STATUS_TYPE,
  },
  {
    title: "Номер заказа:",
    key: "ai_id",
    type: REQUEST_NUMBER_TYPE,
  },
  {
    title: "Дата заказа:",
    key: "utc_date",
    type: DATE_WITH_FORMAT_TYPE,
    format: "DD.MM.YYYY HH:mm",
  },
  {
    title: "Сумма:",
    key: "total_price",
    type: PRICE_TYPE,
  },
  {
    title: "Колличество товаров:",
    key: "total_items",
    type: NUMBER_STRING_TYPE,
  },
];

const CLIENT_DATA_MAP = [
  {
    title: "Имя:",
    key: "first_name",
    type: TEXT_TYPE,
  },
  {
    title: "Фамилия:",
    key: "last_name",
    type: TEXT_TYPE,
  },
  {
    title: "Телефон:",
    key: "phone",
    type: PHONE_TYPE,
  },
  {
    title: "Email:",
    key: "email",
    type: EMAIL_TYPE,
  },
  {
    title: "Метод связи:",
    key: "submit_type",
    type: SUBMIT_TYPE_TYPE,
  },
  {
    title: "Метод оплаты:",
    key: "payment_type",
    type: PAYMENT_TYPE_TYPE,
  },
];

const PREV_DELIVERY_DATA_MAP = [
  {
    title: "Адрес:",
    key: "address",
    type: ADDRESS_TYPE,
  },
];



function MainOrderInfo({ data, isPreloaderVisible, setOrder, error, orderItmes }) {
  // const [data, setData] = useState({
  //   request_number: 123,
  //   utc_date: "2023-11-19T20:53:15+00:00",
  //   total_price: "29500",
  //   total_items: "3",
  //   status: "new",
  //   first_name: "Валентина",
  //   last_name: "Попова",
  //   phone: "79955944221",
  //   email: "valpopova1@yandex.ru",
  //   submit_type: "telegram",
  //   payment_type: "manager",
  //   address: {
  //     city: "Санкт-Петербург",
  //     street: "пр.Маршала Жукова",
  //     house: "д.36 к.1",
  //     apartment: "252",
  //   },
  // });

  const NEW_DELIVERY_DATA_MAP = data ? data.delivery_type && data.delivery_type === OWN_DELIVERY_TYPE ?
    [
      {
        title: "Адрес:",
        key: "address",
        type: TEXT_TYPE,
      },
      {
        title: "Квартира:",
        key: "apartment",
        type: TEXT_TYPE,
      },
      {
        title: "Домофон:",
        key: "entry_code",
        type: TEXT_TYPE,
      },
      {
        title: "Подъезд:",
        key: "entrance",
        type: TEXT_TYPE,
      },
      {
        title: "Этаж:",
        key: "floor",
        type: TEXT_TYPE,
      },
      {
        title: "Комментарий:",
        key: "comment",
        type: TEXT_TYPE,
      },
      {
        title: "Тип:",
        key: "delivery_type",
        type: DELIVERY_TYPE_TYPE,
      },
      {
        title: "Стоимость:",
        key: "delivery_price",
        type: PRICE_TYPE,
      },
      {
        // title: "На карте:",
        key: "coords",
        type: MAP_TYPE,
      },
    ]
    :
    [
      {
        title: "Тип:",
        key: "delivery_type",
        type: DELIVERY_TYPE_TYPE,
      },
    ]
    :
    []



  return !isPreloaderVisible ? (
    <div className="main-order-info">
      {data && orderItmes ?
        <PDFDownloadLink
          document={<OrderPdfGenerator data={data} orderItmes={orderItmes} />}
          fileName={`order-${data?.ai_id}.pdf`}
          className="main-order-info__download"
        >
          {({ loading }) =>
            loading ?
              <>
                Создаем файл, подождите...
              </>
              :
              <>
                Выгрузить заказ в PDF
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.00699 13.5H14.007" stroke="var(--text-on-contrast-color)" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M9.5 9.3L10.7 10.5L12.7 8.5" stroke="var(--text-on-contrast-color)" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M18.449 19.396L17.459 19.8935C17.3206 19.963 17.168 19.9995 17.0132 20C16.8583 20.0005 16.7055 19.965 16.5667 19.8964L14.9942 19.1188L13.4533 19.8929C13.3146 19.9626 13.1615 19.999 13.0062 19.9993C12.851 19.9996 12.6978 19.9637 12.5588 19.8945L11 19.1185L9.44124 19.8945C9.30224 19.9637 9.14903 19.9996 8.99376 19.9993C8.83849 19.999 8.68542 19.9626 8.54668 19.8929L7.00578 19.1188L5.4333 19.8964C5.2945 19.965 5.14166 20.0005 4.98682 20C4.83198 19.9995 4.67937 19.963 4.54102 19.8935L3.55102 19.396C3.38538 19.3128 3.24614 19.1851 3.14884 19.0273C3.05153 18.8696 3 18.6878 3 18.5025V3.49752C3 3.31215 3.05153 3.13042 3.14883 2.97264C3.24613 2.81486 3.38537 2.68723 3.551 2.604L4.541 2.10648C4.67935 2.03695 4.83196 2.0005 4.9868 2.00001C5.14164 1.99951 5.29448 2.03497 5.43328 2.10361L7.00576 2.8812L8.54666 2.10713C8.6854 2.03743 8.83847 2.00099 8.99374 2.00071C9.14901 2.00043 9.30222 2.03631 9.44122 2.10551L11 2.88151L12.5588 2.10551C12.6978 2.03631 12.851 2.00043 13.0062 2.00071C13.1615 2.00099 13.3146 2.03743 13.4533 2.10713L14.9942 2.8812L16.5667 2.10361C16.7055 2.03497 16.8583 1.99951 17.0132 2.00001C17.168 2.0005 17.3206 2.03695 17.459 2.10648L18.449 2.604C18.6146 2.68723 18.7539 2.81486 18.8512 2.97264C18.9485 3.13042 19 3.31214 19 3.49752V18.5025C19 18.6879 18.9484 18.8696 18.8512 19.0274C18.7539 19.1851 18.6146 19.3128 18.449 19.396Z" stroke="var(--text-on-contrast-color)" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </>
          }
        </PDFDownloadLink>
        :
        null
      }

      {data ? (
        <>
          <InfoBox
            data={data.promo_code ?
              {
                ...data,
                promo_discount: Number(data.items_price) - Number(data.total_price),
                promo_value_discount: `${data.promo_code} (-${Number(data.discount)}%)`,
              }
              :
              data
            }
            data_map={data.promo_code ?
              MAIN_DATA_MAP.concat([
                {
                  title: "Промокод:",
                  key: "promo_value_discount",
                  type: TEXT_TYPE,
                },
                {
                  title: "Скидка по промо:",
                  key: "promo_discount",
                  type: PRICE_TYPE,
                },
              ])
              :
              MAIN_DATA_MAP}
            key_name={"main_data"}
            title={"Основная информация"}
            {...{ setOrder }}
          />
          <InfoBox
            data={data}
            data_map={CLIENT_DATA_MAP}
            key_name={"client_data"}
            title={"Данные клиента"}
            {...{ setOrder }}
          />
          {data.delivery_type ?
            <InfoBox
              data={{
                ...data,
                coords: data?.delivery_data?.coords,
                address: data?.delivery_data?.address,
                entrance: data?.delivery_data?.additional_address_data?.entrance,
                entry_code: data?.delivery_data?.additional_address_data?.entry_code,
                floor: data?.delivery_data?.additional_address_data?.floor,
                apartment: data?.delivery_data?.additional_address_data?.apartment,
                comment: data?.delivery_data?.additional_address_data?.comment,
              }}
              data_map={NEW_DELIVERY_DATA_MAP}
              key_name={"delivery"}
              title={"Данные о доставке"}
              {...{ setOrder }}
            />
            :
            <InfoBox
              data={data}
              data_map={PREV_DELIVERY_DATA_MAP}
              key_name={"delivery_data_prev"}
              title={"Данные о доставке"}
              {...{ setOrder }}
            />
          }

        </>
      ) : error ? (
        <ErrorMessage error={error} />
      ) : null}
    </div>
  ) : (
    <div className="prev-orders__preloader">
      <MiniPreloader />
    </div>
  );
}

export default MainOrderInfo;
