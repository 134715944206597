import { MAIN_PUBLIC_URL } from "../utils/constants";

class PublicApi {
    constructor({ baseUrl }) {
        this._BASE_URL = baseUrl;
    }

    _checkResponse(res) {
        if (res.ok) {
            return res.json();
        } else {
            const statusCode = res.status;
            return res.json().then((res) => {
                return Promise.reject({
                    statusCode: statusCode,
                    message: res.msg,
                    detail: res.detail,
                });
            });
        }
    }

    searchAddress({ query }) {
        let params = {};
        if (query) params.query = query;

        return fetch(
            `${MAIN_PUBLIC_URL}/delivery/search-address?` +
            new URLSearchParams({ data: JSON.stringify(params) }),
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        ).then(this._checkResponse);
    }

    getCoordinates({ address }) {
        let params = {};
        if (address) params.address = address;

        return fetch(
            `${MAIN_PUBLIC_URL}/delivery/get-coordinates?` +
            new URLSearchParams({ data: JSON.stringify(params) }),
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        ).then(this._checkResponse);
    }

}

const publicApi = new PublicApi({
    baseUrl: MAIN_PUBLIC_URL,
});

export default publicApi;
