
import { useContext, useEffect, useState } from 'react';
import PlatformInput from '../../../PlatformInput/PlatformInput';
import ActionBtn from '../../../ActionBtn/ActionBtn';
import ServiceContainer from '../../../ServiceContainer/ServiceContainer';
import TwoFactorBtn from '../../../TwoFactorBtn/TwoFactorBtn';
import './YandexMetrics.css';
import { UserContext } from '../../../../../assets/contexts/userContext';
import mainApi from '../../../../../assets/api/MainApi';
import PreloaderBox from '../../../../PreloaderBox/PreloaderBox';
import { ActionsContext } from '../../../../../assets/contexts/actionsContext';


function YandexMetrics() {
    const { setActions } = useContext(ActionsContext)
    useEffect(() => {
        setActions([])
    }, [])
    const [isConnected, setIsConnected] = useState(false)

    const [form, setForm] = useState({
        counter_id: '',
    })

    const { user } = useContext(UserContext)

    const [data, setData] = useState(null)
    const [preloaders, setPreloaders] = useState({
        main: true,
        connect: false,
        disconnect: false,
    })
    useEffect(() => {
        if (!user) return
        setPreloaders(prevValue => ({
            ...prevValue,
            main: true,
        }))
        mainApi.getYandexMetricsIntegration({
            shop_id: user.default_shop._id,
        })
            .then((res) => {
                console.log(res)
                setData({
                    ...res,
                    // is_connected: true,
                })
            })
            .catch((err) => {
                console.log(err)
                if (err.statusCode === 403) {
                    setData(null)
                }
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    main: false,
                }))
            })
    }, [user])

    function handleConnect() {
        // setIsConnected(true)
        setPreloaders(prevValue => ({
            ...prevValue,
            connect: true,
        }))
        mainApi.setYandexMetricsIntegration({
            shop_id: user.default_shop._id,
            id: form.counter_id,
        })
            .then((res) => {
                console.log(res)
                setData({
                    ...res,
                    // is_connected: true,
                })
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    connect: false,
                }))
            })
    }

    function handleDisconnect() {
        // setIsConnected(true)
        setPreloaders(prevValue => ({
            ...prevValue,
            disconnect: true,
        }))
        mainApi.setYandexMetricsIntegration({
            shop_id: user.default_shop._id,
            id: null,
        })
            .then((res) => {
                console.log(res)
                setData({
                    ...res,
                    // is_connected: true,
                })
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    disconnect: false,
                }))
            })
    }

    function handleChange(e) {
        const input = e.target;
        let value = input.value;
        const name = input.name
        let inputValue = value.replace(/\D/g, '')
        switch (name) {
            default:
                setForm(prevValue => ({
                    ...prevValue,
                    [name]: inputValue,
                }))
                break;
        }
    }
    return (


        <>
            {!preloaders.main ?
                <div className='yandex-metrics'>
                    <p className='yandex-metrics__title'>Счетчик Яндекс Метрики</p>
                    {!data.id ?
                        <>
                            <PlatformInput
                                value={form.counter_id}
                                handleChange={handleChange}
                                label={'ID счетчика '}
                                type={'text'}
                                inputMode='numeric'
                                name={'counter_id'}
                            />
                            <ActionBtn
                                text={'Подключить счетчик'}
                                handleAction={handleConnect}
                                isInactive={!(form.counter_id.length >= 8)}
                                isLoading={preloaders.connect}
                            />

                            <p className='yandex-metrics__hint'>Для эффективного отслеживания действий покупателей и&nbsp;доступа к&nbsp;подробной аналитике, пожалуйста, подключите ваш счетчик. Важно: при настройке счетчика активируйте функции &rsquo;Вебвизор, Карта скроллинга и Аналитика форм&rsquo;, чтобы максимально расширить возможности анализа.</p>
                        </>
                        :
                        <>
                            <ServiceContainer
                                title={'Счетчик подключен'}
                                subtitle={`id: ${data.id}`}
                            >

                            </ServiceContainer>
                            <TwoFactorBtn
                                firstStepBtnText={'Отключить Метрику'}
                                secondStepBtnText={'Отключить'}
                                handleAction={handleDisconnect}
                                cancelBtnText={'Отмена'}
                                confirmText={'Вы&nbsp;уверены что хотите отключить Яндекс Метрику?'}
                                isLoading={preloaders.disconnect}
                            />
                        </>
                    }
                </div>
                :
                <PreloaderBox />
            }
        </>
    );
}

export default YandexMetrics