import './ZoneForm.css';
import Button from '../../../../../../Button/Button';
import { colorMap, createFeatureCircle } from '../../AddressForm/utils/addressFromUtils';
import { parsePrice } from '../../../../../../../assets/utils/utils';
import { EditIcon } from '../../../../../../../assets/icons/category/category';
import { CrossIcon } from '../../../../../../../assets/icons/controls/controls';
import ZonePopup from './ZonePopup/ZonePopup';
import { useState } from 'react';

const DEFAULT_TIME_ZONE = {
    distance_km: '',
    cost: "",
    time: "",

}


function ZoneForm({ form, setForm, location, setLocation, blockRef, popups, setPopups, handleZoneEdit, disabled }) {


    function handleZoneEditClose() {
        setPopups(prevValue => ({
            ...prevValue,
            zoneEdit: false,
        }))
        setTimeout(() => {
            setForm(prevValue => ({
                ...prevValue,
                zoneToEdit: null,
            }))
        }, 300);
    }

    function handleZoneRemove(zone) {
        console.log(zone)
        setForm(prevValue => ({
            ...prevValue,
            delivery_zones: prevValue.delivery_zones.filter((item) => item.feature.id !== zone.feature.id)
        }))
    }

    function handleAddZone() {

        if (disabled) return
        setForm(prevValue => {
            const delivery_zones = prevValue.delivery_zones
            const index = delivery_zones?.length ? delivery_zones.length : 0
            const distance_km = index > 0 ? delivery_zones[index - 1].distance_km + 3 : 3
            const color = colorMap[index]
            const circle = createFeatureCircle({
                lon: location.center[0],
                lat: location.center[1],
                r: distance_km,
                n: 1000,
                stroke: color.stroke,
                fill: color.fill,
                setLocation: setLocation,
                mapRef: blockRef,
                zoneHint: {
                    title: `Зона № ${index}`,
                    i: index,
                    distance_km: distance_km,
                    cost: '',
                    time: '',
                }
            })

            return ({
                ...prevValue,
                delivery_zones: delivery_zones.concat({
                    ...DEFAULT_TIME_ZONE,
                    distance_km: distance_km,
                    feature: circle,
                    onClick: handleZoneEdit,
                })
            })
        })
    }

    console.log(form)
    return (
        <div className='zone-form'>
            <ZonePopup
                form={form}
                setForm={setForm}
                isOpen={popups.zoneEdit}
                handleClose={handleZoneEditClose}
                zone={form.zoneToEdit}
                location={location}
                setLocation={setLocation}
                blockRef={blockRef}
            />
            <p className='zone-form__title'>Зоны доставки*</p>
            <div className='zone-form__zones'>
                <div className='zone-form__zones-heading'>
                    <p className='zone-form__zones-heading-title'>Зона</p>
                    <p className='zone-form__zones-heading-title'>Время</p>
                    <p className='zone-form__zones-heading-title'>Радиус</p>
                    <p className='zone-form__zones-heading-title'>Цена</p>
                </div>
                <div className='zone-form__zone-items'>
                    {form.delivery_zones && form.delivery_zones.length > 0 ?
                        form.delivery_zones.map((zone, i) => {
                            return (
                                <div className='zone-form__zone' key={`zone-form__zone${i}_${zone.feature.id}`}>
                                    <p className='zone-form__zone-number' style={{
                                        background: colorMap[i].stroke,
                                    }}>{i + 1}</p>
                                    <p className='zone-form__zone-value'>{zone.time ? zone.time.title : '-'}</p>
                                    <p className='zone-form__zone-value'>{zone.distance_km ? zone.distance_km + ` км` : '-'}</p>
                                    <p className='zone-form__zone-value'>{zone.cost ? parsePrice(zone.cost, 'RUB') : '-'}</p>
                                    <div className='zone-form__zone-controls'>
                                        <button className='zone-form__zone-edit-btn' type='button' onClick={() => {
                                            if (disabled) return
                                            handleZoneEdit(zone)
                                        }}>
                                            <EditIcon
                                                mainClassName={'zone-form__zone-edit'}
                                                fillClassName={'zone-form__zone-edit-icon'}
                                            />
                                        </button>
                                        {form.delivery_zones.length === i + 1 ?
                                            <button className='zone-form__zone-cross-btn' type='button' onClick={() => {
                                                if (disabled) return
                                                handleZoneRemove(zone)
                                            }}>
                                                <CrossIcon
                                                    strokeClassName={'zone-form__zone-cross-icon'}
                                                    mainClassName={'zone-form__zone-cross'}
                                                />
                                            </button>
                                            : null}

                                    </div>


                                </div>
                            )
                        })
                        : null}
                </div>
                <div className='zone-form__btn-box'>
                    {form.delivery_zones.length < 10 ?
                        <Button
                            text={'Добавить зону'}
                            isContrastColor
                            className={'zone-form__btn'}
                            onClick={() => {
                                handleAddZone()
                            }}
                        />
                        : null}

                </div>

            </div>
        </div>



    );
}

export default ZoneForm