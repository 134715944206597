import './BlockPopup.css';

import DesktopPopup from '../DesktopPopup/DesktopPopup';
import icon from '../../assets/images/block/blocked.webp'
import { Link } from 'react-router-dom';
import { PLATFORM_MAIN_LINK, SETTINGS_FINANCE_LINK, SETTINGS_MAIN_LINK } from '../../assets/utils/constants';
import { useContext } from 'react';
import { UserContext } from '../../assets/contexts/userContext';
import { hasPermission, parsePermissions } from '../../assets/utils/utils';
import { PERMISSIONS, RBAC_SHOP_SETTINGS } from '../../assets/utils/permissions_rbac';
import moment from 'moment-timezone';

function BlockPopup({ isOpen, handleClose, popupName }) {
    const { user } = useContext(UserContext)
    const hasBillingPermission = user ? hasPermission(parsePermissions(user), [RBAC_SHOP_SETTINGS[PERMISSIONS.MANAGE_BILLING]]) : false


    return (
        <DesktopPopup
            isOpen={isOpen}
            handleClose={() => {
                return
            }}
            popupName={popupName}
            darkerBg={true}
        >
            <div className='block-popup'>
                <img className='block-popup__icon' src={icon} alt='' />
                <p className='block-popup__title'>{hasBillingPermission ? 'Ваш магазин заблокирован' : `Магазин ${user.default_shop.info.name} заблокирован`}</p>
                {hasBillingPermission ?
                    <p className='block-popup__text'>Пожалуйста обновите данные оплаты, <br />чтобы продолжить пользоваться магазином.</p>
                    :
                    <p className='block-popup__text'>Необходимо обновить данные оплаты, <br />чтобы продолжить пользоваться магазином.</p>
                }
                <p className='block-popup__text'>Магазин будет удален {user?.default_shop?.subscriptions?.main?.renewal?.blocked_till_utc_datetime ? moment(user.default_shop.subscriptions.main.renewal.blocked_till_utc_datetime + '+00:00').format('DD.MM.YYYY') : ''}</p>


                {hasBillingPermission ?
                    <Link className='block-popup__btn' to={`${PLATFORM_MAIN_LINK}/${SETTINGS_MAIN_LINK}/${SETTINGS_FINANCE_LINK}`} onClick={() => {
                        handleClose({ name: popupName })
                    }}>
                        Обновить данные оплаты
                    </Link>
                    :
                    <button className='block-popup__btn' type='button' onClick={() => {
                        handleClose({ name: popupName })
                    }}>
                        Закрыть
                    </button>
                }
            </div>

        </DesktopPopup>

    );
}

export default BlockPopup;