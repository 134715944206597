
import PlatformInput from '../../../PlatformInput/PlatformInput';
import ActionBtn from '../../../ActionBtn/ActionBtn';
import ServiceContainer from '../../../ServiceContainer/ServiceContainer';
import TwoFactorBtn from '../../../TwoFactorBtn/TwoFactorBtn';
import './WebService.css';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../../assets/contexts/userContext';
import mainApi from '../../../../../assets/api/MainApi';
import PreloaderBox from '../../../../PreloaderBox/PreloaderBox';
import { ActionsContext } from '../../../../../assets/contexts/actionsContext';


function WebService() {
    const { setActions } = useContext(ActionsContext)
    useEffect(() => {
        setActions([])
    }, [])
    const { user } = useContext(UserContext)

    const [data, setData] = useState(null)
    const [preloaders, setPreloaders] = useState({
        main: true,
    })
    useEffect(() => {
        if (!user) return
        setPreloaders(prevValue => ({
            ...prevValue,
            main: true,
        }))
        mainApi.getWebExtraIntegration({
            shop_id: user.default_shop._id,
        })
            .then((res) => {
                console.log(res)
                setData({
                    ...res,
                    // is_connected: true,
                })
            })
            .catch((err) => {
                console.log(err)
                if (err.statusCode === 403) {
                    setData(null)
                }
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    main: false,
                }))
            })
    }, [user])

    function handleDownload(url) {
        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.blob();
            })
            .then(blob => {
                // Create a blob URL
                const blobUrl = window.URL.createObjectURL(blob);

                // Create an anchor element
                const anchor = document.createElement('a');
                anchor.style.display = 'none';
                anchor.href = blobUrl;
                anchor.download = url.split('/').pop(); // Extract the file name

                // Append the anchor to the body, click it, and then remove it
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);

                // Revoke the blob URL
                window.URL.revokeObjectURL(blobUrl);
            })
            .catch(e => console.error('Download error:', e));
    }

    return (

        <>
            {!preloaders.main ?
                <div className='web-service'>
                    <p className='web-service__title'>Полезные файлы для веб-сервисов</p>
                    <PlatformInput
                        value={data.url_offers_yml}
                        readOnly={true}
                        label={'Файл YML с информацией о товарах'}
                    />
                    <ActionBtn
                        text={'Скопировать ссылку на файл'}
                        isCopy={true}
                        copyValue={data.url_offers_yml}
                    />
                    <ActionBtn
                        text={'Скачать файл'}
                        handleAction={() => {
                            handleDownload(data.url_offers_yml)
                        }}
                    />

                    <PlatformInput
                        value={data.url_sitemap_xml}
                        readOnly={true}
                        label={'Файл Sitemap XML'}
                    />
                    <ActionBtn
                        text={'Скопировать ссылку на файл'}
                        isCopy={true}
                        copyValue={data.url_sitemap_xml}
                    />
                    <ActionBtn
                        text={'Скачать файл'}
                        handleAction={() => {
                            handleDownload(data.url_sitemap_xml)
                        }}
                    />

                </div>
                :
                <PreloaderBox />
            }
        </>

    );
}

export default WebService