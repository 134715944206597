
import { useContext, useEffect, useState } from 'react';
import './Delivery.css';
import { UserContext } from '../../../../../assets/contexts/userContext';
import mainApi from '../../../../../assets/api/MainApi';
import PreloaderBox from '../../../../PreloaderBox/PreloaderBox';
import { Navigate, NavLink, Route, Routes } from 'react-router-dom';
import NoDelivery from './NoDelivery/NoDelivery';
import OwnDelivery from './OwnDelivery/OwnDelivery';
import { DeliveryContext } from '../../../../../assets/contexts/deliveryContext';
import { NO_DELIVERY_TYPE, OWN_DELIVERY_TYPE } from '../../../../../assets/utils/constants';


function Delivery() {
    const { user } = useContext(UserContext)
    const [data, setData] = useState(undefined)
    const [preloaders, setPreloaders] = useState({
        main: true,
    })
    const [defaultTimeZones, setDefaultTimeZones] = useState(null)

    useEffect(() => {
        if (!user) return
        setPreloaders(prevValue => ({
            ...prevValue,
            main: true,
        }))
        const getAllDeliveries = mainApi.getAllDeliveries({
            shop_id: user.default_shop._id,
        })
            .then((res) => {
                let formattedData = {
                    [OWN_DELIVERY_TYPE]: null,
                    [NO_DELIVERY_TYPE]: null
                };

                res.data.forEach((item) => {
                    switch (item.delivery_type) {
                        case OWN_DELIVERY_TYPE: {
                            formattedData[OWN_DELIVERY_TYPE] = item;
                            break;
                        }

                        case NO_DELIVERY_TYPE: {
                            formattedData[NO_DELIVERY_TYPE] = item;
                            break;
                        }

                        default: {
                            break;
                        }
                    }
                });

                console.log({
                    ...res,
                    formatted: formattedData,
                })
                setData({
                    ...res,
                    formatted: formattedData,
                })
            })
            .catch((err) => {
                console.log(err)
                if (err.statusCode === 403) {
                    setData(undefined)
                }
            })

        const getDeliveryZones = mainApi.getDeliveryZonesStatic()
            .then((res) => {
                console.log(res.data);
                setDefaultTimeZones(res.data)
            })
            .catch((err) => {
                console.log(err);
            });

        // Use Promise.all to wait for both requests to finish
        Promise.all([getAllDeliveries, getDeliveryZones])
            .then(() => {
                // Both requests completed successfully
                console.log('Both requests completed');
            })
            .catch((err) => {
                // Handle any errors that occurred in either request
                console.log('Error occurred during one or both requests', err);
            })
            .finally(() => {
                // Set the main preloader to false after both requests are complete
                setPreloaders(prevValue => ({
                    ...prevValue,
                    main: false,
                }));
            });
    }, [user])

    return (
        <>
            {!preloaders.main ?
                <DeliveryContext.Provider value={{
                    defaultTimeZones
                }}>
                    <div className='delivery'>
                        <p className='delivery__title'>Доставка</p>
                        <div className='delivery__selectors'>
                            <NavLink className={({ isActive }) => `delivery__selector ${isActive ? 'delivery__selector_active' : ''}`} to={NO_DELIVERY_TYPE}>
                                Самовывоз · {data?.formatted[NO_DELIVERY_TYPE]?.is_active ? 'Активен' : 'Отключен'}
                            </NavLink>
                            <NavLink className={({ isActive }) => `delivery__selector ${isActive ? 'delivery__selector_active' : ''}`} to={OWN_DELIVERY_TYPE}>
                                Доставка · {data?.formatted[OWN_DELIVERY_TYPE]?.is_active ? 'Активна' : 'Отключена'}
                            </NavLink>
                        </div>
                        <Routes>
                            <Route index element={<Navigate to={NO_DELIVERY_TYPE} />} />
                            <Route path={NO_DELIVERY_TYPE} element={<NoDelivery initData={data} setInitData={setData} />} />
                            <Route path={OWN_DELIVERY_TYPE} element={<OwnDelivery initData={data} setInitData={setData} />} />
                        </Routes>

                    </div>
                </DeliveryContext.Provider>

                :
                <PreloaderBox />
            }
        </>

    );
}

export default Delivery