
import { useContext, useEffect, useRef, useState } from 'react';
import './OwnDelivery.css';
import { UserContext } from '../../../../../../assets/contexts/userContext';
import mainApi from '../../../../../../assets/api/MainApi';
import PreloaderBox from '../../../../../PreloaderBox/PreloaderBox';
import AddressMap from '../AddressMap/AddressMap';
import publicApi from '../../../../../../assets/api/PublicApi';
import { deepEqual, formatStringOrEmptyString, getRandomId, hasPermission, parsePermissions, StringOrNull } from '../../../../../../assets/utils/utils';
import { ActionsContext } from '../../../../../../assets/contexts/actionsContext';
import { colorMap, createFeatureCircle, getRandomColor, getYandexZoomLevelForRadius } from '../AddressForm/utils/addressFromUtils';
import AddressInput from '../AddressForm/AddressInput/AddressInput';
import ZoneForm from './ZoneForm/ZoneForm';
import { DeliveryContext } from '../../../../../../assets/contexts/deliveryContext';
import { PERMISSIONS, RBAC_SHOP_SETTINGS } from '../../../../../../assets/utils/permissions_rbac';
import { NO_DELIVERY_TYPE, OWN_DELIVERY_TYPE } from '../../../../../../assets/utils/constants';



const INIT_FORM = {
    address: '',
    hint_selected: '',
    initial_value: '',
    delivery_zones: [],
}

function OwnDelivery({ initData, setInitData }) {
    const { defaultTimeZones } = useContext(DeliveryContext)
    const { setActions } = useContext(ActionsContext)
    const blockRef = useRef()
    const { user } = useContext(UserContext)
    const [data, setData] = useState(undefined)
    const [prevForm, setPrevForm] = useState(INIT_FORM)
    const [form, setForm] = useState(INIT_FORM)
    const [preloaders, setPreloaders] = useState({
        main: true,
        hints: false,
        map: true,
        save: false,
        toggle_active: false,
    })

    const [location, setLocation] = useState({ center: [37.95, 55.65], zoom: 14 });
    const [ymap, setYmap] = useState(null);
    const [popups, setPopups] = useState({
        zoneEdit: false,
    })

    function handleZoneEdit(zone) {
        console.log(zone)
        setLocation(prevValue => ({ ...prevValue, zoom: getYandexZoomLevelForRadius(zone.distance_km, location.center[1], blockRef && blockRef.current ? blockRef.current.clientWidth : 500) }))

        setForm(prevValue => ({
            ...prevValue,
            zoneToEdit: zone,
        }))
        setPopups(prevValue => ({
            ...prevValue,
            zoneEdit: true,
        }))

    }



    useEffect(() => {
        if (!user) return;

        // Set main preloader to true before starting the requests
        setPreloaders(prevValue => ({
            ...prevValue,
            main: true,
        }));

        // Create both requests
        const getOwnDelivery = mainApi.getDelivery({
            shop_id: user.default_shop._id,
            delivery_type: OWN_DELIVERY_TYPE,
        })
            .then((res) => {
                console.log({ res });
                setData({ ...res });
                if (res.data) {
                    const res_data = res.data;
                    const { store_address, coords, delivery_zones } = res_data;

                    const mappedDeliveryZones = delivery_zones.map((item, i) => {
                        const time = defaultTimeZones.find(zone => zone.delta === item.time)
                        const circle = createFeatureCircle({
                            lon: coords[0],
                            lat: coords[1],
                            r: item.distance_km,
                            n: 1000,
                            stroke: colorMap[i].stroke,
                            fill: colorMap[i].fill,
                            setLocation: setLocation,
                            mapRef: blockRef,
                            zoneHint: {
                                title: `Зона № ${i + 1}`,
                                i: i,
                                distance_km: item.distance_km,
                                cost: item.cost,
                                time: {
                                    title: time.title.ru,
                                    delta: time.delta,
                                },
                            }
                        })
                        return {
                            ...item,
                            id: getRandomId(),
                            time: {
                                title: time.title.ru,
                                delta: time.delta,
                            },
                            feature: circle,
                            onClick: handleZoneEdit,
                        }
                    })
                    setForm(prevValue => ({
                        ...prevValue,
                        address: store_address,
                        hint_selected: store_address,
                        initial_value: store_address,
                        delivery_zones: mappedDeliveryZones,
                    }));
                    setPrevForm({
                        address: store_address,
                        hint_selected: store_address,
                        initial_value: store_address,
                        delivery_zones: mappedDeliveryZones,
                    });
                    if (coords) {
                        setLocation({ center: coords, zoom: 14 });
                        setPreloaders(prevValue => ({
                            ...prevValue,
                            map: false,
                        }));
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.statusCode === 403) {
                    setData(undefined);
                }
            });



        // Use Promise.all to wait for both requests to finish
        Promise.all([getOwnDelivery])
            .then(() => {
                // Both requests completed successfully
                console.log('Both requests completed');
            })
            .catch((err) => {
                // Handle any errors that occurred in either request
                console.log('Error occurred during one or both requests', err);
            })
            .finally(() => {
                // Set the main preloader to false after both requests are complete
                setPreloaders(prevValue => ({
                    ...prevValue,
                    main: false,
                }));
            });
    }, [user])






    useEffect(() => {
        const hint_selected = form.hint_selected
        const address = form.address

        if (!hint_selected || hint_selected === address) return
        setPreloaders(prevValue => ({
            ...prevValue,
            map: true,
        }))
        publicApi.getCoordinates({ address: hint_selected })
            .then((res) => {
                setLocation({ center: [res.lon, res.lat], zoom: 14 })
                setForm(prevValue => ({
                    ...prevValue,
                    delivery_zones: [],
                }))
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    map: false,
                }))
            })

    }, [form.hint_selected, form.address])



    function handleSave() {
        setPreloaders(prevValue => ({
            ...prevValue,
            save: true,
        }))
        const {
            hint_selected,
            delivery_zones,
        } = form
        mainApi.editDelivery({
            shop_id: user.default_shop._id,
            delivery_type: OWN_DELIVERY_TYPE,
            store_address: hint_selected,
            is_active: initData.formatted[OWN_DELIVERY_TYPE].is_active,
            delivery_zones: delivery_zones.map((item) => ({
                distance_km: Number(item.distance_km),
                cost: Number(item.cost) > 0 ? StringOrNull(item.cost) : null,
                time: item.time.delta,
            })),
        })
            .then((res) => {
                setPrevForm({
                    address: hint_selected,
                    hint_selected: hint_selected,
                    initial_value: hint_selected,
                    delivery_zones: delivery_zones,
                })
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    save: false,
                }))
            })
    }

    function handleFakeToggleActive() {
        console.log('handleFakeToggleActive')
        setInitData(prevValue => ({
            ...prevValue,
            formatted: {
                ...prevValue.formatted,
                [OWN_DELIVERY_TYPE]: {
                    is_active: true,
                }
            }
        }))
    }

    function handleToggleActive() {
        console.log('handleToggleActive')
        setPreloaders(prevValue => ({
            ...prevValue,
            toggle_active: true,
        }))
        mainApi.toggleDelivery({
            shop_id: user.default_shop._id,
            delivery_type: OWN_DELIVERY_TYPE,
        })
            .then((res) => {
                console.log(res)
                setInitData(prevValue => ({
                    ...prevValue,
                    formatted: {
                        ...prevValue.formatted,
                        [OWN_DELIVERY_TYPE]: {
                            ...prevValue.formatted[OWN_DELIVERY_TYPE],
                            is_active: !prevValue.formatted[OWN_DELIVERY_TYPE].is_active,
                        }
                    }
                }))
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    toggle_active: false,
                }))
            })
    }

    const hasManageDeliveryPermission = user ? hasPermission(parsePermissions(user), [RBAC_SHOP_SETTINGS[PERMISSIONS.MANAGE_DELIVERY]]) : false


    useEffect(() => {
        const validateDeliveryZones = (deliveryZones) => {
            return deliveryZones.every(item => {
                const isDistanceValid = item.distance_km && item.distance_km > 0 && item.distance_km <= 10000;
                const isTimeValid = Boolean(item.time);  // Checks if time exists

                // Optional cost doesn't require validation as per rules, but we can check if it exists optionally
                return isDistanceValid && isTimeValid;
            });
        }
        const deliveryZones = form.delivery_zones.map((item) => ({
            distance_km: item.distance_km,
            cost: StringOrNull(item.cost),
            time: item.time.delta,
        }));
        const prevDeliveryZones = prevForm.delivery_zones.map((item) => ({
            distance_km: item.distance_km,
            cost: StringOrNull(item.cost),
            time: item.time.delta,
        }));

        const actionsArray = hasManageDeliveryPermission ?
            initData ?
                !initData.formatted[OWN_DELIVERY_TYPE] ?
                    [
                        {
                            name: 'Активировать доставку',
                            onClick: () => handleFakeToggleActive(),
                            isMainAction: true,
                            id: "own-delivery_fake-toggle-active_true",
                            isPreloaderVisible: preloaders.toggle_active,
                        },
                    ]
                    :
                    initData.formatted[OWN_DELIVERY_TYPE]?.is_active ?
                        [
                            {
                                name: false ? 'Успешно сохранено' : "Сохранить",
                                onClick: () => handleSave(),
                                isMainAction: true,
                                inactive: !(!deepEqual({
                                    store_address: form.hint_selected,
                                    delivery_zones: deliveryZones,
                                }, {
                                    store_address: prevForm.hint_selected,
                                    delivery_zones: prevDeliveryZones,
                                }) && form.hint_selected && form.delivery_zones.length > 0 && validateDeliveryZones(deliveryZones)),
                                id: "own-delivery_save",
                                isPreloaderVisible: preloaders.save,
                            },
                            {
                                name: 'Отключить доставку',
                                onClick: () => handleToggleActive(),
                                isMainAction: false,
                                inactive: !initData?.formatted[NO_DELIVERY_TYPE]?.is_active ? true : false,
                                id: "own-delivery_toggle-active_false",
                                isPreloaderVisible: preloaders.toggle_active,
                            },
                        ]
                        :
                        [
                            {
                                name: 'Активировать доставку',
                                onClick: () => handleToggleActive(),
                                isMainAction: true,
                                id: "own-delivery_toggle-active_true",
                                isPreloaderVisible: preloaders.toggle_active,
                            },
                        ]
                :
                []
            :
            []


        setActions(actionsArray)

    }, [form, initData, preloaders.toggle_active, preloaders.save, prevForm, hasManageDeliveryPermission])



    return (
        <>
            {!preloaders.main ?
                <div className={`own-delivery ${!initData?.formatted[OWN_DELIVERY_TYPE]?.is_active ? 'own-delivery_deactiveted' : ''}`} ref={blockRef}>
                    <div className='own-delivery__hint'>
                        <h3 className='own-delivery__hint-title'>Как это работает</h3>
                        <p className='own-delivery__hint-text'>Стоимость доставки рассчитывается по&nbsp;зонам, которые вы&nbsp;создаёте сами, в&nbsp;зависимости от&nbsp;удалённости от&nbsp;магазина. Магазин самостоятельно осуществляет доставку. Вы&nbsp;можете настроить цену для каждой зоны. Если заказ вне зоны, покупатель получит сообщение о&nbsp;невозможности доставки.</p>
                    </div>
                    <AddressInput
                        form={form}
                        setForm={setForm}
                        disabled={!hasManageDeliveryPermission}
                    />
                    <div className='own-delivery__form-and-map'>

                    </div>

                    <ZoneForm
                        form={form}
                        setForm={setForm}
                        location={location}
                        setLocation={setLocation}
                        blockRef={blockRef}
                        popups={popups}
                        setPopups={setPopups}
                        handleZoneEdit={handleZoneEdit}
                        disabled={!hasManageDeliveryPermission}
                    />
                    {form.hint_selected ?
                        <AddressMap
                            className={'own-delivery__map'}
                            location={location}
                            features={form.delivery_zones}
                            setYmap={setYmap}
                            preloader={preloaders.map}
                            disabled={hasManageDeliveryPermission ? !initData?.formatted[OWN_DELIVERY_TYPE]?.is_active : true}
                        />
                        :
                        null
                    }
                </div>
                :
                <PreloaderBox />
            }
        </>

    );
}

export default OwnDelivery