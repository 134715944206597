import { MAIN_API_URL, MAIN_URL, OWN_DELIVERY_TYPE } from "../utils/constants";
import { StringOrNull } from "../utils/utils";

class MainApi {
  constructor({ baseUrl }) {
    this._BASE_URL = baseUrl;
  }

  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        return Promise.reject({
          statusCode: statusCode,
          message: res.msg,
          detail: res.detail,
        });
      });
    }
  }

  _checkResponseWithCookies({ res, func, params }) {
    if (res.ok) {
      return res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        if (statusCode === 401) {
          console.log("ss");
          return mainApi
            .refreshJWT()
            .then((res) => {
              return func(params);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        } else {
          return Promise.reject({
            statusCode: statusCode,
            message: res.msg,
            detail: res.detail,
          });
        }
      });
    }
  }

  refreshJWT() {
    return fetch(`${this._BASE_URL}/auth/refresh-jwt`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(this._checkResponse);
  }

  login({ email, password }) {
    return fetch(`${this._BASE_URL}/auth/login`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    }).then(this._checkResponse);
  }

  logout() {
    return fetch(`${this._BASE_URL}/auth/logout`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(this._checkResponse);
  }

  getMe() {
    return fetch(`${MAIN_URL}/auth/get-me`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getMe,
        params: {},
      })
    );
  }

  signup({ email, password, phone, first_name, last_name }) {
    return fetch(`${this._BASE_URL}/auth/register/signup`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        language: "ru",
        email: email,
        password: password,
        phone: phone,
        first_name: first_name,
        last_name: last_name,
      }),
    }).then(this._checkResponse);
  }

  signupWithShop({ phone, first_name, last_name, password }) {
    return fetch(`${this._BASE_URL}/auth/register/signup-part-two`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phone: phone,
        first_name: first_name,
        last_name: last_name,
        password: password ? password : null,
      }),
    }).then(this._checkResponse);
  }

  resendVerificationEmail() {
    return fetch(`${this._BASE_URL}/auth/register/resend-email`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(this._checkResponse);
  }

  resetPasswordNOAuth({ email }) {
    return fetch(`${MAIN_URL}/auth/forgot-password/main-not-logged-in`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    }).then(this._checkResponse);
  }

  resetPasswordWithAuth() {
    return fetch(`${this._BASE_URL}/auth/forgot-password/main`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.resetPasswordWithAuth,
        params: {},
      })
    );
  }

  changePassword({ password, token }) {
    return fetch(`${MAIN_URL}/auth/forgot-password/change`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ password, token }),
    }).then(this._checkResponse);
  }

  checkCurrentPassword({ current_password }) {
    return fetch(`${MAIN_URL}/auth/password/check`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ current_password }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.checkCurrentPassword,
        params: { current_password },
      })
    );
  }

  authChangeEmail({ email }) {
    return fetch(`${MAIN_URL}/auth/change-email/main`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.authChangeEmail,
        params: { email },
      })
    );
  }

  authChangeEmailСheckCode({ code }) {
    return fetch(`${MAIN_URL}/auth/change-email/check-code`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.authChangeEmailСheckCode,
        params: { code },
      })
    );
  }

  getOrders({ last_id, limit, shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (last_id) params.last_id = last_id;
    if (limit) params.limit = limit;

    return fetch(
      `${MAIN_URL}/shop/orders/get-all?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getOrders,
        params: { last_id, limit, shop_id },
      })
    );
  }

  getExactOrder({ shop_id, _id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (_id) params._id = _id;

    return fetch(
      `${MAIN_URL}/shop/orders/get-exact?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getExactOrder,
        params: { shop_id, _id },
      })
    );
  }

  getPrevOrders({ shop_id, user_id, exclude_id, last_id, limit }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (user_id) params.user_id = user_id;
    if (exclude_id) params.exclude_id = exclude_id;
    if (last_id) params.last_id = last_id;
    if (limit) params.limit = limit;

    return fetch(
      `${MAIN_URL}/shop/orders/get-orders-by-user-id?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getPrevOrders,
        params: { shop_id, user_id, exclude_id, last_id, limit },
      })
    );
  }

  editStatus({ shop_id, _id, payment_type, status }) {
    return fetch(`${MAIN_URL}/shop/orders/edit-status`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ shop_id, _id, payment_type, status }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editStatus,
        params: { shop_id, _id, payment_type, status },
      })
    );
  }

  getOrderItems({ shop_id, _id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (_id) params._id = _id;

    return fetch(
      `${MAIN_URL}/shop/orders/items/get-by-order-id?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getOrderItems,
        params: { shop_id, _id },
      })
    );
  }

  getAllDrafts({ shop_id, limit, categories, last_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (limit) params.limit = limit;
    if (categories) params.categories = categories;
    if (last_id) params.last_id = last_id;

    return fetch(
      `${MAIN_URL}/shop/products/drafts/get-all?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getAllDrafts,
        params: { shop_id, limit, categories },
      })
    );
  }

  getDraftById({ shop_id, _id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (_id) params._id = _id;

    return fetch(
      `${MAIN_URL}/shop/products/drafts/get-by-id?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getDraftById,
        params: { shop_id, _id },
      })
    );
  }

  publishDraft({ shop_id, data, type }) {
    return fetch(`${MAIN_URL}/shop/products/drafts/publish`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ shop_id, data, type }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.publishDraft,
        params: { shop_id, data, type },
      })
    );
  }

  createDraft({
    shop_id,
    files,
    unique_id,
    name,
    description,
    price_data,
    categories,
    promo_groups,
    is_visible,
    options,
    parameters,
  }) {
    return fetch(`${MAIN_URL}/shop/products/drafts/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id,
        files: files ? files : [],
        unique_id,
        name,
        description,
        price_data,
        categories,
        promo_groups,
        is_visible,
        options,
        parameters,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.createDraft,
        params: {
          shop_id,
          files,
          unique_id,
          name,
          description,
          price_data,
          categories,
          promo_groups,
          is_visible,
          options,
          parameters,
        },
      })
    );
  }

  editDraft({
    shop_id,
    files,
    _id,
    unique_id,
    name,
    description,
    price_data,
    categories,
    promo_groups,
    is_visible,
    options,
    parameters,
  }) {
    return fetch(`${MAIN_URL}/shop/products/drafts/edit`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id,
        files: files ? files : [],
        _id,
        unique_id,
        name,
        description,
        price_data,
        categories,
        promo_groups,
        is_visible,
        options,
        parameters,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editDraft,
        params: {
          shop_id,
          files,
          _id,
          unique_id,
          name,
          description,
          price_data,
          categories,
          promo_groups,
          is_visible,
          options,
          parameters,
        },
      })
    );
  }

  deleteDraft({ shop_id, _id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (_id) params._id = _id;

    return fetch(
      `${MAIN_URL}/shop/products/drafts/delete?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.deleteDraft,
        params: { shop_id, _id },
      })
    );
  }

  getAllItemsPublished({ shop_id, limit, categories, last_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (limit) params.limit = limit;
    if (categories) params.categories = categories;
    if (last_id) params.last_id = last_id;

    return fetch(
      `${MAIN_URL}/shop/products/published/get-all?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getAllItemsPublished,
        params: { shop_id, limit, categories, last_id },
      })
    );
  }

  getItemPublishedById({ shop_id, _id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (_id) params._id = _id;

    return fetch(
      `${MAIN_URL}/shop/products/published/get-by-id?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getItemPublishedById,
        params: { shop_id, _id },
      })
    );
  }

  moveToDrafts({ shop_id, type, data }) {
    return fetch(`${MAIN_URL}/shop/products/published/unpublish`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ shop_id, type, data }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.moveToDrafts,
        params: { shop_id, type, data },
      })
    );
  }

  editItemPublished({
    shop_id,
    files,
    _id,
    unique_id,
    name,
    description,
    price_data,
    categories,
    promo_groups,
    is_visible,
    options,
    parameters,
  }) {
    return fetch(`${MAIN_URL}/shop/products/published/edit`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id,
        files: files ? files : [],
        _id,
        unique_id,
        name,
        description,
        price_data,
        categories,
        promo_groups,
        is_visible,
        options,
        parameters,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editItemPublished,
        params: {
          shop_id,
          files,
          _id,
          unique_id,
          name,
          description,
          price_data,
          categories,
          promo_groups,
          is_visible,
          options,
          parameters,
        },
      })
    );
  }

  deletePublishedItem({ shop_id, _id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (_id) params._id = _id;

    return fetch(
      `${MAIN_URL}/shop/products/published/delete?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.deletePublishedItem,
        params: { shop_id, _id },
      })
    );
  }

  checkUniqueId({ _id, shop_id, unique_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (_id) params._id = _id;
    if (unique_id) params.unique_id = unique_id;

    return fetch(
      `${MAIN_URL}/shop/products/check-unique-id?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.checkUniqueId,
        params: { _id, shop_id, unique_id },
      })
    );
  }

  getCouponGroups({ last_id, limit, shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (last_id) params.last_id = last_id;
    if (limit) params.limit = limit;

    return fetch(
      `${MAIN_URL}/shop/coupon/groups/get-all?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getCouponGroups,
        params: { last_id, limit, shop_id },
      })
    );
  }

  getExactCouponGroup({ _id, shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (_id) params._id = _id;

    return fetch(
      `${MAIN_URL}/shop/coupon/groups/get-by-id?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getExactCouponGroup,
        params: { _id, shop_id },
      })
    );
  }

  createCouponGroup({ shop_id, name }) {
    return fetch(`${MAIN_URL}/shop/coupon/groups/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ shop_id, name }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.createCouponGroup,
        params: { shop_id, name },
      })
    );
  }

  editCouponGroup({ shop_id, name, _id }) {
    return fetch(`${MAIN_URL}/shop/coupon/groups/edit`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ shop_id, name, _id }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editCouponGroup,
        params: { shop_id, name, _id },
      })
    );
  }

  getCodesGroup({ _id, shop_id, last_id, limit }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (_id) params._id = _id;
    if (last_id) params.last_id = last_id;
    if (limit) params.limit = limit;

    return fetch(
      `${MAIN_URL}/shop/coupon/codes/get-all?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getCodesGroup,
        params: { _id, shop_id, last_id, limit },
      })
    );
  }

  toggleCoupon({ shop_id, _id, value }) {
    return fetch(`${MAIN_URL}/shop/coupon/codes/toggle`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ shop_id, _id, value }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.toggleCoupon,
        params: { shop_id, _id, value },
      })
    );
  }

  createPromo({
    shop_id,
    _id,
    name,
    code,
    discount,
    valid_until,
    usage_limit,
  }) {
    return fetch(`${MAIN_URL}/shop/coupon/codes/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id,
        _id,
        name,
        code,
        discount,
        valid_until,
        usage_limit,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.createPromo,
        params: {
          shop_id,
          _id,
          name,
          code,
          discount,
          valid_until,
          usage_limit,
        },
      })
    );
  }

  checkUniquePromo({ shop_id, code }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (code) params.code = code;

    return fetch(
      `${MAIN_URL}/shop/coupon/codes/check?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.checkUniquePromo,
        params: { shop_id, code },
      })
    );
  }

  getShopInfoCategories() {
    return fetch(`${MAIN_URL}/shop/settings/shop-categories?`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getShopInfoCategories,
        params: {},
      })
    );
  }

  getShopInfo({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    return fetch(
      `${MAIN_URL}/shop/get-data?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getShopInfo,
        params: { shop_id },
      })
    );
  }

  updateShopInfo({ shop_id, name, official_name, categories, description }) {
    return fetch(`${MAIN_URL}/shop/settings/update-shop-info`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id,
        name,
        official_name,
        categories,
        description,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.updateShopInfo,
        params: { shop_id, name, official_name, categories, description },
      })
    );
  }

  getUserManagentAll({ last_id, limit, shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (last_id) params.last_id = last_id;
    if (limit) params.limit = limit;

    return fetch(
      `${MAIN_URL}/shop/user-management/get-all?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getUserManagentAll,
        params: { last_id, limit, shop_id },
      })
    );
  }

  revokeAccessUserManagent({ _id, shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (_id) params._id = _id;

    return fetch(
      `${MAIN_URL}/shop/user-management/revoke-access?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.revokeAccessUserManagent,
        params: { _id, shop_id },
      })
    );
  }

  createUserManagmentUser({ shop_id, email, comment, role_id }) {
    return fetch(`${MAIN_URL}/shop/user-management/grant-access`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id,
        email,
        comment,
        role_id,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.createUserManagmentUser,
        params: { shop_id, email, comment, role_id },
      })
    );
  }

  getAllCategories({ _id, shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (_id) params._id = _id;

    return fetch(
      `${MAIN_URL}/shop/categories/get-all?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getAllCategories,
        params: { _id, shop_id },
      })
    );
  }

  getItemsByCategory({ category, shop_id, limit, last_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (category) params.category = category;
    if (last_id) params.last_id = last_id;
    if (limit) params.limit = limit;

    return fetch(
      `${MAIN_URL}/shop/products/get-all-by-category?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getItemsByCategory,
        params: { category, shop_id, limit, last_id },
      })
    );
  }

  getSiblingCategories({ _id, shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (_id) params._id = _id;

    return fetch(
      `${MAIN_URL}/shop/categories/get-category-siblings?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getSiblingCategories,
        params: { _id, shop_id },
      })
    );
  }

  getIdCategories({ _id, shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (_id) params._id = _id;

    return fetch(
      `${MAIN_URL}/shop/categories/get-by-id?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getIdCategories,
        params: { _id, shop_id },
      })
    );
  }

  createCategory({ shop_id, name, translit_name, parent_id, files }) {
    return fetch(`${MAIN_URL}/shop/categories/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id,
        name,
        translit_name: translit_name ? translit_name : null,
        parent_id: parent_id ? parent_id : null,
        files: files ? files : null,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.createCategory,
        params: { shop_id, name, translit_name, parent_id, files },
      })
    );
  }

  editCategory({ shop_id, files, name, translit_name, _id }) {
    return fetch(`${MAIN_URL}/shop/categories/edit`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ shop_id, files, name, translit_name, _id }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editCategory,
        params: { shop_id, files, name, translit_name, _id },
      })
    );
  }

  deleteCategory({ shop_id, _id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (_id) params._id = _id;

    return fetch(
      `${MAIN_URL}/shop/categories/delete?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.deleteCategory,
        params: { shop_id, _id },
      })
    );
  }

  checkTechnicalDomainPathUniqueness({ path, shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (path) params.path = path;

    return fetch(
      `${MAIN_URL}/shop/settings/domain/check-path?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.checkTechnicalDomainPathUniqueness,
        params: { path, shop_id },
      })
    );
  }

  editTechnicalDomainPath({ shop_id, path }) {
    return fetch(`${MAIN_URL}/shop/settings/domain/set-path`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ shop_id, path }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editTechnicalDomainPath,
        params: { shop_id, path },
      })
    );
  }

  checkCustomDomainConnection({ domain, shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (domain) params.domain = domain;

    return fetch(
      `${MAIN_URL}/shop/settings/domain/check-custom?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.checkCustomDomainConnection,
        params: { domain, shop_id },
      })
    );
  }

  setCustomDomain({ shop_id, domain }) {
    return fetch(`${MAIN_URL}/shop/settings/domain/set-custom`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ shop_id, domain }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.setCustomDomain,
        params: { shop_id, domain },
      })
    );
  }

  unsetCustomDomain({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;

    return fetch(
      `${MAIN_URL}/shop/settings/domain/unset-custom?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.unsetCustomDomain,
        params: { shop_id },
      })
    );
  }

  getYooKassaIntegration({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;

    return fetch(
      `${MAIN_URL}/shop/payments/yookassa/get-data?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getYooKassaIntegration,
        params: { shop_id },
      })
    );
  }

  unsetYooKassaIntegration({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;

    return fetch(
      `${MAIN_URL}/shop/payments/yookassa/unset?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.unsetYooKassaIntegration,
        params: { shop_id },
      })
    );
  }


  setYooKassaVatCode({ shop_id, vat_code }) {
    return fetch(`${MAIN_URL}/shop/payments/yookassa/set-vat-code`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ shop_id, vat_code }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.setYooKassaVatCode,
        params: { shop_id, vat_code },
      })
    );
  }

  getYandexMetricsIntegration({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;

    return fetch(
      `${MAIN_URL}/shop/integrations/yandex-metrics/get-data?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getYandexMetricsIntegration,
        params: { shop_id },
      })
    );
  }

  setYandexMetricsIntegration({ shop_id, id }) {
    return fetch(`${MAIN_URL}/shop/integrations/yandex-metrics/set-data`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ shop_id, id }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.setYandexMetricsIntegration,
        params: { shop_id, id },
      })
    );
  }

  getWebExtraIntegration({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;

    return fetch(
      `${MAIN_URL}/shop/integrations/web-extra/get-data?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getWebExtraIntegration,
        params: { shop_id },
      })
    );
  }

  get1SProductsIntegration({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;

    return fetch(
      `${MAIN_URL}/shop/integrations/1c-products/get-data?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.get1SProductsIntegration,
        params: { shop_id },
      })
    );
  }

  revealPasswordFor1SProductsIntegration({ shop_id }) {
    return fetch(`${MAIN_URL}/shop/integrations/1c-products/reset-password`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ shop_id }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.revealPasswordFor1SProductsIntegration,
        params: { shop_id },
      })
    );
  }

  getEmailNotificationsIntegration({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;

    return fetch(
      `${MAIN_URL}/shop/integrations/email-notifications/get-data?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getEmailNotificationsIntegration,
        params: { shop_id },
      })
    );
  }

  setEmailNotificationsIntegration({ shop_id, emails }) {
    return fetch(`${MAIN_URL}/shop/integrations/email-notifications/set-data`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ shop_id, emails }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.setEmailNotificationsIntegration,
        params: { shop_id, emails },
      })
    );
  }

  getUserRoles() {
    return fetch(`${MAIN_URL}/roles/get-roles`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getUserRoles,
        params: {},
      })
    );
  }

  getConstructorFonts({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;

    return fetch(
      `${MAIN_URL}/shop/constructor/tokens/get-fonts?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getConstructorFonts,
        params: { shop_id },
      })
    );
  }
  getConstructorTokens({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;

    return fetch(
      `${MAIN_URL}/shop/constructor/tokens/get-data?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getConstructorTokens,
        params: { shop_id },
      })
    );
  }

  setConstructorTokens({ shop_id, font_data, colour_data, shape_type }) {
    return fetch(`${MAIN_URL}/shop/constructor/tokens/set-data`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ shop_id, font_data, colour_data, shape_type }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.setConstructorTokens,
        params: { shop_id, font_data, colour_data, shape_type },
      })
    );
  }

  getConstructorCard({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;

    return fetch(
      `${MAIN_URL}/shop/constructor/card/get-data?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getConstructorCard,
        params: { shop_id },
      })
    );
  }

  setConstructorCard({ shop_id, image, price, card_in_line, order }) {
    return fetch(`${MAIN_URL}/shop/constructor/card/set-data`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ shop_id, image, price, card_in_line, order }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.setConstructorCard,
        params: { shop_id, image, price, card_in_line, order },
      })
    );
  }

  getConstructorMainPage({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;

    return fetch(
      `${MAIN_URL}/shop/constructor/main-page/get-data?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getConstructorMainPage,
        params: { shop_id },
      })
    );
  }

  getConstructorMainPageBlocks({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;

    return fetch(
      `${MAIN_URL}/shop/constructor/main-page/get-blocks?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getConstructorMainPageBlocks,
        params: { shop_id },
      })
    );
  }

  setConstructorMainPage({ shop_id, logo, icon, blocks }) {
    return fetch(`${MAIN_URL}/shop/constructor/main-page/set-data`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ shop_id, logo, icon, blocks }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.setConstructorMainPage,
        params: { shop_id, logo, icon, blocks },
      })
    );
  }

  getStaticPagesData({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;

    return fetch(
      `${MAIN_URL}/shop/settings/static/get-data?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getStaticPagesData,
        params: { shop_id },
      })
    );
  }

  getSubscrtiptions({ type }) {
    let params = {};
    if (type) params.type = type;

    return fetch(
      `${MAIN_URL}/subscriptions/get-by-type?` +
      new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getSubscrtiptions,
        params: { type },
      })
    );
  }


  getSubscrtiptionsWithAuth({ type, shop_id }) {
    let params = {};
    if (type) params.type = type;
    if (shop_id) params.shop_id = shop_id;

    return fetch(
      `${MAIN_URL}/subscriptions/get-by-type-authenticated?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getSubscrtiptionsWithAuth,
        params: { type, shop_id },
      })
    );
  }

  subscribeMain({ shop_id, _id, payment_id, return_url }) {
    return fetch(`${MAIN_URL}/shop/subscribe`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ shop_id, _id, payment_id, return_url }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.subscribeMain,
        params: { shop_id, _id, payment_id, return_url },
      })
    );
  }

  getShopSubscrtiption({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;

    return fetch(
      `${MAIN_URL}/shop/get-subscription?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getShopSubscrtiption,
        params: { shop_id },
      })
    );
  }

  setStaticData({ shop_id, data }) {
    return fetch(`${MAIN_URL}/shop/settings/static/set-data`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ shop_id, data }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.setStaticData,
        params: { shop_id, data },
      })
    );
  }

  getShopTasks({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;

    return fetch(
      `${MAIN_URL}/shop/tasks/get-all?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getShopTasks,
        params: { shop_id },
      })
    );
  }

  getOrderConfig({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;

    return fetch(
      `${MAIN_URL}/shop/orders/config?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getOrderConfig,
        params: { shop_id },
      })
    );
  }

  getAvailibleShops({ limit, last_id }) {
    let params = {};
    if (limit) params.limit = limit;
    if (last_id) params.last_id = last_id;

    return fetch(
      `${MAIN_URL}/auth/shops/get-all?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getAvailibleShops,
        params: { limit, last_id },
      })
    );
  }

  setDefaultShop({ _id }) {
    return fetch(`${MAIN_URL}/auth/shops/set-default`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ _id }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.setDefaultShop,
        params: { _id },
      })
    );
  }


  checkIsCreateShopAvailible() {
    return fetch(
      `${MAIN_URL}/shop/is-demo-available`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.checkIsCreateShopAvailible,
        params: {},
      })
    );
  }

  createShop() {
    return fetch(`${MAIN_URL}/shop/create-demo`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.createShop,
        params: {},
      })
    );
  }


  getAllPaymentMethods() {
    return fetch(
      `${MAIN_URL}/payment-methods/get-all`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getAllPaymentMethods,
        params: {},
      })
    );
  }

  createPaymentMethod({ return_url }) {
    return fetch(`${MAIN_URL}/payment-methods/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ return_url }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.createPaymentMethod,
        params: { return_url },
      })
    );
  }

  deletePaymentMethod({ _id }) {
    let params = {};
    if (_id) params._id = _id;

    return fetch(`${MAIN_URL}/payment-methods/delete?` +
      new URLSearchParams({ data: JSON.stringify(params) }), {
      method: "DELETE",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.deletePaymentMethod,
        params: { _id },
      })
    );
  }



  async uploadImage({ data, type }) {
    return fetch(
      `${MAIN_URL}/images/upload?` +
      new URLSearchParams({ data: JSON.stringify({ type }) }),
      {
        method: "POST",
        credentials: "include",
        body: data,
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.uploadImage,
        params: { data, type },
      })
    );
  }

  getFinanceTransactionsByShop({ limit, last_id, shop_id, last_utc_datetime }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id
    if (limit) params.limit = limit
    if (last_id) params.last_id = last_id
    if (last_utc_datetime) params.last_utc_datetime = last_utc_datetime

    return fetch(
      `${MAIN_URL}/txs/by-shop?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getFinanceTransactionsByShop,
        params: { limit, last_id, shop_id, last_utc_datetime },
      })
    );
  }

  getMainPageStatistics({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id


    return fetch(
      `${MAIN_URL}/shop/stats/main?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getMainPageStatistics,
        params: { shop_id },
      })
    );
  }

  getStatsPageStatistics({ shop_id, period = 'day' }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id
    if (period) params.period = period


    return fetch(
      `${MAIN_URL}/shop/stats/statistics-page?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getStatsPageStatistics,
        params: { shop_id, period },
      })
    );
  }

  getOnlineStatistics({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id


    return fetch(
      `${MAIN_URL}/shop/stats/get-online?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getOnlineStatistics,
        params: { shop_id },
      })
    );
  }

  getTelegramNotificationsIntegration({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;

    return fetch(
      `${MAIN_URL}/shop/integrations/telegram-notifications/get-data?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getTelegramNotificationsIntegration,
        params: { shop_id },
      })
    );
  }

  unsetTelegramNotificationsIntegration({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;

    return fetch(
      `${MAIN_URL}/shop/integrations/telegram-notifications/unset?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.unsetTelegramNotificationsIntegration,
        params: { shop_id },
      })
    );
  }


  getAllDocs({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;

    return fetch(
      `${MAIN_URL}/shop/constructor/static-pages/get-all?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getAllDocs,
        params: { shop_id },
      })
    );
  }

  createDoc({ shop_id, name, translit_name, is_visible, html, css }) {
    return fetch(`${MAIN_URL}/shop/constructor/static-pages/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id,
        name,
        translit_name: StringOrNull(translit_name),
        is_visible,
        content: {
          html,
          css: StringOrNull(css),
        },
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.createDoc,
        params: { shop_id, name, translit_name, is_visible, html, css },
      })
    );
  }



  deleteDoc({ shop_id, _id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (_id) params._id = _id

    return fetch(
      `${MAIN_URL}/shop/constructor/static-pages/delete?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.deleteDoc,
        params: { shop_id, _id },
      })
    );
  }


  sortDocs({ shop_id, data }) {
    return fetch(`${MAIN_URL}/shop/constructor/static-pages/sort-items`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id,
        data,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.sortDocs,
        params: { shop_id, data },
      })
    );
  }


  getDoc({ shop_id, _id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (_id) params.value = _id
    params.key = '_id'

    return fetch(
      `${MAIN_URL}/shop/constructor/static-pages/get-exact?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getDoc,
        params: { shop_id, _id },
      })
    );
  }

  editDoc({ shop_id, name, translit_name, is_visible, html, css, _id }) {
    return fetch(`${MAIN_URL}/shop/constructor/static-pages/edit`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id,
        _id,
        name,
        translit_name: StringOrNull(translit_name),
        is_visible,
        content: {
          html,
          css: StringOrNull(css),
        },
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editDoc,
        params: { shop_id, name, translit_name, is_visible, html, css, _id },
      })
    );
  }

  getAllSocials({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;

    return fetch(
      `${MAIN_URL}/shop/constructor/social-networks/get-all?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getAllSocials,
        params: { shop_id },
      })
    );
  }

  createSocial({ shop_id, url, type, is_visible }) {
    return fetch(`${MAIN_URL}/shop/constructor/social-networks/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id,
        url,
        type: type,
        is_visible,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.createSocial,
        params: { shop_id, url, type, is_visible },
      })
    );
  }

  sortSocials({ shop_id, data }) {
    return fetch(`${MAIN_URL}/shop/constructor/social-networks/sort-items`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id,
        data,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.sortSocials,
        params: { shop_id, data },
      })
    );
  }

  getSocial({ shop_id, _id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (_id) params.value = _id
    params.key = '_id'

    return fetch(
      `${MAIN_URL}/shop/constructor/social-networks/get-exact?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getSocial,
        params: { shop_id, _id },
      })
    );
  }

  editSocial({ shop_id, url, type, is_visible, _id }) {
    return fetch(`${MAIN_URL}/shop/constructor/social-networks/edit`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id,
        _id,
        type,
        url,
        is_visible,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editSocial,
        params: { shop_id, url, type, is_visible, _id },
      })
    );
  }

  deleteSocial({ shop_id, _id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (_id) params._id = _id

    return fetch(
      `${MAIN_URL}/shop/constructor/social-networks/delete?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.deleteSocial,
        params: { shop_id, _id },
      })
    );
  }

  getFiltersByCategory({ _id, shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (_id) params._id = _id;

    return fetch(
      `${MAIN_URL}/shop/filters/get-by-category?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getFiltersByCategory,
        params: { _id, shop_id },
      })
    );
  }

  editFilter({ shop_id, name, is_active, _id }) {
    return fetch(`${MAIN_URL}/shop/filters/constructor/edit`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id, name, is_active, _id
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editFilter,
        params: { shop_id, name, is_active, _id },
      })
    );
  }

  toggleFilterActivity({ shop_id, _id }) {
    return fetch(`${MAIN_URL}/shop/filters/constructor/toggle`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id, _id
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.toggleFilterActivity,
        params: { shop_id, _id },
      })
    );
  }

  editSpecialPriceFilter({ shop_id, is_active }) {
    return fetch(`${MAIN_URL}/shop/filters/special/price/update`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id, is_active
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editSpecialPriceFilter,
        params: { shop_id, is_active },
      })
    );
  }

  editSpecialInStockFilter({ shop_id, is_active }) {
    return fetch(`${MAIN_URL}/shop/filters/special/in_stock/update`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id, is_active
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editSpecialInStockFilter,
        params: { shop_id, is_active },
      })
    );
  }

  convertFilterType({ shop_id, _id }) {
    return fetch(`${MAIN_URL}/shop/filters/constructor/convert`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id, _id
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.convertFilterType,
        params: { shop_id, _id },
      })
    );
  }

  getSystemConstantsConfig() {
    return fetch(
      `${MAIN_URL}/shop/constructor/constants/config`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getSystemConstantsConfig,
        params: {},
      })
    );
  }

  getSystemConstants({ values, shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (values) params.values = values;

    return fetch(
      `${MAIN_URL}/shop/constructor/constants/get-data?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getSystemConstants,
        params: { values, shop_id },
      })
    );
  }

  setSystemConstants({ shop_id, data }) {
    return fetch(`${MAIN_URL}/shop/constructor/constants/set-data`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id, data
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.setSystemConstants,
        params: { shop_id, data },
      })
    );
  }

  updateAllFiltersShow({ shop_id, status }) {
    return fetch(`${MAIN_URL}/shop/settings/update-filters`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id, status
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.updateAllFiltersShow,
        params: { shop_id, status },
      })
    );
  }

  getAllHeaderLinks({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;

    return fetch(
      `${MAIN_URL}/shop/constructor/header-links/get-all?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getAllHeaderLinks,
        params: { shop_id },
      })
    );
  }

  createHeaderLink({ shop_id, url, is_visible, name }) {
    return fetch(`${MAIN_URL}/shop/constructor/header-links/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id,
        url,
        is_visible,
        name,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.createHeaderLink,
        params: { shop_id, url, is_visible, name },
      })
    );
  }



  deleteHeaderLink({ shop_id, _id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (_id) params._id = _id

    return fetch(
      `${MAIN_URL}/shop/constructor/header-links/delete?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.deleteHeaderLink,
        params: { shop_id, _id },
      })
    );
  }


  sortHeaderLinks({ shop_id, data }) {
    return fetch(`${MAIN_URL}/shop/constructor/header-links/sort-items`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id,
        data,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.sortHeaderLinks,
        params: { shop_id, data },
      })
    );
  }


  getHeaderLink({ shop_id, _id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (_id) params.value = _id
    params.key = '_id'

    return fetch(
      `${MAIN_URL}/shop/constructor/header-links/get-exact?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getHeaderLink,
        params: { shop_id, _id },
      })
    );
  }

  editHeaderLink({ shop_id, url, is_visible, name, _id }) {
    return fetch(`${MAIN_URL}/shop/constructor/header-links/edit`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id,
        _id,
        url,
        is_visible,
        name,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editHeaderLink,
        params: { shop_id, url, is_visible, name, _id },
      })
    );
  }

  getAllFooterLinks({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;

    return fetch(
      `${MAIN_URL}/shop/constructor/footer-links/get-all?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getAllFooterLinks,
        params: { shop_id },
      })
    );
  }

  createFooterLink({ shop_id, url, is_visible, name }) {
    return fetch(`${MAIN_URL}/shop/constructor/footer-links/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id,
        url,
        is_visible,
        name,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.createFooterLink,
        params: { shop_id, url, is_visible, name },
      })
    );
  }



  deleteFooterLink({ shop_id, _id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (_id) params._id = _id

    return fetch(
      `${MAIN_URL}/shop/constructor/footer-links/delete?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.deleteFooterLink,
        params: { shop_id, _id },
      })
    );
  }


  sortFooterLinks({ shop_id, data }) {
    return fetch(`${MAIN_URL}/shop/constructor/footer-links/sort-items`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id,
        data,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.sortFooterLinks,
        params: { shop_id, data },
      })
    );
  }


  getFooterLink({ shop_id, _id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (_id) params.value = _id
    params.key = '_id'

    return fetch(
      `${MAIN_URL}/shop/constructor/footer-links/get-exact?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getFooterLink,
        params: { shop_id, _id },
      })
    );
  }

  editFooterLink({ shop_id, url, is_visible, _id, name }) {
    return fetch(`${MAIN_URL}/shop/constructor/footer-links/edit`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id,
        _id,
        name,
        url,
        is_visible,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editFooterLink,
        params: { shop_id, url, is_visible, _id, name },
      })
    );
  }

  getAllDeliveries({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;

    return fetch(
      `${MAIN_URL}/shop/delivery/get-all?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getAllDeliveries,
        params: { shop_id },
      })
    );
  }

  getDelivery({ shop_id, delivery_type }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (delivery_type) params.delivery_type = delivery_type;

    return fetch(
      `${MAIN_URL}/shop/delivery/get-data?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getDelivery,
        params: { shop_id, delivery_type },
      })
    );
  }

  toggleDelivery({ shop_id, delivery_type }) {
    return fetch(`${MAIN_URL}/shop/delivery/toggle`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id,
        delivery_type,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.toggleDelivery,
        params: { shop_id, delivery_type },
      })
    );
  }

  editDelivery({ shop_id, delivery_type, store_address, entrance, entry_code, floor, apartment, comment, is_active, delivery_zones}) {
    return fetch(`${MAIN_URL}/shop/delivery/set-data`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id,
        delivery_type,
        data: {
          store_address: store_address,
          additional_address_data: entrance || entry_code || floor || apartment || comment  ? {
            entrance: StringOrNull(entrance),
            entry_code: StringOrNull(entry_code),
            floor: StringOrNull(floor),
            apartment: StringOrNull(apartment),
            comment: StringOrNull(comment),
          } : null,
          delivery_zones: delivery_type === OWN_DELIVERY_TYPE ? delivery_zones : null,
        },
        is_active: is_active,
      
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editDelivery,
        params: { shop_id, delivery_type, store_address, entrance, entry_code, floor, apartment, comment, is_active, delivery_zones },
      })
    );
  }

  getDeliveryZonesStatic() {
    return fetch(
      `${MAIN_URL}/shop/delivery/static/get-delivery-zones-time`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getDeliveryZonesStatic,
        params: { },
      })
    );
  }

  async shopImportProducts({ data, shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;

    return fetch(
      `${MAIN_URL}/shop/products/import?` +
      new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "POST",
        credentials: "include",
        body: data,
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.shopImportProducts,
        params: { data },
      })
    );
  }

  async uploadFile({ data }) {
    return fetch(
      `${MAIN_URL}/files/upload?`,
      {
        method: "POST",
        credentials: "include",
        body: data,
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.uploadFile,
        params: { data },
      })
    );
  }
}

const mainApi = new MainApi({
  baseUrl: MAIN_URL,
});

export default mainApi;
