
import { useContext, useEffect, useState } from 'react';
import validator from 'validator'
import PlatformInput from '../../../PlatformInput/PlatformInput';
import ActionBtn from '../../../ActionBtn/ActionBtn';
import './NotificationsEmail.css';
import { DEBOUNCE_TIME } from '../../../../../assets/utils/constants';
import { DeleteCrossIcon } from '../../../../../assets/icons/controls/controls';
import { UserContext } from '../../../../../assets/contexts/userContext';
import mainApi from '../../../../../assets/api/MainApi';
import PreloaderBox from '../../../../PreloaderBox/PreloaderBox';
import { ActionsContext } from '../../../../../assets/contexts/actionsContext';

const DEFAULT_EMAIL_OBJECT = {
    email: '',
    validity: {},
}

function NotificationsEmail() {
    const { setActions } = useContext(ActionsContext)
    useEffect(() => {
        setActions([])
    }, [])


    const [form, setForm] = useState({
        emails: [
            DEFAULT_EMAIL_OBJECT,
        ]
    })

    const [initialEmails, setInitialEmails] = useState([DEFAULT_EMAIL_OBJECT]);


    const { user } = useContext(UserContext)

    const [data, setData] = useState(null)
    const [preloaders, setPreloaders] = useState({
        main: true,
        save: false,
    })
    useEffect(() => {
        if (!user) return
        setPreloaders(prevValue => ({
            ...prevValue,
            main: true,
        }))
        mainApi.getEmailNotificationsIntegration({
            shop_id: user.default_shop._id,
        })
            .then((res) => {
                console.log(res)
                const emails = res.data.map((item) => {
                    return {
                        email: item,
                        validity: {},
                    }
                })
                setForm(prevValue => ({
                    ...prevValue,
                    emails: emails,
                }))
                setInitialEmails(emails)
                setData({
                    ...res,
                    // is_connected: true,
                })
            })
            .catch((err) => {
                console.log(err)
                if (err.statusCode === 403) {
                    setData(null)
                    setInitialEmails([DEFAULT_EMAIL_OBJECT])
                    setForm({
                        emails: [
                            DEFAULT_EMAIL_OBJECT,
                        ]
                    })
                }
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    main: false,
                }))
            })
    }, [user])

    function handleAddEmail() {
        setForm(prevValue => ({
            ...prevValue,
            emails: prevValue.emails.concat([DEFAULT_EMAIL_OBJECT])
        }))
    }



    function handleChange(e) {
        const input = e.target;
        let value = input.value;
        const name = input.name
        const index = input.index
        console.log(value, index, name)
        switch (name) {
            case "email": {
                setForm((prevValue) => ({
                    ...prevValue,
                    emails: prevValue.emails.map((item, i) => {
                        if (i !== index) return item
                        else {
                            return {
                                ...item,
                                [name]: value,
                                validity: {
                                    errorMessage: "",
                                    validState: false,
                                },
                            }
                        }
                    })
                }));
                break;
            }
            case "email-delete": {
                setForm(prevValue => ({
                    ...prevValue,
                    emails: prevValue.emails.filter((_, emailIndex) => emailIndex !== index)
                }));
                break;
            }
            default:
                // setVelues(prevValue => ({
                //     ...prevValue,
                //     [name]: value,
                // }))

                break;
        }
    }


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {

            setForm((prevValue) => ({
                ...prevValue,
                emails: prevValue.emails.map((item, i) => {
                    if (!item.email || item.email.length === 0) return item
                    if (!item.email) {
                        return {
                            ...item,
                            validity: {
                                errorMessage: "",
                                validState: false,
                            },
                        }
                    }
                    if (item.email.length >= 5) {
                        if (validator.isEmail(item.email)) {
                            return {
                                ...item,
                                validity: {
                                    errorMessage: "",
                                    validState: true,
                                },
                            }
                            // ADD LOGIC TO CHECK UNIQUNESS OF EMAIL IN CURRENT SHOP/SYSTEM
                        } else {
                            return {
                                ...item,
                                validity: {
                                    errorMessage: "Несуществующая эл. почта",
                                    validState: false,
                                },
                            }
                        }
                    } else return item
                })
            }));


        }, DEBOUNCE_TIME)

        return () => {
            clearTimeout(delayDebounceFn);
        }
    }, [form.emails])

    const isFormValid = form.emails.every(email => email.validity.validState)



    const hasFormChanged = () => {
        if (form.emails.length !== initialEmails.length) {
            return true;
        }

        return form.emails.some((email, index) => email.email !== initialEmails[index].email);
    };

    function handleSave() {
        setPreloaders(prevValue => ({
            ...prevValue,
            save: true,
        }))
        mainApi.setEmailNotificationsIntegration({
            shop_id: user.default_shop._id,
            emails: form.emails.length > 0 ? form.emails.map((item) => item.email) : [],
        })
            .then((res) => {
                console.log(res)
                const emails = res.data.map((item) => {
                    return {
                        email: item,
                        validity: {},
                    }
                })
                setForm(prevValue => ({
                    ...prevValue,
                    emails: emails,
                }))
                setInitialEmails(emails)
                setData({
                    ...res,
                    // is_connected: true,
                })
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    save: false,
                }))
            })
    }
    return (


        <>
            {!preloaders.main ?
                <div className='notifications-email'>
                    <p className='notifications-email__title'>Список адресов для доставки уведомлений</p>
                    {form.emails.map((item, i) => (
                        <div className='notifications-email__input-box'>
                            <PlatformInput
                                value={item.email}
                                handleChange={(e) => {
                                    handleChange({
                                        target: {
                                            value: e.target.value,
                                            index: i,
                                            name: 'email',
                                        }
                                    })
                                }}
                                label={'Электронная почта'}
                                type={'text'}
                                inputMode='email'
                                name={'email'}
                                key={`notifications-email__email-input${i}`}
                                error={item.validity.errorMessage ? item.validity.errorMessage : ''}
                            />
                            {/* {form.emails.length > 1 ? */}
                            <button className='notifications-email__delete-btn' type='button' onClick={() => {
                                handleChange({
                                    target: {
                                        value: null,
                                        index: i,
                                        name: 'email-delete',
                                    }
                                })
                            }}>
                                <DeleteCrossIcon
                                    mainClassName={'notifications-email__delete-btn-icon'}
                                    fillClassName={'notifications-email__delete-btn-icon-fill'}
                                />
                            </button>
                            {/* : null} */}
                        </div>

                    ))}

                    {form.emails.length < 10 ?
                        <ActionBtn
                            text={'Добавить адрес'}
                            handleAction={handleAddEmail}
                        />
                        : null
                    }

                    {hasFormChanged() ?
                        <ActionBtn
                            text={'Сохранить'}
                            handleAction={handleSave}
                            isInactive={!isFormValid}
                            isContrast={true}
                            isLoading={preloaders.save}
                        />
                        : null}


                    <p className='notifications-email__hint'>Добавьте электронные почтовые адреса ваших менеджеров для того, чтобы они оперативно получали уведомления о&nbsp;новых заказах и&nbsp;важных событиях на&nbsp;вашем сайте. Это позволит вашей команде быстро реагировать на&nbsp;запросы клиентов и&nbsp;повышать эффективность работы.</p>

                </div>
                :
                <PreloaderBox />
            }
        </>


    );
}

export default NotificationsEmail