export const DEFAULT_MAP_STYLE = [
    {
        "tags": "country",
        "elements": "geometry.fill",
        "stylers": [
            {
                "color": "#8595ad"
            },
            {
                "opacity": 0.8,
                "zoom": 0
            },
            {
                "opacity": 0.8,
                "zoom": 1
            },
            {
                "opacity": 0.8,
                "zoom": 2
            },
            {
                "opacity": 0.8,
                "zoom": 3
            },
            {
                "opacity": 0.8,
                "zoom": 4
            },
            {
                "opacity": 1,
                "zoom": 5
            },
            {
                "opacity": 1,
                "zoom": 6
            },
            {
                "opacity": 1,
                "zoom": 7
            },
            {
                "opacity": 1,
                "zoom": 8
            },
            {
                "opacity": 1,
                "zoom": 9
            },
            {
                "opacity": 1,
                "zoom": 10
            },
            {
                "opacity": 1,
                "zoom": 11
            },
            {
                "opacity": 1,
                "zoom": 12
            },
            {
                "opacity": 1,
                "zoom": 13
            },
            {
                "opacity": 1,
                "zoom": 14
            },
            {
                "opacity": 1,
                "zoom": 15
            },
            {
                "opacity": 1,
                "zoom": 16
            },
            {
                "opacity": 1,
                "zoom": 17
            },
            {
                "opacity": 1,
                "zoom": 18
            },
            {
                "opacity": 1,
                "zoom": 19
            },
            {
                "opacity": 1,
                "zoom": 20
            },
            {
                "opacity": 1,
                "zoom": 21
            }
        ]
    },
    {
        "tags": "country",
        "elements": "geometry.outline",
        "stylers": [
            {
                "color": "#9ec3fa"
            },
            {
                "opacity": 0.15,
                "zoom": 0
            },
            {
                "opacity": 0.15,
                "zoom": 1
            },
            {
                "opacity": 0.15,
                "zoom": 2
            },
            {
                "opacity": 0.15,
                "zoom": 3
            },
            {
                "opacity": 0.15,
                "zoom": 4
            },
            {
                "opacity": 0.15,
                "zoom": 5
            },
            {
                "opacity": 0.25,
                "zoom": 6
            },
            {
                "opacity": 0.5,
                "zoom": 7
            },
            {
                "opacity": 0.47,
                "zoom": 8
            },
            {
                "opacity": 0.44,
                "zoom": 9
            },
            {
                "opacity": 0.41,
                "zoom": 10
            },
            {
                "opacity": 0.38,
                "zoom": 11
            },
            {
                "opacity": 0.35,
                "zoom": 12
            },
            {
                "opacity": 0.33,
                "zoom": 13
            },
            {
                "opacity": 0.3,
                "zoom": 14
            },
            {
                "opacity": 0.28,
                "zoom": 15
            },
            {
                "opacity": 0.25,
                "zoom": 16
            },
            {
                "opacity": 0.25,
                "zoom": 17
            },
            {
                "opacity": 0.25,
                "zoom": 18
            },
            {
                "opacity": 0.25,
                "zoom": 19
            },
            {
                "opacity": 0.25,
                "zoom": 20
            },
            {
                "opacity": 0.25,
                "zoom": 21
            }
        ]
    },
    {
        "tags": "region",
        "elements": "geometry.fill",
        "stylers": [
            {
                "color": "#a3afc2",
                "opacity": 0.5,
                "zoom": 0
            },
            {
                "color": "#a3afc2",
                "opacity": 0.5,
                "zoom": 1
            },
            {
                "color": "#a3afc2",
                "opacity": 0.5,
                "zoom": 2
            },
            {
                "color": "#a3afc2",
                "opacity": 0.5,
                "zoom": 3
            },
            {
                "color": "#a3afc2",
                "opacity": 0.5,
                "zoom": 4
            },
            {
                "color": "#a3afc2",
                "opacity": 0.5,
                "zoom": 5
            },
            {
                "color": "#a3afc2",
                "opacity": 1,
                "zoom": 6
            },
            {
                "color": "#a3afc2",
                "opacity": 1,
                "zoom": 7
            },
            {
                "color": "#8595ad",
                "opacity": 1,
                "zoom": 8
            },
            {
                "color": "#8595ad",
                "opacity": 1,
                "zoom": 9
            },
            {
                "color": "#8595ad",
                "opacity": 1,
                "zoom": 10
            },
            {
                "color": "#8595ad",
                "opacity": 1,
                "zoom": 11
            },
            {
                "color": "#8595ad",
                "opacity": 1,
                "zoom": 12
            },
            {
                "color": "#8595ad",
                "opacity": 1,
                "zoom": 13
            },
            {
                "color": "#8595ad",
                "opacity": 1,
                "zoom": 14
            },
            {
                "color": "#8595ad",
                "opacity": 1,
                "zoom": 15
            },
            {
                "color": "#8595ad",
                "opacity": 1,
                "zoom": 16
            },
            {
                "color": "#8595ad",
                "opacity": 1,
                "zoom": 17
            },
            {
                "color": "#8595ad",
                "opacity": 1,
                "zoom": 18
            },
            {
                "color": "#8595ad",
                "opacity": 1,
                "zoom": 19
            },
            {
                "color": "#8595ad",
                "opacity": 1,
                "zoom": 20
            },
            {
                "color": "#8595ad",
                "opacity": 1,
                "zoom": 21
            }
        ]
    },
    {
        "tags": "region",
        "elements": "geometry.outline",
        "stylers": [
            {
                "color": "#9ec3fa"
            },
            {
                "opacity": 0.15,
                "zoom": 0
            },
            {
                "opacity": 0.15,
                "zoom": 1
            },
            {
                "opacity": 0.15,
                "zoom": 2
            },
            {
                "opacity": 0.15,
                "zoom": 3
            },
            {
                "opacity": 0.15,
                "zoom": 4
            },
            {
                "opacity": 0.15,
                "zoom": 5
            },
            {
                "opacity": 0.25,
                "zoom": 6
            },
            {
                "opacity": 0.5,
                "zoom": 7
            },
            {
                "opacity": 0.47,
                "zoom": 8
            },
            {
                "opacity": 0.44,
                "zoom": 9
            },
            {
                "opacity": 0.41,
                "zoom": 10
            },
            {
                "opacity": 0.38,
                "zoom": 11
            },
            {
                "opacity": 0.35,
                "zoom": 12
            },
            {
                "opacity": 0.33,
                "zoom": 13
            },
            {
                "opacity": 0.3,
                "zoom": 14
            },
            {
                "opacity": 0.28,
                "zoom": 15
            },
            {
                "opacity": 0.25,
                "zoom": 16
            },
            {
                "opacity": 0.25,
                "zoom": 17
            },
            {
                "opacity": 0.25,
                "zoom": 18
            },
            {
                "opacity": 0.25,
                "zoom": 19
            },
            {
                "opacity": 0.25,
                "zoom": 20
            },
            {
                "opacity": 0.25,
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "any": "admin",
            "none": [
                "country",
                "region",
                "locality",
                "district",
                "address"
            ]
        },
        "elements": "geometry.fill",
        "stylers": [
            {
                "color": "#8595ad"
            },
            {
                "opacity": 0.5,
                "zoom": 0
            },
            {
                "opacity": 0.5,
                "zoom": 1
            },
            {
                "opacity": 0.5,
                "zoom": 2
            },
            {
                "opacity": 0.5,
                "zoom": 3
            },
            {
                "opacity": 0.5,
                "zoom": 4
            },
            {
                "opacity": 0.5,
                "zoom": 5
            },
            {
                "opacity": 1,
                "zoom": 6
            },
            {
                "opacity": 1,
                "zoom": 7
            },
            {
                "opacity": 1,
                "zoom": 8
            },
            {
                "opacity": 1,
                "zoom": 9
            },
            {
                "opacity": 1,
                "zoom": 10
            },
            {
                "opacity": 1,
                "zoom": 11
            },
            {
                "opacity": 1,
                "zoom": 12
            },
            {
                "opacity": 1,
                "zoom": 13
            },
            {
                "opacity": 1,
                "zoom": 14
            },
            {
                "opacity": 1,
                "zoom": 15
            },
            {
                "opacity": 1,
                "zoom": 16
            },
            {
                "opacity": 1,
                "zoom": 17
            },
            {
                "opacity": 1,
                "zoom": 18
            },
            {
                "opacity": 1,
                "zoom": 19
            },
            {
                "opacity": 1,
                "zoom": 20
            },
            {
                "opacity": 1,
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "any": "admin",
            "none": [
                "country",
                "region",
                "locality",
                "district",
                "address"
            ]
        },
        "elements": "geometry.outline",
        "stylers": [
            {
                "color": "#9ec3fa"
            },
            {
                "opacity": 0.15,
                "zoom": 0
            },
            {
                "opacity": 0.15,
                "zoom": 1
            },
            {
                "opacity": 0.15,
                "zoom": 2
            },
            {
                "opacity": 0.15,
                "zoom": 3
            },
            {
                "opacity": 0.15,
                "zoom": 4
            },
            {
                "opacity": 0.15,
                "zoom": 5
            },
            {
                "opacity": 0.25,
                "zoom": 6
            },
            {
                "opacity": 0.5,
                "zoom": 7
            },
            {
                "opacity": 0.47,
                "zoom": 8
            },
            {
                "opacity": 0.44,
                "zoom": 9
            },
            {
                "opacity": 0.41,
                "zoom": 10
            },
            {
                "opacity": 0.38,
                "zoom": 11
            },
            {
                "opacity": 0.35,
                "zoom": 12
            },
            {
                "opacity": 0.33,
                "zoom": 13
            },
            {
                "opacity": 0.3,
                "zoom": 14
            },
            {
                "opacity": 0.28,
                "zoom": 15
            },
            {
                "opacity": 0.25,
                "zoom": 16
            },
            {
                "opacity": 0.25,
                "zoom": 17
            },
            {
                "opacity": 0.25,
                "zoom": 18
            },
            {
                "opacity": 0.25,
                "zoom": 19
            },
            {
                "opacity": 0.25,
                "zoom": 20
            },
            {
                "opacity": 0.25,
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "any": "landcover",
            "none": "vegetation"
        },
        "stylers": [
            {
                "hue": "#c0d8fc"
            }
        ]
    },
    {
        "tags": "vegetation",
        "elements": "geometry",
        "stylers": [
            {
                "color": "#8fbafa",
                "opacity": 0.1,
                "zoom": 0
            },
            {
                "color": "#8fbafa",
                "opacity": 0.1,
                "zoom": 1
            },
            {
                "color": "#8fbafa",
                "opacity": 0.1,
                "zoom": 2
            },
            {
                "color": "#8fbafa",
                "opacity": 0.1,
                "zoom": 3
            },
            {
                "color": "#8fbafa",
                "opacity": 0.1,
                "zoom": 4
            },
            {
                "color": "#8fbafa",
                "opacity": 0.1,
                "zoom": 5
            },
            {
                "color": "#8fbafa",
                "opacity": 0.2,
                "zoom": 6
            },
            {
                "color": "#c0d8fc",
                "opacity": 0.3,
                "zoom": 7
            },
            {
                "color": "#c0d8fc",
                "opacity": 0.4,
                "zoom": 8
            },
            {
                "color": "#c0d8fc",
                "opacity": 0.6,
                "zoom": 9
            },
            {
                "color": "#c0d8fc",
                "opacity": 0.8,
                "zoom": 10
            },
            {
                "color": "#c0d8fc",
                "opacity": 1,
                "zoom": 11
            },
            {
                "color": "#c0d8fc",
                "opacity": 1,
                "zoom": 12
            },
            {
                "color": "#c0d8fc",
                "opacity": 1,
                "zoom": 13
            },
            {
                "color": "#c9defc",
                "opacity": 1,
                "zoom": 14
            },
            {
                "color": "#d3e4fd",
                "opacity": 1,
                "zoom": 15
            },
            {
                "color": "#d3e4fd",
                "opacity": 1,
                "zoom": 16
            },
            {
                "color": "#d3e4fd",
                "opacity": 1,
                "zoom": 17
            },
            {
                "color": "#d3e4fd",
                "opacity": 1,
                "zoom": 18
            },
            {
                "color": "#d3e4fd",
                "opacity": 1,
                "zoom": 19
            },
            {
                "color": "#d3e4fd",
                "opacity": 1,
                "zoom": 20
            },
            {
                "color": "#d3e4fd",
                "opacity": 1,
                "zoom": 21
            }
        ]
    },
    {
        "tags": "park",
        "elements": "geometry",
        "stylers": [
            {
                "color": "#c0d8fc",
                "opacity": 0.1,
                "zoom": 0
            },
            {
                "color": "#c0d8fc",
                "opacity": 0.1,
                "zoom": 1
            },
            {
                "color": "#c0d8fc",
                "opacity": 0.1,
                "zoom": 2
            },
            {
                "color": "#c0d8fc",
                "opacity": 0.1,
                "zoom": 3
            },
            {
                "color": "#c0d8fc",
                "opacity": 0.1,
                "zoom": 4
            },
            {
                "color": "#c0d8fc",
                "opacity": 0.1,
                "zoom": 5
            },
            {
                "color": "#c0d8fc",
                "opacity": 0.2,
                "zoom": 6
            },
            {
                "color": "#c0d8fc",
                "opacity": 0.3,
                "zoom": 7
            },
            {
                "color": "#c0d8fc",
                "opacity": 0.4,
                "zoom": 8
            },
            {
                "color": "#c0d8fc",
                "opacity": 0.6,
                "zoom": 9
            },
            {
                "color": "#c0d8fc",
                "opacity": 0.8,
                "zoom": 10
            },
            {
                "color": "#c0d8fc",
                "opacity": 1,
                "zoom": 11
            },
            {
                "color": "#c0d8fc",
                "opacity": 1,
                "zoom": 12
            },
            {
                "color": "#c0d8fc",
                "opacity": 1,
                "zoom": 13
            },
            {
                "color": "#c9defc",
                "opacity": 1,
                "zoom": 14
            },
            {
                "color": "#d3e4fd",
                "opacity": 1,
                "zoom": 15
            },
            {
                "color": "#d3e4fd",
                "opacity": 0.9,
                "zoom": 16
            },
            {
                "color": "#d3e4fd",
                "opacity": 0.8,
                "zoom": 17
            },
            {
                "color": "#d3e4fd",
                "opacity": 0.7,
                "zoom": 18
            },
            {
                "color": "#d3e4fd",
                "opacity": 0.7,
                "zoom": 19
            },
            {
                "color": "#d3e4fd",
                "opacity": 0.7,
                "zoom": 20
            },
            {
                "color": "#d3e4fd",
                "opacity": 0.7,
                "zoom": 21
            }
        ]
    },
    {
        "tags": "national_park",
        "elements": "geometry",
        "stylers": [
            {
                "color": "#c0d8fc",
                "opacity": 0.1,
                "zoom": 0
            },
            {
                "color": "#c0d8fc",
                "opacity": 0.1,
                "zoom": 1
            },
            {
                "color": "#c0d8fc",
                "opacity": 0.1,
                "zoom": 2
            },
            {
                "color": "#c0d8fc",
                "opacity": 0.1,
                "zoom": 3
            },
            {
                "color": "#c0d8fc",
                "opacity": 0.1,
                "zoom": 4
            },
            {
                "color": "#c0d8fc",
                "opacity": 0.1,
                "zoom": 5
            },
            {
                "color": "#c0d8fc",
                "opacity": 0.2,
                "zoom": 6
            },
            {
                "color": "#c0d8fc",
                "opacity": 0.3,
                "zoom": 7
            },
            {
                "color": "#c0d8fc",
                "opacity": 0.4,
                "zoom": 8
            },
            {
                "color": "#c0d8fc",
                "opacity": 0.6,
                "zoom": 9
            },
            {
                "color": "#c0d8fc",
                "opacity": 0.8,
                "zoom": 10
            },
            {
                "color": "#c0d8fc",
                "opacity": 1,
                "zoom": 11
            },
            {
                "color": "#c0d8fc",
                "opacity": 1,
                "zoom": 12
            },
            {
                "color": "#c0d8fc",
                "opacity": 1,
                "zoom": 13
            },
            {
                "color": "#c9defc",
                "opacity": 1,
                "zoom": 14
            },
            {
                "color": "#d3e4fd",
                "opacity": 1,
                "zoom": 15
            },
            {
                "color": "#d3e4fd",
                "opacity": 0.7,
                "zoom": 16
            },
            {
                "color": "#d3e4fd",
                "opacity": 0.7,
                "zoom": 17
            },
            {
                "color": "#d3e4fd",
                "opacity": 0.7,
                "zoom": 18
            },
            {
                "color": "#d3e4fd",
                "opacity": 0.7,
                "zoom": 19
            },
            {
                "color": "#d3e4fd",
                "opacity": 0.7,
                "zoom": 20
            },
            {
                "color": "#d3e4fd",
                "opacity": 0.7,
                "zoom": 21
            }
        ]
    },
    {
        "tags": "cemetery",
        "elements": "geometry",
        "stylers": [
            {
                "color": "#c0d8fc",
                "zoom": 0
            },
            {
                "color": "#c0d8fc",
                "zoom": 1
            },
            {
                "color": "#c0d8fc",
                "zoom": 2
            },
            {
                "color": "#c0d8fc",
                "zoom": 3
            },
            {
                "color": "#c0d8fc",
                "zoom": 4
            },
            {
                "color": "#c0d8fc",
                "zoom": 5
            },
            {
                "color": "#c0d8fc",
                "zoom": 6
            },
            {
                "color": "#c0d8fc",
                "zoom": 7
            },
            {
                "color": "#c0d8fc",
                "zoom": 8
            },
            {
                "color": "#c0d8fc",
                "zoom": 9
            },
            {
                "color": "#c0d8fc",
                "zoom": 10
            },
            {
                "color": "#c0d8fc",
                "zoom": 11
            },
            {
                "color": "#c0d8fc",
                "zoom": 12
            },
            {
                "color": "#c0d8fc",
                "zoom": 13
            },
            {
                "color": "#c9defc",
                "zoom": 14
            },
            {
                "color": "#d3e4fd",
                "zoom": 15
            },
            {
                "color": "#d3e4fd",
                "zoom": 16
            },
            {
                "color": "#d3e4fd",
                "zoom": 17
            },
            {
                "color": "#d3e4fd",
                "zoom": 18
            },
            {
                "color": "#d3e4fd",
                "zoom": 19
            },
            {
                "color": "#d3e4fd",
                "zoom": 20
            },
            {
                "color": "#d3e4fd",
                "zoom": 21
            }
        ]
    },
    {
        "tags": "sports_ground",
        "elements": "geometry",
        "stylers": [
            {
                "color": "#a7c9fb",
                "opacity": 0,
                "zoom": 0
            },
            {
                "color": "#a7c9fb",
                "opacity": 0,
                "zoom": 1
            },
            {
                "color": "#a7c9fb",
                "opacity": 0,
                "zoom": 2
            },
            {
                "color": "#a7c9fb",
                "opacity": 0,
                "zoom": 3
            },
            {
                "color": "#a7c9fb",
                "opacity": 0,
                "zoom": 4
            },
            {
                "color": "#a7c9fb",
                "opacity": 0,
                "zoom": 5
            },
            {
                "color": "#a7c9fb",
                "opacity": 0,
                "zoom": 6
            },
            {
                "color": "#a7c9fb",
                "opacity": 0,
                "zoom": 7
            },
            {
                "color": "#a7c9fb",
                "opacity": 0,
                "zoom": 8
            },
            {
                "color": "#a7c9fb",
                "opacity": 0,
                "zoom": 9
            },
            {
                "color": "#a7c9fb",
                "opacity": 0,
                "zoom": 10
            },
            {
                "color": "#a7c9fb",
                "opacity": 0,
                "zoom": 11
            },
            {
                "color": "#a7c9fb",
                "opacity": 0,
                "zoom": 12
            },
            {
                "color": "#a7c9fb",
                "opacity": 0,
                "zoom": 13
            },
            {
                "color": "#b1cffb",
                "opacity": 0,
                "zoom": 14
            },
            {
                "color": "#bbd5fc",
                "opacity": 0.5,
                "zoom": 15
            },
            {
                "color": "#bdd6fc",
                "opacity": 1,
                "zoom": 16
            },
            {
                "color": "#bed7fc",
                "opacity": 1,
                "zoom": 17
            },
            {
                "color": "#c0d8fc",
                "opacity": 1,
                "zoom": 18
            },
            {
                "color": "#c2d9fc",
                "opacity": 1,
                "zoom": 19
            },
            {
                "color": "#c3dafc",
                "opacity": 1,
                "zoom": 20
            },
            {
                "color": "#c5dbfc",
                "opacity": 1,
                "zoom": 21
            }
        ]
    },
    {
        "tags": "terrain",
        "elements": "geometry",
        "stylers": [
            {
                "hue": "#d8e7fd"
            },
            {
                "opacity": 0.3,
                "zoom": 0
            },
            {
                "opacity": 0.3,
                "zoom": 1
            },
            {
                "opacity": 0.3,
                "zoom": 2
            },
            {
                "opacity": 0.3,
                "zoom": 3
            },
            {
                "opacity": 0.3,
                "zoom": 4
            },
            {
                "opacity": 0.35,
                "zoom": 5
            },
            {
                "opacity": 0.4,
                "zoom": 6
            },
            {
                "opacity": 0.6,
                "zoom": 7
            },
            {
                "opacity": 0.8,
                "zoom": 8
            },
            {
                "opacity": 0.9,
                "zoom": 9
            },
            {
                "opacity": 1,
                "zoom": 10
            },
            {
                "opacity": 1,
                "zoom": 11
            },
            {
                "opacity": 1,
                "zoom": 12
            },
            {
                "opacity": 1,
                "zoom": 13
            },
            {
                "opacity": 1,
                "zoom": 14
            },
            {
                "opacity": 1,
                "zoom": 15
            },
            {
                "opacity": 1,
                "zoom": 16
            },
            {
                "opacity": 1,
                "zoom": 17
            },
            {
                "opacity": 1,
                "zoom": 18
            },
            {
                "opacity": 1,
                "zoom": 19
            },
            {
                "opacity": 1,
                "zoom": 20
            },
            {
                "opacity": 1,
                "zoom": 21
            }
        ]
    },
    {
        "tags": "geographic_line",
        "elements": "geometry",
        "stylers": [
            {
                "color": "#166ff3"
            }
        ]
    },
    {
        "tags": "land",
        "elements": "geometry",
        "stylers": [
            {
                "color": "#d8e6fd",
                "zoom": 0
            },
            {
                "color": "#d8e6fd",
                "zoom": 1
            },
            {
                "color": "#d8e6fd",
                "zoom": 2
            },
            {
                "color": "#d8e6fd",
                "zoom": 3
            },
            {
                "color": "#d8e6fd",
                "zoom": 4
            },
            {
                "color": "#dde9fd",
                "zoom": 5
            },
            {
                "color": "#e2ecfd",
                "zoom": 6
            },
            {
                "color": "#e7f0fe",
                "zoom": 7
            },
            {
                "color": "#ecf3fe",
                "zoom": 8
            },
            {
                "color": "#ecf3fe",
                "zoom": 9
            },
            {
                "color": "#ecf3fe",
                "zoom": 10
            },
            {
                "color": "#ecf3fe",
                "zoom": 11
            },
            {
                "color": "#ecf3fe",
                "zoom": 12
            },
            {
                "color": "#ecf3fe",
                "zoom": 13
            },
            {
                "color": "#f0f6fe",
                "zoom": 14
            },
            {
                "color": "#f5f9fe",
                "zoom": 15
            },
            {
                "color": "#f6f9fe",
                "zoom": 16
            },
            {
                "color": "#f7fafe",
                "zoom": 17
            },
            {
                "color": "#f7fafe",
                "zoom": 18
            },
            {
                "color": "#f8fbff",
                "zoom": 19
            },
            {
                "color": "#f9fbff",
                "zoom": 20
            },
            {
                "color": "#fafcff",
                "zoom": 21
            }
        ]
    },
    {
        "tags": "residential",
        "elements": "geometry",
        "stylers": [
            {
                "color": "#d8e7fd",
                "opacity": 0.5,
                "zoom": 0
            },
            {
                "color": "#d8e7fd",
                "opacity": 0.5,
                "zoom": 1
            },
            {
                "color": "#d8e7fd",
                "opacity": 0.5,
                "zoom": 2
            },
            {
                "color": "#d8e7fd",
                "opacity": 0.5,
                "zoom": 3
            },
            {
                "color": "#d8e7fd",
                "opacity": 0.5,
                "zoom": 4
            },
            {
                "color": "#d8e7fd",
                "opacity": 0.5,
                "zoom": 5
            },
            {
                "color": "#d8e7fd",
                "opacity": 0.5,
                "zoom": 6
            },
            {
                "color": "#d8e7fd",
                "opacity": 0.5,
                "zoom": 7
            },
            {
                "color": "#d8e7fd",
                "opacity": 0.5,
                "zoom": 8
            },
            {
                "color": "#d8e7fd",
                "opacity": 0.5,
                "zoom": 9
            },
            {
                "color": "#d8e7fd",
                "opacity": 0.5,
                "zoom": 10
            },
            {
                "color": "#d8e7fd",
                "opacity": 0.5,
                "zoom": 11
            },
            {
                "color": "#d8e7fd",
                "opacity": 0.5,
                "zoom": 12
            },
            {
                "color": "#d8e7fd",
                "opacity": 1,
                "zoom": 13
            },
            {
                "color": "#e2edfd",
                "opacity": 1,
                "zoom": 14
            },
            {
                "color": "#ecf3fe",
                "opacity": 1,
                "zoom": 15
            },
            {
                "color": "#edf4fe",
                "opacity": 1,
                "zoom": 16
            },
            {
                "color": "#eff5fe",
                "opacity": 1,
                "zoom": 17
            },
            {
                "color": "#f0f6fe",
                "opacity": 1,
                "zoom": 18
            },
            {
                "color": "#f2f7fe",
                "opacity": 1,
                "zoom": 19
            },
            {
                "color": "#f3f8fe",
                "opacity": 1,
                "zoom": 20
            },
            {
                "color": "#f5f9fe",
                "opacity": 1,
                "zoom": 21
            }
        ]
    },
    {
        "tags": "locality",
        "elements": "geometry",
        "stylers": [
            {
                "color": "#d8e7fd",
                "zoom": 0
            },
            {
                "color": "#d8e7fd",
                "zoom": 1
            },
            {
                "color": "#d8e7fd",
                "zoom": 2
            },
            {
                "color": "#d8e7fd",
                "zoom": 3
            },
            {
                "color": "#d8e7fd",
                "zoom": 4
            },
            {
                "color": "#d8e7fd",
                "zoom": 5
            },
            {
                "color": "#d8e7fd",
                "zoom": 6
            },
            {
                "color": "#d8e7fd",
                "zoom": 7
            },
            {
                "color": "#d8e7fd",
                "zoom": 8
            },
            {
                "color": "#d8e7fd",
                "zoom": 9
            },
            {
                "color": "#d8e7fd",
                "zoom": 10
            },
            {
                "color": "#d8e7fd",
                "zoom": 11
            },
            {
                "color": "#d8e7fd",
                "zoom": 12
            },
            {
                "color": "#d8e7fd",
                "zoom": 13
            },
            {
                "color": "#e2edfd",
                "zoom": 14
            },
            {
                "color": "#ecf3fe",
                "zoom": 15
            },
            {
                "color": "#edf4fe",
                "zoom": 16
            },
            {
                "color": "#eff5fe",
                "zoom": 17
            },
            {
                "color": "#f0f6fe",
                "zoom": 18
            },
            {
                "color": "#f2f7fe",
                "zoom": 19
            },
            {
                "color": "#f3f8fe",
                "zoom": 20
            },
            {
                "color": "#f5f9fe",
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "any": "structure",
            "none": [
                "building",
                "fence"
            ]
        },
        "elements": "geometry",
        "stylers": [
            {
                "opacity": 0.9
            },
            {
                "color": "#d8e7fd",
                "zoom": 0
            },
            {
                "color": "#d8e7fd",
                "zoom": 1
            },
            {
                "color": "#d8e7fd",
                "zoom": 2
            },
            {
                "color": "#d8e7fd",
                "zoom": 3
            },
            {
                "color": "#d8e7fd",
                "zoom": 4
            },
            {
                "color": "#d8e7fd",
                "zoom": 5
            },
            {
                "color": "#d8e7fd",
                "zoom": 6
            },
            {
                "color": "#d8e7fd",
                "zoom": 7
            },
            {
                "color": "#d8e7fd",
                "zoom": 8
            },
            {
                "color": "#d8e7fd",
                "zoom": 9
            },
            {
                "color": "#d8e7fd",
                "zoom": 10
            },
            {
                "color": "#d8e7fd",
                "zoom": 11
            },
            {
                "color": "#d8e7fd",
                "zoom": 12
            },
            {
                "color": "#d8e7fd",
                "zoom": 13
            },
            {
                "color": "#e2edfd",
                "zoom": 14
            },
            {
                "color": "#ecf3fe",
                "zoom": 15
            },
            {
                "color": "#edf4fe",
                "zoom": 16
            },
            {
                "color": "#eff5fe",
                "zoom": 17
            },
            {
                "color": "#f0f6fe",
                "zoom": 18
            },
            {
                "color": "#f2f7fe",
                "zoom": 19
            },
            {
                "color": "#f3f8fe",
                "zoom": 20
            },
            {
                "color": "#f5f9fe",
                "zoom": 21
            }
        ]
    },
    {
        "tags": "building",
        "elements": "geometry.fill",
        "stylers": [
            {
                "color": "#c5dbfc"
            },
            {
                "opacity": 0.7,
                "zoom": 0
            },
            {
                "opacity": 0.7,
                "zoom": 1
            },
            {
                "opacity": 0.7,
                "zoom": 2
            },
            {
                "opacity": 0.7,
                "zoom": 3
            },
            {
                "opacity": 0.7,
                "zoom": 4
            },
            {
                "opacity": 0.7,
                "zoom": 5
            },
            {
                "opacity": 0.7,
                "zoom": 6
            },
            {
                "opacity": 0.7,
                "zoom": 7
            },
            {
                "opacity": 0.7,
                "zoom": 8
            },
            {
                "opacity": 0.7,
                "zoom": 9
            },
            {
                "opacity": 0.7,
                "zoom": 10
            },
            {
                "opacity": 0.7,
                "zoom": 11
            },
            {
                "opacity": 0.7,
                "zoom": 12
            },
            {
                "opacity": 0.7,
                "zoom": 13
            },
            {
                "opacity": 0.7,
                "zoom": 14
            },
            {
                "opacity": 0.7,
                "zoom": 15
            },
            {
                "opacity": 0.9,
                "zoom": 16
            },
            {
                "opacity": 0.6,
                "zoom": 17
            },
            {
                "opacity": 0.6,
                "zoom": 18
            },
            {
                "opacity": 0.6,
                "zoom": 19
            },
            {
                "opacity": 0.6,
                "zoom": 20
            },
            {
                "opacity": 0.6,
                "zoom": 21
            }
        ]
    },
    {
        "tags": "building",
        "elements": "geometry.outline",
        "stylers": [
            {
                "color": "#9ec3fa"
            },
            {
                "opacity": 0.5,
                "zoom": 0
            },
            {
                "opacity": 0.5,
                "zoom": 1
            },
            {
                "opacity": 0.5,
                "zoom": 2
            },
            {
                "opacity": 0.5,
                "zoom": 3
            },
            {
                "opacity": 0.5,
                "zoom": 4
            },
            {
                "opacity": 0.5,
                "zoom": 5
            },
            {
                "opacity": 0.5,
                "zoom": 6
            },
            {
                "opacity": 0.5,
                "zoom": 7
            },
            {
                "opacity": 0.5,
                "zoom": 8
            },
            {
                "opacity": 0.5,
                "zoom": 9
            },
            {
                "opacity": 0.5,
                "zoom": 10
            },
            {
                "opacity": 0.5,
                "zoom": 11
            },
            {
                "opacity": 0.5,
                "zoom": 12
            },
            {
                "opacity": 0.5,
                "zoom": 13
            },
            {
                "opacity": 0.5,
                "zoom": 14
            },
            {
                "opacity": 0.5,
                "zoom": 15
            },
            {
                "opacity": 0.5,
                "zoom": 16
            },
            {
                "opacity": 1,
                "zoom": 17
            },
            {
                "opacity": 1,
                "zoom": 18
            },
            {
                "opacity": 1,
                "zoom": 19
            },
            {
                "opacity": 1,
                "zoom": 20
            },
            {
                "opacity": 1,
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "any": "urban_area",
            "none": [
                "residential",
                "industrial",
                "cemetery",
                "park",
                "medical",
                "sports_ground",
                "beach",
                "construction_site"
            ]
        },
        "elements": "geometry",
        "stylers": [
            {
                "color": "#c5dbfc",
                "opacity": 1,
                "zoom": 0
            },
            {
                "color": "#c5dbfc",
                "opacity": 1,
                "zoom": 1
            },
            {
                "color": "#c5dbfc",
                "opacity": 1,
                "zoom": 2
            },
            {
                "color": "#c5dbfc",
                "opacity": 1,
                "zoom": 3
            },
            {
                "color": "#c5dbfc",
                "opacity": 1,
                "zoom": 4
            },
            {
                "color": "#c5dbfc",
                "opacity": 1,
                "zoom": 5
            },
            {
                "color": "#c5dbfc",
                "opacity": 1,
                "zoom": 6
            },
            {
                "color": "#c5dbfc",
                "opacity": 1,
                "zoom": 7
            },
            {
                "color": "#c5dbfc",
                "opacity": 1,
                "zoom": 8
            },
            {
                "color": "#c5dbfc",
                "opacity": 1,
                "zoom": 9
            },
            {
                "color": "#c5dbfc",
                "opacity": 1,
                "zoom": 10
            },
            {
                "color": "#c5dbfc",
                "opacity": 1,
                "zoom": 11
            },
            {
                "color": "#c5dbfc",
                "opacity": 1,
                "zoom": 12
            },
            {
                "color": "#c5dbfc",
                "opacity": 1,
                "zoom": 13
            },
            {
                "color": "#d1e2fc",
                "opacity": 1,
                "zoom": 14
            },
            {
                "color": "#ddeafd",
                "opacity": 1,
                "zoom": 15
            },
            {
                "color": "#e9f1fd",
                "opacity": 0.67,
                "zoom": 16
            },
            {
                "color": "#f5f9fe",
                "opacity": 0.33,
                "zoom": 17
            },
            {
                "color": "#f5f9fe",
                "opacity": 0,
                "zoom": 18
            },
            {
                "color": "#f5f9fe",
                "opacity": 0,
                "zoom": 19
            },
            {
                "color": "#f5f9fe",
                "opacity": 0,
                "zoom": 20
            },
            {
                "color": "#f5f9fe",
                "opacity": 0,
                "zoom": 21
            }
        ]
    },
    {
        "tags": "poi",
        "elements": "label.icon",
        "stylers": [
            {
                "color": "#0d69f2"
            },
            {
                "secondary-color": "#ffffff"
            },
            {
                "tertiary-color": "#ffffff"
            }
        ]
    },
    {
        "tags": "poi",
        "elements": "label.text.fill",
        "stylers": [
            {
                "color": "#083f91"
            }
        ]
    },
    {
        "tags": "poi",
        "elements": "label.text.outline",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "opacity": 0.5
            }
        ]
    },
    {
        "tags": "outdoor",
        "elements": "label.icon",
        "stylers": [
            {
                "color": "#0d69f2"
            },
            {
                "secondary-color": "#ffffff"
            },
            {
                "tertiary-color": "#ffffff"
            }
        ]
    },
    {
        "tags": "outdoor",
        "elements": "label.text.fill",
        "stylers": [
            {
                "color": "#083f91"
            }
        ]
    },
    {
        "tags": "outdoor",
        "elements": "label.text.outline",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "opacity": 0.5
            }
        ]
    },
    {
        "tags": "park",
        "elements": "label.icon",
        "stylers": [
            {
                "color": "#0d69f2"
            },
            {
                "secondary-color": "#ffffff"
            },
            {
                "tertiary-color": "#ffffff"
            }
        ]
    },
    {
        "tags": "park",
        "elements": "label.text.fill",
        "stylers": [
            {
                "color": "#083f91"
            }
        ]
    },
    {
        "tags": "park",
        "elements": "label.text.outline",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "opacity": 0.5
            }
        ]
    },
    {
        "tags": "cemetery",
        "elements": "label.icon",
        "stylers": [
            {
                "color": "#0d69f2"
            },
            {
                "secondary-color": "#ffffff"
            },
            {
                "tertiary-color": "#ffffff"
            }
        ]
    },
    {
        "tags": "cemetery",
        "elements": "label.text.fill",
        "stylers": [
            {
                "color": "#083f91"
            }
        ]
    },
    {
        "tags": "cemetery",
        "elements": "label.text.outline",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "opacity": 0.5
            }
        ]
    },
    {
        "tags": "beach",
        "elements": "label.icon",
        "stylers": [
            {
                "color": "#0d69f2"
            },
            {
                "secondary-color": "#ffffff"
            },
            {
                "tertiary-color": "#ffffff"
            }
        ]
    },
    {
        "tags": "beach",
        "elements": "label.text.fill",
        "stylers": [
            {
                "color": "#083f91"
            }
        ]
    },
    {
        "tags": "beach",
        "elements": "label.text.outline",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "opacity": 0.5
            }
        ]
    },
    {
        "tags": "medical",
        "elements": "label.icon",
        "stylers": [
            {
                "color": "#0d69f2"
            },
            {
                "secondary-color": "#ffffff"
            },
            {
                "tertiary-color": "#ffffff"
            }
        ]
    },
    {
        "tags": "medical",
        "elements": "label.text.fill",
        "stylers": [
            {
                "color": "#083f91"
            }
        ]
    },
    {
        "tags": "medical",
        "elements": "label.text.outline",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "opacity": 0.5
            }
        ]
    },
    {
        "tags": "shopping",
        "elements": "label.icon",
        "stylers": [
            {
                "color": "#0d69f2"
            },
            {
                "secondary-color": "#ffffff"
            },
            {
                "tertiary-color": "#ffffff"
            }
        ]
    },
    {
        "tags": "shopping",
        "elements": "label.text.fill",
        "stylers": [
            {
                "color": "#083f91"
            }
        ]
    },
    {
        "tags": "shopping",
        "elements": "label.text.outline",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "opacity": 0.5
            }
        ]
    },
    {
        "tags": "commercial_services",
        "elements": "label.icon",
        "stylers": [
            {
                "color": "#0d69f2"
            },
            {
                "secondary-color": "#ffffff"
            },
            {
                "tertiary-color": "#ffffff"
            }
        ]
    },
    {
        "tags": "commercial_services",
        "elements": "label.text.fill",
        "stylers": [
            {
                "color": "#083f91"
            }
        ]
    },
    {
        "tags": "commercial_services",
        "elements": "label.text.outline",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "opacity": 0.5
            }
        ]
    },
    {
        "tags": "food_and_drink",
        "elements": "label.icon",
        "stylers": [
            {
                "color": "#0d69f2"
            },
            {
                "secondary-color": "#ffffff"
            },
            {
                "tertiary-color": "#ffffff"
            }
        ]
    },
    {
        "tags": "food_and_drink",
        "elements": "label.text.fill",
        "stylers": [
            {
                "color": "#083f91"
            }
        ]
    },
    {
        "tags": "food_and_drink",
        "elements": "label.text.outline",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "opacity": 0.5
            }
        ]
    },
    {
        "tags": "road",
        "elements": "label.icon",
        "types": "point",
        "stylers": [
            {
                "color": "#0d69f2"
            },
            {
                "secondary-color": "#ffffff"
            },
            {
                "tertiary-color": "#ffffff"
            }
        ]
    },
    {
        "tags": "road",
        "elements": "label.text.fill",
        "types": "point",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "tags": "entrance",
        "elements": "label.icon",
        "stylers": [
            {
                "color": "#0d69f2"
            },
            {
                "secondary-color": "#ffffff"
            },
            {
                "hue": "#0d69f2"
            }
        ]
    },
    {
        "tags": "locality",
        "elements": "label.icon",
        "stylers": [
            {
                "color": "#0d69f2"
            },
            {
                "secondary-color": "#ffffff"
            }
        ]
    },
    {
        "tags": "country",
        "elements": "label.text.fill",
        "stylers": [
            {
                "opacity": 0.8
            },
            {
                "color": "#0b5eda"
            }
        ]
    },
    {
        "tags": "country",
        "elements": "label.text.outline",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "opacity": 0.5
            }
        ]
    },
    {
        "tags": "region",
        "elements": "label.text.fill",
        "stylers": [
            {
                "color": "#0b5eda"
            },
            {
                "opacity": 0.8
            }
        ]
    },
    {
        "tags": "region",
        "elements": "label.text.outline",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "opacity": 0.5
            }
        ]
    },
    {
        "tags": "district",
        "elements": "label.text.fill",
        "stylers": [
            {
                "color": "#0b5eda"
            },
            {
                "opacity": 0.8
            }
        ]
    },
    {
        "tags": "district",
        "elements": "label.text.outline",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "opacity": 0.5
            }
        ]
    },
    {
        "tags": {
            "any": "admin",
            "none": [
                "country",
                "region",
                "locality",
                "district",
                "address"
            ]
        },
        "elements": "label.text.fill",
        "stylers": [
            {
                "color": "#0b5eda"
            }
        ]
    },
    {
        "tags": {
            "any": "admin",
            "none": [
                "country",
                "region",
                "locality",
                "district",
                "address"
            ]
        },
        "elements": "label.text.outline",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "opacity": 0.5
            }
        ]
    },
    {
        "tags": "locality",
        "elements": "label.text.fill",
        "stylers": [
            {
                "color": "#083f91",
                "zoom": 0
            },
            {
                "color": "#083f91",
                "zoom": 1
            },
            {
                "color": "#083f91",
                "zoom": 2
            },
            {
                "color": "#083f91",
                "zoom": 3
            },
            {
                "color": "#083f91",
                "zoom": 4
            },
            {
                "color": "#083d8d",
                "zoom": 5
            },
            {
                "color": "#073b89",
                "zoom": 6
            },
            {
                "color": "#073a85",
                "zoom": 7
            },
            {
                "color": "#073881",
                "zoom": 8
            },
            {
                "color": "#06367d",
                "zoom": 9
            },
            {
                "color": "#063479",
                "zoom": 10
            },
            {
                "color": "#063479",
                "zoom": 11
            },
            {
                "color": "#063479",
                "zoom": 12
            },
            {
                "color": "#063479",
                "zoom": 13
            },
            {
                "color": "#063479",
                "zoom": 14
            },
            {
                "color": "#063479",
                "zoom": 15
            },
            {
                "color": "#063479",
                "zoom": 16
            },
            {
                "color": "#063479",
                "zoom": 17
            },
            {
                "color": "#063479",
                "zoom": 18
            },
            {
                "color": "#063479",
                "zoom": 19
            },
            {
                "color": "#063479",
                "zoom": 20
            },
            {
                "color": "#063479",
                "zoom": 21
            }
        ]
    },
    {
        "tags": "locality",
        "elements": "label.text.outline",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "opacity": 0.5
            }
        ]
    },
    {
        "tags": "road",
        "elements": "label.text.fill",
        "types": "polyline",
        "stylers": [
            {
                "color": "#0949aa"
            }
        ]
    },
    {
        "tags": "road",
        "elements": "label.text.outline",
        "types": "polyline",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "opacity": 0.5
            }
        ]
    },
    {
        "tags": "road",
        "elements": "geometry.fill.pattern",
        "types": "polyline",
        "stylers": [
            {
                "scale": 1
            },
            {
                "color": "#2578f4"
            }
        ]
    },
    {
        "tags": "road",
        "elements": "label.text.fill",
        "types": "point",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "tags": "structure",
        "elements": "label.text.fill",
        "stylers": [
            {
                "color": "#0a54c2"
            },
            {
                "opacity": 0.5
            }
        ]
    },
    {
        "tags": "structure",
        "elements": "label.text.outline",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "opacity": 0.5
            }
        ]
    },
    {
        "tags": "address",
        "elements": "label.text.fill",
        "stylers": [
            {
                "color": "#0a54c2"
            },
            {
                "opacity": 0.9,
                "zoom": 0
            },
            {
                "opacity": 0.9,
                "zoom": 1
            },
            {
                "opacity": 0.9,
                "zoom": 2
            },
            {
                "opacity": 0.9,
                "zoom": 3
            },
            {
                "opacity": 0.9,
                "zoom": 4
            },
            {
                "opacity": 0.9,
                "zoom": 5
            },
            {
                "opacity": 0.9,
                "zoom": 6
            },
            {
                "opacity": 0.9,
                "zoom": 7
            },
            {
                "opacity": 0.9,
                "zoom": 8
            },
            {
                "opacity": 0.9,
                "zoom": 9
            },
            {
                "opacity": 0.9,
                "zoom": 10
            },
            {
                "opacity": 0.9,
                "zoom": 11
            },
            {
                "opacity": 0.9,
                "zoom": 12
            },
            {
                "opacity": 0.9,
                "zoom": 13
            },
            {
                "opacity": 0.9,
                "zoom": 14
            },
            {
                "opacity": 0.9,
                "zoom": 15
            },
            {
                "opacity": 0.9,
                "zoom": 16
            },
            {
                "opacity": 1,
                "zoom": 17
            },
            {
                "opacity": 1,
                "zoom": 18
            },
            {
                "opacity": 1,
                "zoom": 19
            },
            {
                "opacity": 1,
                "zoom": 20
            },
            {
                "opacity": 1,
                "zoom": 21
            }
        ]
    },
    {
        "tags": "address",
        "elements": "label.text.outline",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "opacity": 0.5
            }
        ]
    },
    {
        "tags": "landscape",
        "elements": "label.text.fill",
        "stylers": [
            {
                "color": "#0b5eda",
                "opacity": 1,
                "zoom": 0
            },
            {
                "color": "#0b5eda",
                "opacity": 1,
                "zoom": 1
            },
            {
                "color": "#0b5eda",
                "opacity": 1,
                "zoom": 2
            },
            {
                "color": "#0b5eda",
                "opacity": 1,
                "zoom": 3
            },
            {
                "color": "#0a54c2",
                "opacity": 0.5,
                "zoom": 4
            },
            {
                "color": "#0a54c2",
                "opacity": 0.5,
                "zoom": 5
            },
            {
                "color": "#0a54c2",
                "opacity": 0.5,
                "zoom": 6
            },
            {
                "color": "#0a54c2",
                "opacity": 0.5,
                "zoom": 7
            },
            {
                "color": "#0a54c2",
                "opacity": 0.5,
                "zoom": 8
            },
            {
                "color": "#0a54c2",
                "opacity": 0.5,
                "zoom": 9
            },
            {
                "color": "#0a54c2",
                "opacity": 0.5,
                "zoom": 10
            },
            {
                "color": "#0a54c2",
                "opacity": 0.5,
                "zoom": 11
            },
            {
                "color": "#0a54c2",
                "opacity": 0.5,
                "zoom": 12
            },
            {
                "color": "#0a54c2",
                "opacity": 0.5,
                "zoom": 13
            },
            {
                "color": "#0a54c2",
                "opacity": 0.5,
                "zoom": 14
            },
            {
                "color": "#0a54c2",
                "opacity": 0.5,
                "zoom": 15
            },
            {
                "color": "#0a54c2",
                "opacity": 0.5,
                "zoom": 16
            },
            {
                "color": "#0a54c2",
                "opacity": 0.5,
                "zoom": 17
            },
            {
                "color": "#0a54c2",
                "opacity": 0.5,
                "zoom": 18
            },
            {
                "color": "#0a54c2",
                "opacity": 0.5,
                "zoom": 19
            },
            {
                "color": "#0a54c2",
                "opacity": 0.5,
                "zoom": 20
            },
            {
                "color": "#0a54c2",
                "opacity": 0.5,
                "zoom": 21
            }
        ]
    },
    {
        "tags": "landscape",
        "elements": "label.text.outline",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "opacity": 0.5,
                "zoom": 0
            },
            {
                "opacity": 0.5,
                "zoom": 1
            },
            {
                "opacity": 0.5,
                "zoom": 2
            },
            {
                "opacity": 0.5,
                "zoom": 3
            },
            {
                "opacity": 0,
                "zoom": 4
            },
            {
                "opacity": 0,
                "zoom": 5
            },
            {
                "opacity": 0,
                "zoom": 6
            },
            {
                "opacity": 0,
                "zoom": 7
            },
            {
                "opacity": 0,
                "zoom": 8
            },
            {
                "opacity": 0,
                "zoom": 9
            },
            {
                "opacity": 0,
                "zoom": 10
            },
            {
                "opacity": 0,
                "zoom": 11
            },
            {
                "opacity": 0,
                "zoom": 12
            },
            {
                "opacity": 0,
                "zoom": 13
            },
            {
                "opacity": 0,
                "zoom": 14
            },
            {
                "opacity": 0,
                "zoom": 15
            },
            {
                "opacity": 0,
                "zoom": 16
            },
            {
                "opacity": 0,
                "zoom": 17
            },
            {
                "opacity": 0,
                "zoom": 18
            },
            {
                "opacity": 0,
                "zoom": 19
            },
            {
                "opacity": 0,
                "zoom": 20
            },
            {
                "opacity": 0,
                "zoom": 21
            }
        ]
    },
    {
        "tags": "water",
        "elements": "label.text.fill",
        "stylers": [
            {
                "color": "#126cf3"
            },
            {
                "opacity": 0.8
            }
        ]
    },
    {
        "tags": "water",
        "elements": "label.text.outline",
        "types": "polyline",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "opacity": 0.2
            }
        ]
    },
    {
        "tags": {
            "any": "road_1",
            "none": "is_tunnel"
        },
        "elements": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "scale": 0,
                "zoom": 0
            },
            {
                "scale": 0,
                "zoom": 1
            },
            {
                "scale": 0,
                "zoom": 2
            },
            {
                "scale": 0,
                "zoom": 3
            },
            {
                "scale": 0,
                "zoom": 4
            },
            {
                "scale": 0,
                "zoom": 5
            },
            {
                "scale": 2.97,
                "zoom": 6
            },
            {
                "scale": 3.19,
                "zoom": 7
            },
            {
                "scale": 3.53,
                "zoom": 8
            },
            {
                "scale": 4,
                "zoom": 9
            },
            {
                "scale": 3.61,
                "zoom": 10
            },
            {
                "scale": 3.06,
                "zoom": 11
            },
            {
                "scale": 2.64,
                "zoom": 12
            },
            {
                "scale": 2.27,
                "zoom": 13
            },
            {
                "scale": 2.03,
                "zoom": 14
            },
            {
                "scale": 1.9,
                "zoom": 15
            },
            {
                "scale": 1.86,
                "zoom": 16
            },
            {
                "scale": 1.48,
                "zoom": 17
            },
            {
                "scale": 1.21,
                "zoom": 18
            },
            {
                "scale": 1.04,
                "zoom": 19
            },
            {
                "scale": 0.94,
                "zoom": 20
            },
            {
                "scale": 0.9,
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "any": "road_1"
        },
        "elements": "geometry.outline",
        "stylers": [
            {
                "color": "#00000000",
                "scale": 1.4,
                "zoom": 0
            },
            {
                "color": "#00000000",
                "scale": 1.4,
                "zoom": 1
            },
            {
                "color": "#00000000",
                "scale": 1.4,
                "zoom": 2
            },
            {
                "color": "#00000000",
                "scale": 1.4,
                "zoom": 3
            },
            {
                "color": "#00000000",
                "scale": 1.4,
                "zoom": 4
            },
            {
                "color": "#00000000",
                "scale": 1.4,
                "zoom": 5
            },
            {
                "color": "#00000000",
                "scale": 3.05,
                "zoom": 6
            },
            {
                "color": "#00000000",
                "scale": 3.05,
                "zoom": 7
            },
            {
                "color": "#cfe1fc",
                "scale": 3.15,
                "zoom": 8
            },
            {
                "color": "#d8e7fd",
                "scale": 3.37,
                "zoom": 9
            },
            {
                "color": "#d8e7fd",
                "scale": 3.36,
                "zoom": 10
            },
            {
                "color": "#d8e7fd",
                "scale": 3.17,
                "zoom": 11
            },
            {
                "color": "#d8e7fd",
                "scale": 3,
                "zoom": 12
            },
            {
                "color": "#d8e7fd",
                "scale": 2.8,
                "zoom": 13
            },
            {
                "color": "#e2edfd",
                "scale": 2.66,
                "zoom": 14
            },
            {
                "color": "#e2edfd",
                "scale": 2.61,
                "zoom": 15
            },
            {
                "color": "#e5effd",
                "scale": 2.64,
                "zoom": 16
            },
            {
                "color": "#e9f1fd",
                "scale": 2.14,
                "zoom": 17
            },
            {
                "color": "#ecf4fe",
                "scale": 1.79,
                "zoom": 18
            },
            {
                "color": "#f0f6fe",
                "scale": 1.55,
                "zoom": 19
            },
            {
                "color": "#f2f7fe",
                "scale": 1.41,
                "zoom": 20
            },
            {
                "color": "#f5f9fe",
                "scale": 1.35,
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "any": "road_2",
            "none": "is_tunnel"
        },
        "elements": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "scale": 0,
                "zoom": 0
            },
            {
                "scale": 0,
                "zoom": 1
            },
            {
                "scale": 0,
                "zoom": 2
            },
            {
                "scale": 0,
                "zoom": 3
            },
            {
                "scale": 0,
                "zoom": 4
            },
            {
                "scale": 0,
                "zoom": 5
            },
            {
                "scale": 2.97,
                "zoom": 6
            },
            {
                "scale": 3.19,
                "zoom": 7
            },
            {
                "scale": 3.53,
                "zoom": 8
            },
            {
                "scale": 4,
                "zoom": 9
            },
            {
                "scale": 3.61,
                "zoom": 10
            },
            {
                "scale": 3.06,
                "zoom": 11
            },
            {
                "scale": 2.64,
                "zoom": 12
            },
            {
                "scale": 2.27,
                "zoom": 13
            },
            {
                "scale": 2.03,
                "zoom": 14
            },
            {
                "scale": 1.9,
                "zoom": 15
            },
            {
                "scale": 1.86,
                "zoom": 16
            },
            {
                "scale": 1.48,
                "zoom": 17
            },
            {
                "scale": 1.21,
                "zoom": 18
            },
            {
                "scale": 1.04,
                "zoom": 19
            },
            {
                "scale": 0.94,
                "zoom": 20
            },
            {
                "scale": 0.9,
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "any": "road_2"
        },
        "elements": "geometry.outline",
        "stylers": [
            {
                "color": "#00000000",
                "scale": 1.4,
                "zoom": 0
            },
            {
                "color": "#00000000",
                "scale": 1.4,
                "zoom": 1
            },
            {
                "color": "#00000000",
                "scale": 1.4,
                "zoom": 2
            },
            {
                "color": "#00000000",
                "scale": 1.4,
                "zoom": 3
            },
            {
                "color": "#00000000",
                "scale": 1.4,
                "zoom": 4
            },
            {
                "color": "#00000000",
                "scale": 1.4,
                "zoom": 5
            },
            {
                "color": "#00000000",
                "scale": 3.05,
                "zoom": 6
            },
            {
                "color": "#00000000",
                "scale": 3.05,
                "zoom": 7
            },
            {
                "color": "#cfe1fc",
                "scale": 3.15,
                "zoom": 8
            },
            {
                "color": "#d8e7fd",
                "scale": 3.37,
                "zoom": 9
            },
            {
                "color": "#d8e7fd",
                "scale": 3.36,
                "zoom": 10
            },
            {
                "color": "#d8e7fd",
                "scale": 3.17,
                "zoom": 11
            },
            {
                "color": "#d8e7fd",
                "scale": 3,
                "zoom": 12
            },
            {
                "color": "#d8e7fd",
                "scale": 2.8,
                "zoom": 13
            },
            {
                "color": "#e2edfd",
                "scale": 2.66,
                "zoom": 14
            },
            {
                "color": "#e2edfd",
                "scale": 2.61,
                "zoom": 15
            },
            {
                "color": "#e5effd",
                "scale": 2.64,
                "zoom": 16
            },
            {
                "color": "#e9f1fd",
                "scale": 2.14,
                "zoom": 17
            },
            {
                "color": "#ecf4fe",
                "scale": 1.79,
                "zoom": 18
            },
            {
                "color": "#f0f6fe",
                "scale": 1.55,
                "zoom": 19
            },
            {
                "color": "#f2f7fe",
                "scale": 1.41,
                "zoom": 20
            },
            {
                "color": "#f5f9fe",
                "scale": 1.35,
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "any": "road_3",
            "none": "is_tunnel"
        },
        "elements": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "scale": 0,
                "zoom": 0
            },
            {
                "scale": 0,
                "zoom": 1
            },
            {
                "scale": 0,
                "zoom": 2
            },
            {
                "scale": 0,
                "zoom": 3
            },
            {
                "scale": 0,
                "zoom": 4
            },
            {
                "scale": 0,
                "zoom": 5
            },
            {
                "scale": 0,
                "zoom": 6
            },
            {
                "scale": 0,
                "zoom": 7
            },
            {
                "scale": 0,
                "zoom": 8
            },
            {
                "scale": 2.51,
                "zoom": 9
            },
            {
                "scale": 2.62,
                "zoom": 10
            },
            {
                "scale": 1.68,
                "zoom": 11
            },
            {
                "scale": 1.67,
                "zoom": 12
            },
            {
                "scale": 1.38,
                "zoom": 13
            },
            {
                "scale": 1.19,
                "zoom": 14
            },
            {
                "scale": 1.08,
                "zoom": 15
            },
            {
                "scale": 1.04,
                "zoom": 16
            },
            {
                "scale": 0.91,
                "zoom": 17
            },
            {
                "scale": 0.84,
                "zoom": 18
            },
            {
                "scale": 0.82,
                "zoom": 19
            },
            {
                "scale": 0.84,
                "zoom": 20
            },
            {
                "scale": 0.9,
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "any": "road_3"
        },
        "elements": "geometry.outline",
        "stylers": [
            {
                "color": "#ffffff",
                "scale": 1.6,
                "zoom": 0
            },
            {
                "color": "#ffffff",
                "scale": 1.6,
                "zoom": 1
            },
            {
                "color": "#ffffff",
                "scale": 1.6,
                "zoom": 2
            },
            {
                "color": "#ffffff",
                "scale": 1.6,
                "zoom": 3
            },
            {
                "color": "#ffffff",
                "scale": 1.6,
                "zoom": 4
            },
            {
                "color": "#ffffff",
                "scale": 1.6,
                "zoom": 5
            },
            {
                "color": "#ffffff",
                "scale": 1.6,
                "zoom": 6
            },
            {
                "color": "#ffffff",
                "scale": 1.6,
                "zoom": 7
            },
            {
                "color": "#ffffff",
                "scale": 1.29,
                "zoom": 8
            },
            {
                "color": "#d8e7fd",
                "scale": 4.21,
                "zoom": 9
            },
            {
                "color": "#d8e7fd",
                "scale": 2.74,
                "zoom": 10
            },
            {
                "color": "#d8e7fd",
                "scale": 2.04,
                "zoom": 11
            },
            {
                "color": "#d8e7fd",
                "scale": 2.13,
                "zoom": 12
            },
            {
                "color": "#d8e7fd",
                "scale": 1.88,
                "zoom": 13
            },
            {
                "color": "#e2edfd",
                "scale": 1.7,
                "zoom": 14
            },
            {
                "color": "#e2edfd",
                "scale": 1.59,
                "zoom": 15
            },
            {
                "color": "#e5effd",
                "scale": 1.55,
                "zoom": 16
            },
            {
                "color": "#e9f1fd",
                "scale": 1.37,
                "zoom": 17
            },
            {
                "color": "#ecf4fe",
                "scale": 1.27,
                "zoom": 18
            },
            {
                "color": "#f0f6fe",
                "scale": 1.23,
                "zoom": 19
            },
            {
                "color": "#f2f7fe",
                "scale": 1.26,
                "zoom": 20
            },
            {
                "color": "#f5f9fe",
                "scale": 1.35,
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "any": "road_4",
            "none": "is_tunnel"
        },
        "elements": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "scale": 0,
                "zoom": 0
            },
            {
                "scale": 0,
                "zoom": 1
            },
            {
                "scale": 0,
                "zoom": 2
            },
            {
                "scale": 0,
                "zoom": 3
            },
            {
                "scale": 0,
                "zoom": 4
            },
            {
                "scale": 0,
                "zoom": 5
            },
            {
                "scale": 0,
                "zoom": 6
            },
            {
                "scale": 0,
                "zoom": 7
            },
            {
                "scale": 0,
                "zoom": 8
            },
            {
                "scale": 0,
                "zoom": 9
            },
            {
                "scale": 1.69,
                "zoom": 10
            },
            {
                "scale": 1.26,
                "zoom": 11
            },
            {
                "scale": 1.41,
                "zoom": 12
            },
            {
                "scale": 1.19,
                "zoom": 13
            },
            {
                "scale": 1.04,
                "zoom": 14
            },
            {
                "scale": 0.97,
                "zoom": 15
            },
            {
                "scale": 1.15,
                "zoom": 16
            },
            {
                "scale": 0.99,
                "zoom": 17
            },
            {
                "scale": 0.89,
                "zoom": 18
            },
            {
                "scale": 0.85,
                "zoom": 19
            },
            {
                "scale": 0.85,
                "zoom": 20
            },
            {
                "scale": 0.9,
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "any": "road_4"
        },
        "elements": "geometry.outline",
        "stylers": [
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 0
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 1
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 2
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 3
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 4
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 5
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 6
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 7
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 8
            },
            {
                "color": "#ffffff",
                "scale": 1.12,
                "zoom": 9
            },
            {
                "color": "#d8e7fd",
                "scale": 1.9,
                "zoom": 10
            },
            {
                "color": "#d8e7fd",
                "scale": 1.62,
                "zoom": 11
            },
            {
                "color": "#d8e7fd",
                "scale": 1.83,
                "zoom": 12
            },
            {
                "color": "#d8e7fd",
                "scale": 1.64,
                "zoom": 13
            },
            {
                "color": "#e2edfd",
                "scale": 1.51,
                "zoom": 14
            },
            {
                "color": "#e2edfd",
                "scale": 1.44,
                "zoom": 15
            },
            {
                "color": "#e5effd",
                "scale": 1.69,
                "zoom": 16
            },
            {
                "color": "#e9f1fd",
                "scale": 1.47,
                "zoom": 17
            },
            {
                "color": "#ecf4fe",
                "scale": 1.34,
                "zoom": 18
            },
            {
                "color": "#f0f6fe",
                "scale": 1.28,
                "zoom": 19
            },
            {
                "color": "#f2f7fe",
                "scale": 1.28,
                "zoom": 20
            },
            {
                "color": "#f5f9fe",
                "scale": 1.34,
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "any": "road_5",
            "none": "is_tunnel"
        },
        "elements": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "scale": 0,
                "zoom": 0
            },
            {
                "scale": 0,
                "zoom": 1
            },
            {
                "scale": 0,
                "zoom": 2
            },
            {
                "scale": 0,
                "zoom": 3
            },
            {
                "scale": 0,
                "zoom": 4
            },
            {
                "scale": 0,
                "zoom": 5
            },
            {
                "scale": 0,
                "zoom": 6
            },
            {
                "scale": 0,
                "zoom": 7
            },
            {
                "scale": 0,
                "zoom": 8
            },
            {
                "scale": 0,
                "zoom": 9
            },
            {
                "scale": 0,
                "zoom": 10
            },
            {
                "scale": 0,
                "zoom": 11
            },
            {
                "scale": 1.25,
                "zoom": 12
            },
            {
                "scale": 0.95,
                "zoom": 13
            },
            {
                "scale": 0.81,
                "zoom": 14
            },
            {
                "scale": 0.95,
                "zoom": 15
            },
            {
                "scale": 1.1,
                "zoom": 16
            },
            {
                "scale": 0.93,
                "zoom": 17
            },
            {
                "scale": 0.85,
                "zoom": 18
            },
            {
                "scale": 0.82,
                "zoom": 19
            },
            {
                "scale": 0.84,
                "zoom": 20
            },
            {
                "scale": 0.9,
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "any": "road_5"
        },
        "elements": "geometry.outline",
        "stylers": [
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 0
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 1
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 2
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 3
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 4
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 5
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 6
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 7
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 8
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 9
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 10
            },
            {
                "color": "#ffffff",
                "scale": 0.62,
                "zoom": 11
            },
            {
                "color": "#d8e7fd",
                "scale": 1.61,
                "zoom": 12
            },
            {
                "color": "#d8e7fd",
                "scale": 1.36,
                "zoom": 13
            },
            {
                "color": "#e2edfd",
                "scale": 1.22,
                "zoom": 14
            },
            {
                "color": "#e2edfd",
                "scale": 1.41,
                "zoom": 15
            },
            {
                "color": "#e5effd",
                "scale": 1.63,
                "zoom": 16
            },
            {
                "color": "#e9f1fd",
                "scale": 1.4,
                "zoom": 17
            },
            {
                "color": "#ecf4fe",
                "scale": 1.27,
                "zoom": 18
            },
            {
                "color": "#f0f6fe",
                "scale": 1.23,
                "zoom": 19
            },
            {
                "color": "#f2f7fe",
                "scale": 1.25,
                "zoom": 20
            },
            {
                "color": "#f5f9fe",
                "scale": 1.34,
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "any": "road_6",
            "none": "is_tunnel"
        },
        "elements": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "scale": 0,
                "zoom": 0
            },
            {
                "scale": 0,
                "zoom": 1
            },
            {
                "scale": 0,
                "zoom": 2
            },
            {
                "scale": 0,
                "zoom": 3
            },
            {
                "scale": 0,
                "zoom": 4
            },
            {
                "scale": 0,
                "zoom": 5
            },
            {
                "scale": 0,
                "zoom": 6
            },
            {
                "scale": 0,
                "zoom": 7
            },
            {
                "scale": 0,
                "zoom": 8
            },
            {
                "scale": 0,
                "zoom": 9
            },
            {
                "scale": 0,
                "zoom": 10
            },
            {
                "scale": 0,
                "zoom": 11
            },
            {
                "scale": 0,
                "zoom": 12
            },
            {
                "scale": 2.25,
                "zoom": 13
            },
            {
                "scale": 1.27,
                "zoom": 14
            },
            {
                "scale": 1.25,
                "zoom": 15
            },
            {
                "scale": 1.31,
                "zoom": 16
            },
            {
                "scale": 1.04,
                "zoom": 17
            },
            {
                "scale": 0.9,
                "zoom": 18
            },
            {
                "scale": 0.85,
                "zoom": 19
            },
            {
                "scale": 0.85,
                "zoom": 20
            },
            {
                "scale": 0.9,
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "any": "road_6"
        },
        "elements": "geometry.outline",
        "stylers": [
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 0
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 1
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 2
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 3
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 4
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 5
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 6
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 7
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 8
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 9
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 10
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 11
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 12
            },
            {
                "color": "#d8e7fd",
                "scale": 2.31,
                "zoom": 13
            },
            {
                "color": "#e2edfd",
                "scale": 1.7,
                "zoom": 14
            },
            {
                "color": "#e2edfd",
                "scale": 1.76,
                "zoom": 15
            },
            {
                "color": "#e5effd",
                "scale": 1.89,
                "zoom": 16
            },
            {
                "color": "#e9f1fd",
                "scale": 1.55,
                "zoom": 17
            },
            {
                "color": "#ecf4fe",
                "scale": 1.36,
                "zoom": 18
            },
            {
                "color": "#f0f6fe",
                "scale": 1.27,
                "zoom": 19
            },
            {
                "color": "#f2f7fe",
                "scale": 1.27,
                "zoom": 20
            },
            {
                "color": "#f5f9fe",
                "scale": 1.34,
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "any": "road_7",
            "none": "is_tunnel"
        },
        "elements": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "scale": 0,
                "zoom": 0
            },
            {
                "scale": 0,
                "zoom": 1
            },
            {
                "scale": 0,
                "zoom": 2
            },
            {
                "scale": 0,
                "zoom": 3
            },
            {
                "scale": 0,
                "zoom": 4
            },
            {
                "scale": 0,
                "zoom": 5
            },
            {
                "scale": 0,
                "zoom": 6
            },
            {
                "scale": 0,
                "zoom": 7
            },
            {
                "scale": 0,
                "zoom": 8
            },
            {
                "scale": 0,
                "zoom": 9
            },
            {
                "scale": 0,
                "zoom": 10
            },
            {
                "scale": 0,
                "zoom": 11
            },
            {
                "scale": 0,
                "zoom": 12
            },
            {
                "scale": 0,
                "zoom": 13
            },
            {
                "scale": 0.9,
                "zoom": 14
            },
            {
                "scale": 0.78,
                "zoom": 15
            },
            {
                "scale": 0.88,
                "zoom": 16
            },
            {
                "scale": 0.8,
                "zoom": 17
            },
            {
                "scale": 0.78,
                "zoom": 18
            },
            {
                "scale": 0.79,
                "zoom": 19
            },
            {
                "scale": 0.83,
                "zoom": 20
            },
            {
                "scale": 0.9,
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "any": "road_7"
        },
        "elements": "geometry.outline",
        "stylers": [
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 0
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 1
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 2
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 3
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 4
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 5
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 6
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 7
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 8
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 9
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 10
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 11
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 12
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 13
            },
            {
                "color": "#e2edfd",
                "scale": 1.31,
                "zoom": 14
            },
            {
                "color": "#e2edfd",
                "scale": 1.19,
                "zoom": 15
            },
            {
                "color": "#e5effd",
                "scale": 1.31,
                "zoom": 16
            },
            {
                "color": "#e9f1fd",
                "scale": 1.21,
                "zoom": 17
            },
            {
                "color": "#ecf4fe",
                "scale": 1.17,
                "zoom": 18
            },
            {
                "color": "#f0f6fe",
                "scale": 1.18,
                "zoom": 19
            },
            {
                "color": "#f2f7fe",
                "scale": 1.23,
                "zoom": 20
            },
            {
                "color": "#f5f9fe",
                "scale": 1.33,
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "any": "road_minor",
            "none": "is_tunnel"
        },
        "elements": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "scale": 0,
                "zoom": 0
            },
            {
                "scale": 0,
                "zoom": 1
            },
            {
                "scale": 0,
                "zoom": 2
            },
            {
                "scale": 0,
                "zoom": 3
            },
            {
                "scale": 0,
                "zoom": 4
            },
            {
                "scale": 0,
                "zoom": 5
            },
            {
                "scale": 0,
                "zoom": 6
            },
            {
                "scale": 0,
                "zoom": 7
            },
            {
                "scale": 0,
                "zoom": 8
            },
            {
                "scale": 0,
                "zoom": 9
            },
            {
                "scale": 0,
                "zoom": 10
            },
            {
                "scale": 0,
                "zoom": 11
            },
            {
                "scale": 0,
                "zoom": 12
            },
            {
                "scale": 0,
                "zoom": 13
            },
            {
                "scale": 0,
                "zoom": 14
            },
            {
                "scale": 0,
                "zoom": 15
            },
            {
                "scale": 0.9,
                "zoom": 16
            },
            {
                "scale": 0.9,
                "zoom": 17
            },
            {
                "scale": 0.9,
                "zoom": 18
            },
            {
                "scale": 0.9,
                "zoom": 19
            },
            {
                "scale": 0.9,
                "zoom": 20
            },
            {
                "scale": 0.9,
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "any": "road_minor"
        },
        "elements": "geometry.outline",
        "stylers": [
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 0
            },
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 1
            },
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 2
            },
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 3
            },
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 4
            },
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 5
            },
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 6
            },
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 7
            },
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 8
            },
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 9
            },
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 10
            },
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 11
            },
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 12
            },
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 13
            },
            {
                "color": "#e2edfd",
                "scale": 0.4,
                "zoom": 14
            },
            {
                "color": "#e2edfd",
                "scale": 0.4,
                "zoom": 15
            },
            {
                "color": "#e5effd",
                "scale": 1.4,
                "zoom": 16
            },
            {
                "color": "#e9f1fd",
                "scale": 1.27,
                "zoom": 17
            },
            {
                "color": "#ecf4fe",
                "scale": 1.27,
                "zoom": 18
            },
            {
                "color": "#f0f6fe",
                "scale": 1.29,
                "zoom": 19
            },
            {
                "color": "#f2f7fe",
                "scale": 1.31,
                "zoom": 20
            },
            {
                "color": "#f5f9fe",
                "scale": 1.32,
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "any": "road_unclassified",
            "none": "is_tunnel"
        },
        "elements": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "scale": 0,
                "zoom": 0
            },
            {
                "scale": 0,
                "zoom": 1
            },
            {
                "scale": 0,
                "zoom": 2
            },
            {
                "scale": 0,
                "zoom": 3
            },
            {
                "scale": 0,
                "zoom": 4
            },
            {
                "scale": 0,
                "zoom": 5
            },
            {
                "scale": 0,
                "zoom": 6
            },
            {
                "scale": 0,
                "zoom": 7
            },
            {
                "scale": 0,
                "zoom": 8
            },
            {
                "scale": 0,
                "zoom": 9
            },
            {
                "scale": 0,
                "zoom": 10
            },
            {
                "scale": 0,
                "zoom": 11
            },
            {
                "scale": 0,
                "zoom": 12
            },
            {
                "scale": 0,
                "zoom": 13
            },
            {
                "scale": 0,
                "zoom": 14
            },
            {
                "scale": 0,
                "zoom": 15
            },
            {
                "scale": 0.9,
                "zoom": 16
            },
            {
                "scale": 0.9,
                "zoom": 17
            },
            {
                "scale": 0.9,
                "zoom": 18
            },
            {
                "scale": 0.9,
                "zoom": 19
            },
            {
                "scale": 0.9,
                "zoom": 20
            },
            {
                "scale": 0.9,
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "any": "road_unclassified"
        },
        "elements": "geometry.outline",
        "stylers": [
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 0
            },
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 1
            },
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 2
            },
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 3
            },
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 4
            },
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 5
            },
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 6
            },
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 7
            },
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 8
            },
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 9
            },
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 10
            },
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 11
            },
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 12
            },
            {
                "color": "#ffffff",
                "scale": 0.4,
                "zoom": 13
            },
            {
                "color": "#e2edfd",
                "scale": 0.4,
                "zoom": 14
            },
            {
                "color": "#e2edfd",
                "scale": 0.4,
                "zoom": 15
            },
            {
                "color": "#e5effd",
                "scale": 1.4,
                "zoom": 16
            },
            {
                "color": "#e9f1fd",
                "scale": 1.27,
                "zoom": 17
            },
            {
                "color": "#ecf4fe",
                "scale": 1.27,
                "zoom": 18
            },
            {
                "color": "#f0f6fe",
                "scale": 1.29,
                "zoom": 19
            },
            {
                "color": "#f2f7fe",
                "scale": 1.31,
                "zoom": 20
            },
            {
                "color": "#f5f9fe",
                "scale": 1.32,
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "all": "is_tunnel",
            "none": "path"
        },
        "elements": "geometry.fill",
        "stylers": [
            {
                "color": "#cfe1fc",
                "zoom": 0
            },
            {
                "color": "#cfe1fc",
                "zoom": 1
            },
            {
                "color": "#cfe1fc",
                "zoom": 2
            },
            {
                "color": "#cfe1fc",
                "zoom": 3
            },
            {
                "color": "#cfe1fc",
                "zoom": 4
            },
            {
                "color": "#cfe1fc",
                "zoom": 5
            },
            {
                "color": "#cfe1fc",
                "zoom": 6
            },
            {
                "color": "#cfe1fc",
                "zoom": 7
            },
            {
                "color": "#cfe1fc",
                "zoom": 8
            },
            {
                "color": "#cfe1fc",
                "zoom": 9
            },
            {
                "color": "#cfe1fc",
                "zoom": 10
            },
            {
                "color": "#cfe1fc",
                "zoom": 11
            },
            {
                "color": "#cfe1fc",
                "zoom": 12
            },
            {
                "color": "#cfe1fc",
                "zoom": 13
            },
            {
                "color": "#d8e7fc",
                "zoom": 14
            },
            {
                "color": "#e2edfd",
                "zoom": 15
            },
            {
                "color": "#e4eefd",
                "zoom": 16
            },
            {
                "color": "#e5effd",
                "zoom": 17
            },
            {
                "color": "#e7f0fd",
                "zoom": 18
            },
            {
                "color": "#e9f1fe",
                "zoom": 19
            },
            {
                "color": "#eaf2fe",
                "zoom": 20
            },
            {
                "color": "#ecf3fe",
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "all": "path",
            "none": "is_tunnel"
        },
        "elements": "geometry.fill",
        "stylers": [
            {
                "color": "#85b4f9"
            }
        ]
    },
    {
        "tags": {
            "all": "path",
            "none": "is_tunnel"
        },
        "elements": "geometry.outline",
        "stylers": [
            {
                "opacity": 0.7
            },
            {
                "color": "#d8e7fd",
                "zoom": 0
            },
            {
                "color": "#d8e7fd",
                "zoom": 1
            },
            {
                "color": "#d8e7fd",
                "zoom": 2
            },
            {
                "color": "#d8e7fd",
                "zoom": 3
            },
            {
                "color": "#d8e7fd",
                "zoom": 4
            },
            {
                "color": "#d8e7fd",
                "zoom": 5
            },
            {
                "color": "#d8e7fd",
                "zoom": 6
            },
            {
                "color": "#d8e7fd",
                "zoom": 7
            },
            {
                "color": "#d8e7fd",
                "zoom": 8
            },
            {
                "color": "#d8e7fd",
                "zoom": 9
            },
            {
                "color": "#d8e7fd",
                "zoom": 10
            },
            {
                "color": "#d8e7fd",
                "zoom": 11
            },
            {
                "color": "#d8e7fd",
                "zoom": 12
            },
            {
                "color": "#d8e7fd",
                "zoom": 13
            },
            {
                "color": "#e2edfd",
                "zoom": 14
            },
            {
                "color": "#ecf3fe",
                "zoom": 15
            },
            {
                "color": "#edf4fe",
                "zoom": 16
            },
            {
                "color": "#eff5fe",
                "zoom": 17
            },
            {
                "color": "#f0f6fe",
                "zoom": 18
            },
            {
                "color": "#f2f7fe",
                "zoom": 19
            },
            {
                "color": "#f3f8fe",
                "zoom": 20
            },
            {
                "color": "#f5f9fe",
                "zoom": 21
            }
        ]
    },
    {
        "tags": "road_construction",
        "elements": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "tags": "road_construction",
        "elements": "geometry.outline",
        "stylers": [
            {
                "color": "#b6d2fc",
                "zoom": 0
            },
            {
                "color": "#b6d2fc",
                "zoom": 1
            },
            {
                "color": "#b6d2fc",
                "zoom": 2
            },
            {
                "color": "#b6d2fc",
                "zoom": 3
            },
            {
                "color": "#b6d2fc",
                "zoom": 4
            },
            {
                "color": "#b6d2fc",
                "zoom": 5
            },
            {
                "color": "#b6d2fc",
                "zoom": 6
            },
            {
                "color": "#b6d2fc",
                "zoom": 7
            },
            {
                "color": "#b6d2fc",
                "zoom": 8
            },
            {
                "color": "#b6d2fc",
                "zoom": 9
            },
            {
                "color": "#b6d2fc",
                "zoom": 10
            },
            {
                "color": "#b6d2fc",
                "zoom": 11
            },
            {
                "color": "#b6d2fc",
                "zoom": 12
            },
            {
                "color": "#b6d2fc",
                "zoom": 13
            },
            {
                "color": "#85b4f9",
                "zoom": 14
            },
            {
                "color": "#b6d2fc",
                "zoom": 15
            },
            {
                "color": "#bed7fc",
                "zoom": 16
            },
            {
                "color": "#c6dcfd",
                "zoom": 17
            },
            {
                "color": "#cee1fd",
                "zoom": 18
            },
            {
                "color": "#d7e6fd",
                "zoom": 19
            },
            {
                "color": "#dfebfe",
                "zoom": 20
            },
            {
                "color": "#e7f0fe",
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "any": "ferry"
        },
        "stylers": [
            {
                "color": "#72a8f8"
            }
        ]
    },
    {
        "tags": "transit_location",
        "elements": "label.icon",
        "stylers": [
            {
                "hue": "#0d69f2"
            },
            {
                "saturation": -0.1
            }
        ]
    },
    {
        "tags": "transit_location",
        "elements": "label.text.fill",
        "stylers": [
            {
                "color": "#7a93b8"
            }
        ]
    },
    {
        "tags": "transit_location",
        "elements": "label.text.outline",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "tags": "transit_schema",
        "elements": "geometry.fill",
        "stylers": [
            {
                "color": "#7a93b8"
            },
            {
                "scale": 0.7
            },
            {
                "opacity": 0.6,
                "zoom": 0
            },
            {
                "opacity": 0.6,
                "zoom": 1
            },
            {
                "opacity": 0.6,
                "zoom": 2
            },
            {
                "opacity": 0.6,
                "zoom": 3
            },
            {
                "opacity": 0.6,
                "zoom": 4
            },
            {
                "opacity": 0.6,
                "zoom": 5
            },
            {
                "opacity": 0.6,
                "zoom": 6
            },
            {
                "opacity": 0.6,
                "zoom": 7
            },
            {
                "opacity": 0.6,
                "zoom": 8
            },
            {
                "opacity": 0.6,
                "zoom": 9
            },
            {
                "opacity": 0.6,
                "zoom": 10
            },
            {
                "opacity": 0.6,
                "zoom": 11
            },
            {
                "opacity": 0.6,
                "zoom": 12
            },
            {
                "opacity": 0.6,
                "zoom": 13
            },
            {
                "opacity": 0.6,
                "zoom": 14
            },
            {
                "opacity": 0.5,
                "zoom": 15
            },
            {
                "opacity": 0.4,
                "zoom": 16
            },
            {
                "opacity": 0.4,
                "zoom": 17
            },
            {
                "opacity": 0.4,
                "zoom": 18
            },
            {
                "opacity": 0.4,
                "zoom": 19
            },
            {
                "opacity": 0.4,
                "zoom": 20
            },
            {
                "opacity": 0.4,
                "zoom": 21
            }
        ]
    },
    {
        "tags": "transit_schema",
        "elements": "geometry.outline",
        "stylers": [
            {
                "opacity": 0
            }
        ]
    },
    {
        "tags": "transit_line",
        "elements": "geometry.fill.pattern",
        "stylers": [
            {
                "color": "#a3afc2"
            },
            {
                "opacity": 0,
                "zoom": 0
            },
            {
                "opacity": 0,
                "zoom": 1
            },
            {
                "opacity": 0,
                "zoom": 2
            },
            {
                "opacity": 0,
                "zoom": 3
            },
            {
                "opacity": 0,
                "zoom": 4
            },
            {
                "opacity": 0,
                "zoom": 5
            },
            {
                "opacity": 0,
                "zoom": 6
            },
            {
                "opacity": 0,
                "zoom": 7
            },
            {
                "opacity": 0,
                "zoom": 8
            },
            {
                "opacity": 0,
                "zoom": 9
            },
            {
                "opacity": 0,
                "zoom": 10
            },
            {
                "opacity": 0,
                "zoom": 11
            },
            {
                "opacity": 0,
                "zoom": 12
            },
            {
                "opacity": 1,
                "zoom": 13
            },
            {
                "opacity": 1,
                "zoom": 14
            },
            {
                "opacity": 1,
                "zoom": 15
            },
            {
                "opacity": 1,
                "zoom": 16
            },
            {
                "opacity": 1,
                "zoom": 17
            },
            {
                "opacity": 1,
                "zoom": 18
            },
            {
                "opacity": 1,
                "zoom": 19
            },
            {
                "opacity": 1,
                "zoom": 20
            },
            {
                "opacity": 1,
                "zoom": 21
            }
        ]
    },
    {
        "tags": "transit_line",
        "elements": "geometry.fill",
        "stylers": [
            {
                "color": "#a3afc2"
            },
            {
                "scale": 0.4
            },
            {
                "opacity": 0,
                "zoom": 0
            },
            {
                "opacity": 0,
                "zoom": 1
            },
            {
                "opacity": 0,
                "zoom": 2
            },
            {
                "opacity": 0,
                "zoom": 3
            },
            {
                "opacity": 0,
                "zoom": 4
            },
            {
                "opacity": 0,
                "zoom": 5
            },
            {
                "opacity": 0,
                "zoom": 6
            },
            {
                "opacity": 0,
                "zoom": 7
            },
            {
                "opacity": 0,
                "zoom": 8
            },
            {
                "opacity": 0,
                "zoom": 9
            },
            {
                "opacity": 0,
                "zoom": 10
            },
            {
                "opacity": 0,
                "zoom": 11
            },
            {
                "opacity": 0,
                "zoom": 12
            },
            {
                "opacity": 1,
                "zoom": 13
            },
            {
                "opacity": 1,
                "zoom": 14
            },
            {
                "opacity": 1,
                "zoom": 15
            },
            {
                "opacity": 1,
                "zoom": 16
            },
            {
                "opacity": 1,
                "zoom": 17
            },
            {
                "opacity": 1,
                "zoom": 18
            },
            {
                "opacity": 1,
                "zoom": 19
            },
            {
                "opacity": 1,
                "zoom": 20
            },
            {
                "opacity": 1,
                "zoom": 21
            }
        ]
    },
    {
        "tags": "water",
        "elements": "geometry",
        "stylers": [
            {
                "color": "#a3c6fa",
                "zoom": 0
            },
            {
                "color": "#a3c6fa",
                "zoom": 1
            },
            {
                "color": "#a3c6fa",
                "zoom": 2
            },
            {
                "color": "#a3c6fa",
                "zoom": 3
            },
            {
                "color": "#a3c6fa",
                "zoom": 4
            },
            {
                "color": "#a3c6fa",
                "zoom": 5
            },
            {
                "color": "#a3c6fa",
                "zoom": 6
            },
            {
                "color": "#a3c6fa",
                "zoom": 7
            },
            {
                "color": "#a6c8fa",
                "zoom": 8
            },
            {
                "color": "#aacafb",
                "zoom": 9
            },
            {
                "color": "#adccfb",
                "zoom": 10
            },
            {
                "color": "#aecdfb",
                "zoom": 11
            },
            {
                "color": "#b0cefb",
                "zoom": 12
            },
            {
                "color": "#b1cffb",
                "zoom": 13
            },
            {
                "color": "#b3d0fb",
                "zoom": 14
            },
            {
                "color": "#b6d2fb",
                "zoom": 15
            },
            {
                "color": "#b8d3fb",
                "zoom": 16
            },
            {
                "color": "#bbd5fb",
                "zoom": 17
            },
            {
                "color": "#bdd6fc",
                "zoom": 18
            },
            {
                "color": "#c0d8fc",
                "zoom": 19
            },
            {
                "color": "#c2d9fc",
                "zoom": 20
            },
            {
                "color": "#c5dbfc",
                "zoom": 21
            }
        ]
    },
    {
        "tags": "water",
        "elements": "geometry",
        "types": "polyline",
        "stylers": [
            {
                "opacity": 0.4,
                "zoom": 0
            },
            {
                "opacity": 0.4,
                "zoom": 1
            },
            {
                "opacity": 0.4,
                "zoom": 2
            },
            {
                "opacity": 0.4,
                "zoom": 3
            },
            {
                "opacity": 0.6,
                "zoom": 4
            },
            {
                "opacity": 0.8,
                "zoom": 5
            },
            {
                "opacity": 1,
                "zoom": 6
            },
            {
                "opacity": 1,
                "zoom": 7
            },
            {
                "opacity": 1,
                "zoom": 8
            },
            {
                "opacity": 1,
                "zoom": 9
            },
            {
                "opacity": 1,
                "zoom": 10
            },
            {
                "opacity": 1,
                "zoom": 11
            },
            {
                "opacity": 1,
                "zoom": 12
            },
            {
                "opacity": 1,
                "zoom": 13
            },
            {
                "opacity": 1,
                "zoom": 14
            },
            {
                "opacity": 1,
                "zoom": 15
            },
            {
                "opacity": 1,
                "zoom": 16
            },
            {
                "opacity": 1,
                "zoom": 17
            },
            {
                "opacity": 1,
                "zoom": 18
            },
            {
                "opacity": 1,
                "zoom": 19
            },
            {
                "opacity": 1,
                "zoom": 20
            },
            {
                "opacity": 1,
                "zoom": 21
            }
        ]
    },
    {
        "tags": "bathymetry",
        "elements": "geometry",
        "stylers": [
            {
                "hue": "#a3c6fa"
            }
        ]
    },
    {
        "tags": {
            "any": [
                "industrial",
                "construction_site"
            ]
        },
        "elements": "geometry",
        "stylers": [
            {
                "color": "#cfe1fc",
                "zoom": 0
            },
            {
                "color": "#cfe1fc",
                "zoom": 1
            },
            {
                "color": "#cfe1fc",
                "zoom": 2
            },
            {
                "color": "#cfe1fc",
                "zoom": 3
            },
            {
                "color": "#cfe1fc",
                "zoom": 4
            },
            {
                "color": "#cfe1fc",
                "zoom": 5
            },
            {
                "color": "#cfe1fc",
                "zoom": 6
            },
            {
                "color": "#cfe1fc",
                "zoom": 7
            },
            {
                "color": "#cfe1fc",
                "zoom": 8
            },
            {
                "color": "#cfe1fc",
                "zoom": 9
            },
            {
                "color": "#cfe1fc",
                "zoom": 10
            },
            {
                "color": "#cfe1fc",
                "zoom": 11
            },
            {
                "color": "#cfe1fc",
                "zoom": 12
            },
            {
                "color": "#cfe1fc",
                "zoom": 13
            },
            {
                "color": "#d8e7fc",
                "zoom": 14
            },
            {
                "color": "#e2edfd",
                "zoom": 15
            },
            {
                "color": "#e4eefd",
                "zoom": 16
            },
            {
                "color": "#e5effd",
                "zoom": 17
            },
            {
                "color": "#e7f0fd",
                "zoom": 18
            },
            {
                "color": "#e9f1fe",
                "zoom": 19
            },
            {
                "color": "#eaf2fe",
                "zoom": 20
            },
            {
                "color": "#ecf3fe",
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "any": "transit",
            "none": [
                "transit_location",
                "transit_line",
                "transit_schema",
                "is_unclassified_transit"
            ]
        },
        "elements": "geometry",
        "stylers": [
            {
                "color": "#cfe1fc",
                "zoom": 0
            },
            {
                "color": "#cfe1fc",
                "zoom": 1
            },
            {
                "color": "#cfe1fc",
                "zoom": 2
            },
            {
                "color": "#cfe1fc",
                "zoom": 3
            },
            {
                "color": "#cfe1fc",
                "zoom": 4
            },
            {
                "color": "#cfe1fc",
                "zoom": 5
            },
            {
                "color": "#cfe1fc",
                "zoom": 6
            },
            {
                "color": "#cfe1fc",
                "zoom": 7
            },
            {
                "color": "#cfe1fc",
                "zoom": 8
            },
            {
                "color": "#cfe1fc",
                "zoom": 9
            },
            {
                "color": "#cfe1fc",
                "zoom": 10
            },
            {
                "color": "#cfe1fc",
                "zoom": 11
            },
            {
                "color": "#cfe1fc",
                "zoom": 12
            },
            {
                "color": "#cfe1fc",
                "zoom": 13
            },
            {
                "color": "#d8e7fc",
                "zoom": 14
            },
            {
                "color": "#e2edfd",
                "zoom": 15
            },
            {
                "color": "#e4eefd",
                "zoom": 16
            },
            {
                "color": "#e5effd",
                "zoom": 17
            },
            {
                "color": "#e7f0fd",
                "zoom": 18
            },
            {
                "color": "#e9f1fe",
                "zoom": 19
            },
            {
                "color": "#eaf2fe",
                "zoom": 20
            },
            {
                "color": "#ecf3fe",
                "zoom": 21
            }
        ]
    },
    {
        "tags": "fence",
        "elements": "geometry.fill",
        "stylers": [
            {
                "color": "#bcd5fb"
            },
            {
                "opacity": 0.75,
                "zoom": 0
            },
            {
                "opacity": 0.75,
                "zoom": 1
            },
            {
                "opacity": 0.75,
                "zoom": 2
            },
            {
                "opacity": 0.75,
                "zoom": 3
            },
            {
                "opacity": 0.75,
                "zoom": 4
            },
            {
                "opacity": 0.75,
                "zoom": 5
            },
            {
                "opacity": 0.75,
                "zoom": 6
            },
            {
                "opacity": 0.75,
                "zoom": 7
            },
            {
                "opacity": 0.75,
                "zoom": 8
            },
            {
                "opacity": 0.75,
                "zoom": 9
            },
            {
                "opacity": 0.75,
                "zoom": 10
            },
            {
                "opacity": 0.75,
                "zoom": 11
            },
            {
                "opacity": 0.75,
                "zoom": 12
            },
            {
                "opacity": 0.75,
                "zoom": 13
            },
            {
                "opacity": 0.75,
                "zoom": 14
            },
            {
                "opacity": 0.75,
                "zoom": 15
            },
            {
                "opacity": 0.75,
                "zoom": 16
            },
            {
                "opacity": 0.45,
                "zoom": 17
            },
            {
                "opacity": 0.45,
                "zoom": 18
            },
            {
                "opacity": 0.45,
                "zoom": 19
            },
            {
                "opacity": 0.45,
                "zoom": 20
            },
            {
                "opacity": 0.45,
                "zoom": 21
            }
        ]
    },
    {
        "tags": "medical",
        "elements": "geometry",
        "stylers": [
            {
                "color": "#cfe1fc",
                "zoom": 0
            },
            {
                "color": "#cfe1fc",
                "zoom": 1
            },
            {
                "color": "#cfe1fc",
                "zoom": 2
            },
            {
                "color": "#cfe1fc",
                "zoom": 3
            },
            {
                "color": "#cfe1fc",
                "zoom": 4
            },
            {
                "color": "#cfe1fc",
                "zoom": 5
            },
            {
                "color": "#cfe1fc",
                "zoom": 6
            },
            {
                "color": "#cfe1fc",
                "zoom": 7
            },
            {
                "color": "#cfe1fc",
                "zoom": 8
            },
            {
                "color": "#cfe1fc",
                "zoom": 9
            },
            {
                "color": "#cfe1fc",
                "zoom": 10
            },
            {
                "color": "#cfe1fc",
                "zoom": 11
            },
            {
                "color": "#cfe1fc",
                "zoom": 12
            },
            {
                "color": "#cfe1fc",
                "zoom": 13
            },
            {
                "color": "#d8e7fc",
                "zoom": 14
            },
            {
                "color": "#e2edfd",
                "zoom": 15
            },
            {
                "color": "#e4eefd",
                "zoom": 16
            },
            {
                "color": "#e5effd",
                "zoom": 17
            },
            {
                "color": "#e7f0fd",
                "zoom": 18
            },
            {
                "color": "#e9f1fe",
                "zoom": 19
            },
            {
                "color": "#eaf2fe",
                "zoom": 20
            },
            {
                "color": "#ecf3fe",
                "zoom": 21
            }
        ]
    },
    {
        "tags": "beach",
        "elements": "geometry",
        "stylers": [
            {
                "color": "#cfe1fc",
                "opacity": 0.3,
                "zoom": 0
            },
            {
                "color": "#cfe1fc",
                "opacity": 0.3,
                "zoom": 1
            },
            {
                "color": "#cfe1fc",
                "opacity": 0.3,
                "zoom": 2
            },
            {
                "color": "#cfe1fc",
                "opacity": 0.3,
                "zoom": 3
            },
            {
                "color": "#cfe1fc",
                "opacity": 0.3,
                "zoom": 4
            },
            {
                "color": "#cfe1fc",
                "opacity": 0.3,
                "zoom": 5
            },
            {
                "color": "#cfe1fc",
                "opacity": 0.3,
                "zoom": 6
            },
            {
                "color": "#cfe1fc",
                "opacity": 0.3,
                "zoom": 7
            },
            {
                "color": "#cfe1fc",
                "opacity": 0.3,
                "zoom": 8
            },
            {
                "color": "#cfe1fc",
                "opacity": 0.3,
                "zoom": 9
            },
            {
                "color": "#cfe1fc",
                "opacity": 0.3,
                "zoom": 10
            },
            {
                "color": "#cfe1fc",
                "opacity": 0.3,
                "zoom": 11
            },
            {
                "color": "#cfe1fc",
                "opacity": 0.3,
                "zoom": 12
            },
            {
                "color": "#cfe1fc",
                "opacity": 0.65,
                "zoom": 13
            },
            {
                "color": "#d8e7fc",
                "opacity": 1,
                "zoom": 14
            },
            {
                "color": "#e2edfd",
                "opacity": 1,
                "zoom": 15
            },
            {
                "color": "#e4eefd",
                "opacity": 1,
                "zoom": 16
            },
            {
                "color": "#e5effd",
                "opacity": 1,
                "zoom": 17
            },
            {
                "color": "#e7f0fd",
                "opacity": 1,
                "zoom": 18
            },
            {
                "color": "#e9f1fe",
                "opacity": 1,
                "zoom": 19
            },
            {
                "color": "#eaf2fe",
                "opacity": 1,
                "zoom": 20
            },
            {
                "color": "#ecf3fe",
                "opacity": 1,
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "all": [
                "is_tunnel",
                "path"
            ]
        },
        "elements": "geometry.fill",
        "stylers": [
            {
                "color": "#7baef9"
            },
            {
                "opacity": 0.3
            }
        ]
    },
    {
        "tags": {
            "all": [
                "is_tunnel",
                "path"
            ]
        },
        "elements": "geometry.outline",
        "stylers": [
            {
                "opacity": 0
            }
        ]
    },
    {
        "tags": "road_limited",
        "elements": "geometry.fill",
        "stylers": [
            {
                "color": "#9ec3fa"
            },
            {
                "scale": 0,
                "zoom": 0
            },
            {
                "scale": 0,
                "zoom": 1
            },
            {
                "scale": 0,
                "zoom": 2
            },
            {
                "scale": 0,
                "zoom": 3
            },
            {
                "scale": 0,
                "zoom": 4
            },
            {
                "scale": 0,
                "zoom": 5
            },
            {
                "scale": 0,
                "zoom": 6
            },
            {
                "scale": 0,
                "zoom": 7
            },
            {
                "scale": 0,
                "zoom": 8
            },
            {
                "scale": 0,
                "zoom": 9
            },
            {
                "scale": 0,
                "zoom": 10
            },
            {
                "scale": 0,
                "zoom": 11
            },
            {
                "scale": 0,
                "zoom": 12
            },
            {
                "scale": 0.1,
                "zoom": 13
            },
            {
                "scale": 0.2,
                "zoom": 14
            },
            {
                "scale": 0.3,
                "zoom": 15
            },
            {
                "scale": 0.5,
                "zoom": 16
            },
            {
                "scale": 0.6,
                "zoom": 17
            },
            {
                "scale": 0.7,
                "zoom": 18
            },
            {
                "scale": 0.79,
                "zoom": 19
            },
            {
                "scale": 0.83,
                "zoom": 20
            },
            {
                "scale": 0.9,
                "zoom": 21
            }
        ]
    },
    {
        "tags": "road_limited",
        "elements": "geometry.outline",
        "stylers": [
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 0
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 1
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 2
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 3
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 4
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 5
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 6
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 7
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 8
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 9
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 10
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 11
            },
            {
                "color": "#ffffff",
                "scale": 1.4,
                "zoom": 12
            },
            {
                "color": "#ffffff",
                "scale": 0.1,
                "zoom": 13
            },
            {
                "color": "#e2edfd",
                "scale": 0.2,
                "zoom": 14
            },
            {
                "color": "#e2edfd",
                "scale": 0.3,
                "zoom": 15
            },
            {
                "color": "#e5effd",
                "scale": 0.5,
                "zoom": 16
            },
            {
                "color": "#e9f1fd",
                "scale": 0.6,
                "zoom": 17
            },
            {
                "color": "#ecf4fe",
                "scale": 0.7,
                "zoom": 18
            },
            {
                "color": "#f0f6fe",
                "scale": 1.18,
                "zoom": 19
            },
            {
                "color": "#f2f7fe",
                "scale": 1.23,
                "zoom": 20
            },
            {
                "color": "#f5f9fe",
                "scale": 1.33,
                "zoom": 21
            }
        ]
    },
    {
        "tags": {
            "any": [
                "transit"
            ]
        },
        "stylers": {
            "visibility": "off"
        }
    },
    {
        "tags": {
            "any": "landcover",
            "none": "vegetation"
        },
        "stylers": {
            "visibility": "off"
        }
    }
]