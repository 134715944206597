import { useContext, useEffect, useState } from 'react';
import AdaptivePopup from '../../../../../../../AdaptivePopup/AdaptivePopup';
import PlatformInput from '../../../../../../PlatformInput/PlatformInput';
import './ZonePopup.css';
import { colorMap, createFeatureCircle } from '../../../AddressForm/utils/addressFromUtils';
import { DeliveryContext } from '../../../../../../../../assets/contexts/deliveryContext';



function ZonePopup({ isOpen, handleClose, zone, setForm, form, location, blockRef, setLocation }) {
    const { defaultTimeZones } = useContext(DeliveryContext)



    function handleZoneChange(e) {
        const value = e.target.value
        const name = e.target.name
        switch (name) {
            case 'distance_km': {
                let inputValue = e.target.value.replace(/\D/g, '')
                if (!inputValue || Number(inputValue) === 0) {
                    inputValue = ''
                }
                if (Number(inputValue) >= 10000) {
                    inputValue = '10000'
                }
                console.log(zone)
                const index = zone.feature.properties.hint.i
                const circle = createFeatureCircle({
                    lon: location.center[0],
                    lat: location.center[1],
                    r: inputValue ? Number(inputValue) : 0,
                    n: 1000,
                    stroke: colorMap[index].stroke,
                    fill: colorMap[index].fill,
                    setLocation: setLocation,
                    mapRef: blockRef,
                    zoneHint: {
                        title: `Зона № ${index + 1}`,
                        i: index,
                        distance_km: inputValue ? Number(inputValue) : 0,
                        cost: zone.cost,
                        time: zone.time,
                    }
                })
                setForm(prevValue => {
                    const updatedDeliveryZones = prevValue.delivery_zones.map((item) => {
                        if (item.feature.id !== zone.feature.id) return item
                        else return {
                            ...item,
                            [name]: inputValue,
                            feature: circle,
                        }
                    })
                    return ({
                        ...prevValue,
                        delivery_zones: updatedDeliveryZones,
                        zoneToEdit: {
                            ...prevValue.zoneToEdit,
                            [name]: inputValue,
                            feature: circle,
                        },
                    })
                })
                break;
            }

            default: {
                setForm(prevValue => {
                    const updatedDeliveryZones = prevValue.delivery_zones.map((item) => {
                        if (item.feature.id !== zone.feature.id) return item
                        else return {
                            ...item,
                            [name]: value,
                        }
                    })
                    return ({
                        ...prevValue,
                        delivery_zones: updatedDeliveryZones,
                        zoneToEdit: {
                            ...prevValue.zoneToEdit,
                            [name]: value,
                        }
                    })
                })
                break;
            }
        }

    }
    return (
        <AdaptivePopup
            isOpen={isOpen}
            handleClose={handleClose}
            popupName={'zoneEdit'}
        >
            {zone && defaultTimeZones ?
                <div className='zone-popup'>
                    <div className='zone-popup__heading'>
                        <p className='zone-popup__number' style={{
                            backgroundColor: colorMap[zone.feature.properties.hint.i].stroke,
                        }}>{zone.feature.properties.hint.i + 1}</p>
                        <h4 className='zone-popup__title'>Настройка зоны</h4>
                    </div>
                    {console.log(zone.time)}
                    <PlatformInput
                        value={zone.time}
                        label={'Время доставки*'}
                        // hint={'Видно только вам'}
                        isSelect={true}
                        selected_text_value={zone.time ? zone.time.title : 'Не выбрано'}
                        maxLength={100}
                        handleChange={handleZoneChange}
                        selectList={defaultTimeZones.map((item) => ({
                            ...item,
                            title: item.title.ru,
                        }))}
                        selectDifferenceKey={'delta'}
                        selectTextValueKey={'title'}
                        name={'time'}
                    />
                    <PlatformInput
                        value={zone.distance_km}
                        handleChange={handleZoneChange}
                        label={'Расстояние от магазина (км)*'}
                        type={'text'}
                        inputMode='numeric'
                        name={'distance_km'}
                        show_eror_color_only
                        error={!zone.distance_km}
                    />
                    <PlatformInput
                        value={zone.cost}
                        handleChange={handleZoneChange}
                        label={'Цена доставки (₽)'}
                        type={'text'}
                        inputMode='numeric'
                        name={'cost'}
                    />
                </div>
                : null}

        </AdaptivePopup>
    );
}

export default ZonePopup